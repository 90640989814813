import { ElectionLeaderApi } from "../../types/el";
import { User } from "../../types/user";
import { createAction } from "@reduxjs/toolkit";

export const USERS_PREFIX = "users";

export const USER_ACTIONS = {
  GET_ACTIVE_USER: `${USERS_PREFIX}/GET_ACTIVE_USER`,
  GET_PROFILE: `${USERS_PREFIX}/GET_PROFILE`,
  LOGOUT: `${USERS_PREFIX}/LOGOUT`,
} as const;

export const getProfile = createAction(
  USER_ACTIONS.GET_PROFILE,
  (user: ElectionLeaderApi): { payload: ElectionLeaderApi } => {
    return { payload: user };
  }
);

export const getActiveUser = createAction(
  USER_ACTIONS.GET_ACTIVE_USER,
  (user: User): { payload: User | null } => {
    // console.log(user);
    return { payload: user };
  }
);

export const logoutAction = createAction(USER_ACTIONS.LOGOUT);
