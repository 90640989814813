import { ApiResponse, CreatePayload } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { Group, GroupApiUpdate } from "../../types/group";

export const groupClient = () => Axios(`${getApiUrl()}/group`);

export async function createNewGroup(values: CreatePayload<Group>) {
  return await groupClient().post("/create", values);
}

export async function updateGroup(values: GroupApiUpdate) {
  return await groupClient().put("/update", values);
}

export async function deleteGroup(values: Group) {
  return await groupClient().delete("/delete", {
    params: { group_id: values._id },
  });
}

export async function fetchGroups() {
  return groupClient().get<ApiResponse<Group[]>>("/get/all");
}
