import { styled } from "@mui/material/styles";
import TableCell, {
  TableCellProps,
  tableCellClasses,
} from "@mui/material/TableCell";
import variables from "../../styles/variables.module.scss";

export const StyledTableCell = styled(TableCell)<CustomTableCellProps>(
  ({ greyed = false }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: variables.primary,
      fontSize: variables.font_size_table,
      color: variables.text_color,
      fontWeight: variables.font_weight,
      padding: variables.padding_table,
    },
    [`&.${tableCellClasses.body}`]: {
      color: greyed ? variables.text_secondary : variables.text_color,
      fontSize: variables.font_size_table,
      padding: variables.padding_table,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      // borderBottom: `1px solid ${variables.background_toolbar_secondary}`,
    },
  })
);

interface CustomTableCellProps extends TableCellProps {
  greyed?: boolean;
}
