import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
} from "@mui/material";
import {
  ArrowForward,
  ArrowRight,
  Groups,
  KeyboardArrowRight,
  Person,
} from "@mui/icons-material";
import variables from "../../../styles/variables.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectGroups } from "../../../store/selectors/groupSelector";
import DetailsCard from "../../DetailsCard";
import { memo, useState } from "react";
import { Group } from "../../../types/group";
import { PollStatus } from "../../../types/poll";
import { getPollColor, getPollColorLight } from "../../../utils/getColours";
import { selectVoters } from "../../../store/selectors/voterSelector";
import { Voter } from "../../../types/voter";
import VerifiedIcon from "@mui/icons-material/Verified";

const PreviewCandidateGroups = memo(
  ({
    groups,
    pollType,
    committed,
    shrinked = false,
    fw = false,
  }: {
    groups: string[];
    pollType: PollStatus;
    committed?: string[];
    shrinked?: boolean;
    fw?: boolean;
  }) => {
    const { t } = useTranslation();
    const groupList = useAppSelector(selectGroups).filter((group) =>
      groups.includes(group._id)
    );
    const voters = useAppSelector(selectVoters);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [selectedVoters, setSelectedVoters] = useState<Voter[]>([]);

    const handleClick = (event: any, group: Group) => {
      setAnchorEl(event.currentTarget);
      setSelectedVoters(
        voters.filter((voter) => group.members.includes(voter._id))
      );
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    // console.log("here: ", groupList);
    console.log("sV: ", selectedVoters);

    return (
      <DetailsCard
        title={t("polls.details.groups_list")}
        // width={{ xs: "100%", lg: "30%", xl: "30%" }}
        width={
          fw
            ? { xs: "100%", lg: "100%", xl: "100%" }
            : { xs: "100%", lg: "30%", xl: "30%" }
        }
        shrinked={shrinked}
        pollType={pollType}
      >
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <List
            sx={{
              maxWidth: 350,
              bgcolor: "background.paper",
              maxHeight: shrinked ? "500px" : "1300px",
            }}
          >
            {groupList.map((group, index) => (
              <div key={index}>
                <ListItem
                  // aria-owns={open ? "mouse-over-popover" : undefined}
                  // aria-haspopup='true'
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      borderRadius: 5,
                      backgroundColor: getPollColorLight(pollType),
                    },
                  }}
                  onClick={(e) => handleClick(e, group)}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{ backgroundColor: getPollColorLight(pollType) }}
                    >
                      <Groups
                        sx={{
                          color: getPollColor(pollType),
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={group.title} />
                  <KeyboardArrowRight sx={{ color: getPollColor(pollType) }} />
                </ListItem>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  elevation={0}
                >
                  <List
                    sx={{
                      paddingTop: 2,
                      paddingBottom: 2,
                      maxWidth: "fit",
                      bgcolor: "background.paper",
                      maxHeight: shrinked ? "400px" : "600px",
                      overflowY: "auto",
                      border: `2px solid ${variables.grey}`,
                      borderRadius: "10px",
                      background: getPollColorLight(pollType),
                    }}
                  >
                    {selectedVoters.length > 0 &&
                      selectedVoters.map((voter, index) => (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                backgroundColor: getPollColor(pollType),
                              }}
                            >
                              <Person />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={voter._id}
                            secondary={
                              voter.gov_firstname && voter.gov_lastname
                                ? `${voter.gov_firstname} ${voter.gov_lastname}`
                                : null
                            }
                          />
                          {committed && committed.includes(voter._id) && (
                            <VerifiedIcon
                              sx={{
                                ml: 1,
                                color: variables.color_success,
                              }}
                            />
                          )}
                        </ListItem>
                      ))}
                  </List>
                </Popover>
              </div>
            ))}
          </List>
        </Box>
      </DetailsCard>
    );
  }
);

function arePropsEqual(
  prevProps: { groups: string[] },
  nextProps: { groups: string[] }
) {
  return prevProps.groups === nextProps.groups;
}

export default memo(PreviewCandidateGroups, arePropsEqual);
