import { createAction } from "@reduxjs/toolkit";
import { PollStatus } from "../../types/poll";
import { TableState } from "../../types/redux";

export const UI_PREFIX = "ui";

export const UI_ACTIONS = {
  SET_LANGUAGE: `${UI_PREFIX}/SET_LANGUAGE`,
  GET_ACTIVE_CHOICE: `${UI_PREFIX}/GET_ACTIVE_CHOICE`,
  GET_ACTIVE_TYPE: `${UI_PREFIX}/GET_ACTIVE_TYPE`,
  GET_TABLE_STATE_VOTERS: `${UI_PREFIX}/GET_TABLE_STATE_VOTERS`,
  GET_TABLE_STATE_GROUPS: `${UI_PREFIX}/GET_TABLE_STATE_GROUPS`,
  GET_TABLE_STATE_POLLS: `${UI_PREFIX}/GET_TABLE_STATE_POLLS`,
} as const;

export const setLanguage = createAction(
  UI_ACTIONS.SET_LANGUAGE,
  (language: string): { payload: string } => {
    return { payload: language };
  }
);

export const getActiveChoice = createAction(
  UI_ACTIONS.GET_ACTIVE_CHOICE,
  (choice: string): { payload: string } => {
    console.log(choice);
    return { payload: choice };
  }
);

export const getActiveType = createAction(
  UI_ACTIONS.GET_ACTIVE_TYPE,
  (choice: PollStatus): { payload: PollStatus } => {
    return { payload: choice };
  }
);

export const getTableStateVoters = createAction(
  UI_ACTIONS.GET_TABLE_STATE_VOTERS,
  (tsv: TableState): { payload: TableState } => {
    return { payload: tsv };
  }
);

export const getTableStateGroups = createAction(
  UI_ACTIONS.GET_TABLE_STATE_GROUPS,
  (tsg: TableState): { payload: TableState } => {
    return { payload: tsg };
  }
);

export const getTableStatePolls = createAction(
  UI_ACTIONS.GET_TABLE_STATE_GROUPS,
  (tsp: TableState): { payload: TableState } => {
    return { payload: tsp };
  }
);
