import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import styles from "../../styles/Form.module.scss";
import {
  Container,
  Grid,
  InputAdornment,
  Toolbar,
  Typography,
} from "@mui/material";
import { StyledButton } from "../styled/Button";
import { StyledTextField } from "../styled/TextField";
import { TableBar } from "../styled/AppBar";
import { Toc, Groups } from "@mui/icons-material";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import { getFullDataGroup } from "../../utils/getFormData";
import { Formik, Field, ErrorMessage } from "formik";
import { getActiveGroup } from "../../store/actions/groupAction";
import { selectActiveGroup } from "../../store/selectors/groupSelector";
import { GroupFormData } from "../../types/formik";
import { yupValidationGroup } from "../../utils/yupValidation";
import { selectVoters } from "../../store/selectors/voterSelector";
import { useTranslation } from "react-i18next";
import CustomAutocomplete from "../utils/CustomAutocomplete";
import { createNewGroup, updateGroup } from "../../services/api/group.api";
import { getGroups } from "../../store/effects/groupEffects";
import { GroupApiUpdate } from "../../types/group";
import AlertDialog from "../utils/CustomAlertDialog";
import variables from "../../styles/variables.module.scss";
import * as Sentry from "@sentry/react";

const Form = ({ close }: { close?: (close: boolean) => void }) => {
  const { t } = useTranslation();
  const activeGroup = useAppSelector(selectActiveGroup);
  const fullData: GroupFormData = getFullDataGroup(activeGroup);
  const dispatch = useAppThunkDispatch();
  const voters = useAppSelector(selectVoters);
  const [error, setError] = useState<string | null>(null);

  console.log("Form Group", activeGroup);
  console.log(fullData);
  console.log(voters);

  useEffect(() => {
    return () => {
      dispatch(getActiveGroup(null));
    };
  }, []);

  return (
    <Container className={styles.formContainer} maxWidth='lg'>
      <Paper elevation={2} className={styles.paper} sx={{ overflow: "hidden" }}>
        <TableBar position='relative'>
          <Toolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant='h6'>
              {t("electorate.form.title.group")}
            </Typography>
          </Toolbar>
        </TableBar>
        <Box className={styles.box}>
          <Formik
            initialValues={fullData}
            onSubmit={(values, actions) => {
              // values = { ...values, groups: [values.groups] };
              console.log(values);

              if (activeGroup) {
                const editGroup: GroupApiUpdate = {
                  ...values,
                  id: activeGroup._id,
                };
                console.log("Edit: ", editGroup);
                // dispatch(updateGroup(editGroup));
                updateGroup(editGroup)
                  .then((res) => {
                    console.log(res);
                    dispatch(getGroups());
                    // alert(res.message);
                    close && close(true); // router(-1);
                  })
                  .catch((error) => {
                    console.error("Axios request error:", error);
                    Sentry.captureException(error);
                    setError(error);
                  });
              } else {
                const createGroup = { ...values, _id: "" };

                console.log("Create: ", createGroup);

                createNewGroup(createGroup)
                  .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                      dispatch(getGroups());
                      close && close(true); // router(-1);
                    }
                  })
                  .catch((error) => {
                    console.error("Axios request error:", error);
                    Sentry.captureException(error);
                    setError(error);
                  });
              }
            }}
            validationSchema={yupValidationGroup}
          >
            {({
              values,
              errors,
              touched,
              // setValues,
              setFieldValue,
              // handleChange,
              // handleBlur,
              handleSubmit,
              // isSubmitting,
            }) => (
              <form
                className={styles.form}
                onSubmit={handleSubmit}
                autoComplete='off'
              >
                <Grid container rowSpacing={1.5} columnSpacing={3}>
                  {activeGroup && (
                    <Grid container item xs={12} justifyContent={"center"}>
                      <Typography
                        variant='body1'
                        fontStyle={"italic"}
                        color={variables.color_warn}
                      >
                        {t("general.menu.warning.message4")}
                      </Typography>
                    </Grid>
                  )}
                  <Grid container item xs={12}>
                    <Field
                      as={StyledTextField}
                      required
                      autoComplete='off'
                      id={"title"}
                      name={"title"}
                      label={t("electorate.form.fields.title")}
                      fullWidth
                      margin='dense'
                      variant='filled'
                      disabled={activeGroup ? true : false}
                      helperText={<ErrorMessage name={"title"} />}
                      error={errors["title"] && touched["title"]}
                      // inputProps={{ maxLength: 8, minLength: 8 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Groups />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <Field
                      as={StyledTextField}
                      multiline
                      rows={4}
                      required
                      autoComplete='off'
                      id={"description"}
                      name={"description"}
                      label={t("electorate.form.fields.description")}
                      fullWidth
                      margin='dense'
                      variant='filled'
                      helperText={<ErrorMessage name={"description"} />}
                      error={errors["description"] && touched["description"]}
                      // inputProps={{ maxLength: 8, minLength: 8 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            style={{ position: "absolute", top: 20, right: 10 }}
                          >
                            <Toc />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <CustomAutocomplete
                      items={voters.map((voter) => ({
                        label: voter._id,
                        value: voter._id,
                        secondary:
                          voter.gov_firstname && voter.gov_lastname
                            ? `${voter.gov_firstname} ${voter.gov_lastname}`
                            : undefined,
                      }))}
                      selectedItems={voters
                        .filter((voter) => values.members.includes(voter._id))
                        .map((voter) => ({
                          label: voter._id,
                          value: voter._id,
                        }))}
                      setFieldValue={setFieldValue}
                      fieldTag={"members"}
                      label={t("electorate.form.fields.voters")}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent={"center"}>
                    <StyledButton
                      className={styles.button}
                      variant='contained'
                      type='submit'
                    >
                      {activeGroup
                        ? t("electorate.form.button.edit_group")
                        : t("electorate.form.button.create_group")}
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          {error && (
            <AlertDialog
              open={true}
              title={t("general.alert")}
              message={error}
              buttons={[{ label: "Ok", onClick: () => setError(null) }]}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Form;
