import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import styles from "../../styles/Form.module.scss";
import {
  Container,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { StyledButton } from "../styled/Button";
import { StyledTextField } from "../styled/TextField";
import { TableBar } from "../styled/AppBar";
import { Email } from "@mui/icons-material";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import { selectActiveVoter } from "../../store/selectors/voterSelector";
import {
  ErrorMessage,
  Field,
  Formik,
  FormikErrors,
  FormikTouched,
} from "formik";
import { yupValidationVoter } from "../../utils/yupValidation";
import { FormikVoterTypes, VoterFormData } from "../../types/formik";
import { getFullDataVoter } from "../../utils/getFormData";
import { getActiveVoter } from "../../store/actions/voterAction";
import { StyledFormControl, menuStyles } from "../styled/StyledFormSelect";
import { StyledCheckbox } from "../styled/StyledCheckbox";
import { selectGroups } from "../../store/selectors/groupSelector";
import { Group } from "../../types/group";
import { useTranslation } from "react-i18next";
import { getVoters } from "../../store/effects/voterEffects";
import { createNewVoter, updateVoter } from "../../services/api/voter.api";
import AlertDialog from "../utils/CustomAlertDialog";
import variables from "../../styles/variables.module.scss";
import * as Sentry from "@sentry/react";

const getTitle = (groups: Group[], id: string) => {
  return groups.find((s) => s._id === id)?.title;
};

const Form = ({ close }: { close?: (close: boolean) => void }) => {
  const { t } = useTranslation();
  const activeVoter = useAppSelector(selectActiveVoter);
  const fullData: VoterFormData = getFullDataVoter(activeVoter);
  const dispatch = useAppThunkDispatch();
  // const router = useNavigate();
  // const voters = useAppSelector(selectVoters);
  const groups = useAppSelector(selectGroups);
  // const [invite, setInvite] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      dispatch(getActiveVoter(null));
    };
  }, []);

  console.log("Form User", activeVoter);
  console.log(fullData);

  const createTextField = (
    errors: FormikErrors<FormikVoterTypes>,
    touched: FormikTouched<FormikVoterTypes>,
    text: string,
    icon: JSX.Element,
    disabled: boolean,
    required: boolean
    // type: string,
  ): JSX.Element => {
    return (
      <Field
        as={StyledTextField}
        disabled={disabled}
        required={required}
        autoComplete='off'
        id={text}
        name={text}
        label={t(`electorate.form.fields.${text}`)}
        fullWidth
        margin='dense'
        variant='filled'
        helperText={<ErrorMessage name={text} />}
        error={
          errors[text as keyof FormikVoterTypes] &&
          touched[text as keyof FormikVoterTypes]
        }
        type={text}
        // inputProps={{ maxLength: 8, minLength: 8 }}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{icon}</InputAdornment>,
        }}
      />
    );
  };

  return (
    <Container className={styles.formContainer} maxWidth='lg'>
      <Paper elevation={2} className={styles.paper} sx={{ overflow: "hidden" }}>
        <TableBar position='relative'>
          <Toolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant='h6'>
              {t("electorate.form.title.voter")}
            </Typography>
            {activeVoter && activeVoter.gov_firstname && (
              <Box
                sx={{
                  color: variables.purple,
                  padding: 2,
                  borderRadius: 10,
                }}
              >
                <Typography>
                  {activeVoter.gov_firstname + " " + activeVoter.gov_lastname}
                </Typography>
              </Box>
            )}
          </Toolbar>
        </TableBar>
        <Box className={styles.box}>
          <Formik
            initialValues={fullData}
            onSubmit={(values, actions) => {
              // values = { ...values, groups: [values.groups] };
              console.log(values);

              if (activeVoter) {
                const editVoter = {
                  ...values,
                  email: activeVoter._id,
                  groups: values.groups,
                  // DID: activeVoter.DID,
                  // verified: true,
                  // status: activeVoter.status,
                  // polls: activeVoter.polls,
                };

                console.log("Edit: ", editVoter);
                updateVoter(editVoter)
                  .then((res: any) => {
                    console.log(res);
                    if (res.status === 200) {
                      dispatch(getVoters());
                      close && close(true); // router(-1);
                    }
                  })
                  .catch((error) => {
                    console.error("Axios request error:", error);
                    Sentry.captureException(error);
                    setError(error);
                  });
              } else {
                const createVoter = {
                  ...values,
                  _id: "",
                  // DID: "",
                  // verified: true,
                  // status: 'Invited',
                  // polls: [],
                };

                console.log("Create: ", createVoter);

                createNewVoter(createVoter)
                  .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                      dispatch(getVoters());
                      close && close(true); // router(-1);
                    }
                  })
                  .catch((error) => {
                    console.error("Axios request error:", error);
                    Sentry.captureException(error);
                    setError(error);
                  });
              }
            }}
            validationSchema={yupValidationVoter}
          >
            {({
              values,
              errors,
              touched,
              // setValues,
              // setFieldValue,
              handleChange,
              // handleBlur,
              handleSubmit,
              // isSubmitting,
            }) => (
              <form
                className={styles.form}
                onSubmit={handleSubmit}
                autoComplete='off'
              >
                <Grid container rowSpacing={1.5} columnSpacing={3}>
                  {activeVoter && (
                    <Grid container item xs={12} justifyContent={"center"}>
                      <Typography
                        variant='body1'
                        fontStyle={"italic"}
                        color={variables.color_warn}
                      >
                        {t("general.menu.warning.message4")}
                      </Typography>
                    </Grid>
                  )}
                  <Grid container item xs={12}>
                    {createTextField(
                      errors,
                      touched,
                      "email",
                      <Email />,
                      activeVoter ? true : false,
                      true
                    )}
                  </Grid>
                  {/* <Grid container item xs={6}>
                    {createTextField(
                      errors,
                      touched,
                      "blockchain_address",
                      <Person />,
                      false,
                      false
                    )}
                  </Grid> */}
                  <Grid container item xs={12}>
                    <StyledFormControl variant='filled' sx={{ width: "100%" }}>
                      <InputLabel id='groups'>
                        {t("electorate.form.fields.groups")}
                      </InputLabel>
                      <Field
                        as={Select}
                        multiple
                        labelId='groups'
                        id='groups'
                        name='groups'
                        value={values.groups}
                        onChange={(e: { target: { value: string } }) => {
                          handleChange(e);
                        }}
                        renderValue={(selected: string[] | string) =>
                          !(typeof selected === "string")
                            ? selected
                                .map((s) => getTitle(groups, s))
                                .join(", ")
                            : getTitle(groups, selected)
                        }
                        MenuProps={menuStyles}
                      >
                        {groups.map((group, index) => {
                          return (
                            <MenuItem key={index} value={group._id}>
                              <StyledCheckbox
                                checked={values.groups.indexOf(group._id) > -1}
                              />

                              <ListItemText
                                primary={getTitle(groups, group._id)}
                              />
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </StyledFormControl>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    columnGap={3}
                  >
                    {/* <StyledButton
                      className={styles.button}
                      variant="contained"
                      type="submit"
                      onClick={() => setInvite(false)}
                    >
                      {t("electorate.form.button.create_voter")}
                    </StyledButton> */}
                    <StyledButton
                      className={styles.button}
                      variant='contained'
                      type='submit'
                      // onClick={() => setInvite(true)}
                    >
                      {activeVoter
                        ? t("electorate.form.button.edit_voter")
                        : t("electorate.form.button.create_voter_inv")}
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          {error && (
            <AlertDialog
              open={true}
              title={t("general.alert")}
              message={error}
              buttons={[{ label: "Ok", onClick: () => setError(null) }]}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Form;
