export const bcState = {
  zkEthereumAddress: {
    proof: {
      pi_a: [
        "18333681228175167353273369476785925816382384970972268283695566749990302439812",
        "15251024026105248789051418189876799462919857611960589074021679724862728780701",
      ],
      pi_b: [
        [
          "1389438356884049988158305201667509073050827163169716720004691321458392233767",
          "4952942599872002405632487169094447673902024030628150622774796942064500384854",
        ],
        [
          "570012753295854112682891382507127181171723204629249846783427035934903843731",
          "19887882037371895375238112769182025860645891724929571902835747797871008850605",
        ],
      ],
      pi_c: [
        "7848266466822901044259853555073743771949567530489859226196260517521458190828",
        "12983474587105726584215720412406135313508760294275772551457115508732718533620",
      ],
    },
    publicSignals: [
      "0",
      "0",
      "18109299042501422609873040227783617387724629203069565972823767558691226901891",
      "0",
    ],
  },
  zkMerkleTree: {
    proof: {
      pi_a: [
        "676468566904148350203700408625111175820391753334450651450274041847316761958",
        "5738542356847467218709795553541560865039014867575072905868442000867211812362",
      ],
      pi_b: [
        [
          "16647493184707723419554382668529456791033046760717468891248186912575470431343",
          "20842167707136848500964714785200438138974382351309378885551341901804097048446",
        ],
        [
          "2575723008434251166363131153241832332554764699554818519597224014239958182684",
          "1136460978864781704120711824513726942253185185696727882658624627414564468500",
        ],
      ],
      pi_c: [
        "4443183549378139457128943117848166323532287889791701732578578316875431924394",
        "5509176310899939461112476644068585745027232562294899351165880310148893016205",
      ],
    },
    publicSignals: [
      "0",
      "0",
      "18109299042501422609873040227783617387724629203069565972823767558691226901891",
      "6744870434946027900550536289066247483062132451717243970943893397885531360673",
    ],
  },
  pathItems: {
    pathElements: [
      "21663839004416932945382355908790599225266501822907911457504978515578255421292",
      "16923532097304556005972200564242292693309333953544141029519619077135960040221",
      "7833458610320835472520144237082236871909694928684820466656733259024982655488",
      "14506027710748750947258687001455876266559341618222612722926156490737302846427",
      "4766583705360062980279572762279781527342845808161105063909171241304075622345",
      "16640205414190175414380077665118269450294358858897019640557533278896634808665",
      "13024477302430254842915163302704885770955784224100349847438808884122720088412",
      "11345696205391376769769683860277269518617256738724086786512014734609753488820",
      "17235543131546745471991808272245772046758360534180976603221801364506032471936",
      "155962837046691114236524362966874066300454611955781275944230309195800494087",
      "14030416097908897320437553787826300082392928432242046897689557706485311282736",
      "12626316503845421241020584259526236205728737442715389902276517188414400172517",
      "6729873933803351171051407921027021443029157982378522227479748669930764447503",
      "12963910739953248305308691828220784129233893953613908022664851984069510335421",
      "8697310796973811813791996651816817650608143394255750603240183429036696711432",
      "9001816533475173848300051969191408053495003693097546138634479732228054209462",
      "13882856022500117449912597249521445907860641470008251408376408693167665584212",
      "6167697920744083294431071781953545901493956884412099107903554924846764168938",
      "16572499860108808790864031418434474032816278079272694833180094335573354127261",
      "11544818037702067293688063426012553693851444915243122674915303779243865603077",
      "18926336163373752588529320804722226672465218465546337267825102089394393880276",
      "11644142961923297861823153318467410719458235936926864848600378646368500787559",
      "14452740608498941570269709581566908438169321105015301708099056566809891976275",
      "7578744943370928628486790984031172450284789077258575411544517949960795417672",
      "5265560722662711931897489036950489198497887581819190855722292641626977795281",
      "731223578478205522266734242762040379509084610212963055574289967577626707020",
      "20461032451716111710758703191059719329157552073475405257510123004109059116697",
      "21109115181850306325376985763042479104020288670074922684065722930361593295700",
      "81188535419966333443828411879788371791911419113311601242851320922268145565",
      "7369375930008366466575793949976062119589129382075515225587339510228573090855",
      "14128481056524536957498216347562587505734220138697483515041882766627531681467",
      "20117374654854068065360091929240690644953205021847304657748312176352011708876",
    ],
    pathIndices: [
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ],
  },
  loading: false,
  _persist: {
    version: -1,
    rehydrated: true,
  },
};
