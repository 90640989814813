import { PollStatus } from "../types/poll";
import variables from "../styles/variables.module.scss";

export const getPollColor = (pollType: PollStatus) => {
  switch (pollType) {
    case "PAST":
      return variables.red;
    case "ACTIVE":
      return variables.green;
    case "UPCOMING":
      return variables.yellow;
    case "DRAFT":
      return variables.purple;
    default:
      return variables.grey;
  }
};

export const getPollColorLight = (pollType: PollStatus) => {
  switch (pollType) {
    case "PAST":
      return variables.redLight;
    case "ACTIVE":
      return variables.greenLight;
    case "UPCOMING":
      return variables.yellowLight;
    case "DRAFT":
      return variables.purpleLight;
    default:
      return variables.greyLight;
  }
};
