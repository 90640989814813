import * as yup from "yup";

// const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
// const FILE_SIZE = 524288;
// const PasswordRegEx =
//   /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const UpperLowerCaseRegex = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
const NumberSymbolRegex = /^(?=.*\d)(?=.*[^\w\s]).+$/;

export const yupValidationUser = yup.object().shape({
  username: yup.string().min(3, "Too Short !").max(30, "Too Long !"),
  // .required("Username is Required")
  // email: yup.string().email("Enter a Valid Email").required("Email is Required"),

  // fullname: yup.string().required("Fullname is Required"),

  password: yup
    .string()
    // .required("Enter Your Password")
    // .matches(PasswordRegEx, "Uppercase Lowercase Special char Required")
    // .min(8, "Password Should be minimum 8 character")
    .max(50, "Too long"),

  // confirmPassword: yup
  //   .string()
  //   .oneOf([yup.ref("password")], "Password does not matched")
  //   .required("Confirm Password is Required"),

  role: yup.string().required("Select an Option"),
});

export const yupValidationVoter = yup.object().shape({
  email: yup
    .string()
    .email("Enter a Valid Email")
    .required("Email is Required"),

  // fullname: yup.string().required("Fullname is Required"),
});

export const yupValidationGroup = yup.object().shape({
  title: yup.string().min(3, "Too Short !").max(80, "Too Long !"),
  // .required("Username is Required")
  description: yup.string().max(500, "Too Long !"),
  // .required("Username is Required")
});

export const yupValidationLogin = yup.object().shape({
  username: yup.string().required("Username is Required"),
  password: yup.string().required("Password is Required"),
});

export const yupValidationPrivacy = yup.object().shape({
  // current_password: yup.string().required("Password is Required"),
  password: yup
    .string()
    .required("Password is Required")
    .min(8, "Password Should be minimum 8 character")
    .max(50, "Too long")
    .matches(
      UpperLowerCaseRegex,
      "At least one lowercase & one uppercase character"
    )
    .matches(NumberSymbolRegex, "At least one number & one special character"),
  confirm_password: yup
    .string()
    .required("Password is Required")
    .oneOf([yup.ref("password")], "Password does not matched"),
});

export const yupValidationAccount = yup.object().shape({
  username: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  occupation: yup.string(),
});
