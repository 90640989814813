import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Serializer, StylesManager } from "survey-core";
import {
  SurveyCreator,
  SurveyCreatorComponent,
  settings,
} from "survey-creator-react";
import { ICreatorOptions } from "survey-creator-core";
import { SurveyQuestionEditorDefinition } from "survey-creator-core";
import styles from "../../../styles/Wizard.module.scss";
// import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";
// import "survey-core/survey.i18n";
// import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-creator-core/survey-creator-core.min.css";
import { ElementType, PollApi } from "../../../types/poll";
import { setLicenseKey } from "survey-core";
import { useTranslation } from "react-i18next";
import { editorLocalization } from "survey-creator-core";
import { SURVEY_LICENSE_KEY } from "../../../consts/survey";

setLicenseKey(SURVEY_LICENSE_KEY);

const creatorOptions: ICreatorOptions = {
  showLogicTab: false,
  showJSONEditorTab: false,
  isAutoSave: false,
  showErrorOnFailedSave: true,
  questionTypes: ["text", "checkbox", "radiogroup"],
  pageEditMode: "single",
  showSidebar: false,
};

//Show/Hide specific properties
// const blackList = ["showTitle", "locale"];
// const whiteList = ["name", "title", "description"];

// Show/Hide whole tabs in Grid Properties
SurveyQuestionEditorDefinition.definition["survey"].tabs = [
  { name: "timer", visible: false },
  { name: "showOnCompleted", visible: false },
  { name: "validation", visible: false },
  { name: "data", visible: false },
  { name: "logic", visible: false },
  { name: "pages", visible: false },
  { name: "navigation", visible: false },
  { name: "logo", visible: false },
  { name: "question", visible: false },
  { name: "others", visible: false },
];

// SurveyQuestionEditorDefinition.definition["survey"].properties = whiteList;

Serializer.removeProperty("survey", "logo");
Serializer.removeProperty("page", "addNewQuestion");
Serializer.findProperty("survey", "title").readOnly = true;
Serializer.findProperty("survey", "description").readOnly = true;
// Serializer.findProperty("question", "title").readOnly = true;

settings.designer.showAddQuestionButton = false;
settings.propertyGrid.maximumChoicesCount = 10;

const PollCreatorComponent = ({
  poll,
  setElements,
  setDirty,
  language,
  surveyType,
}: {
  poll: PollApi;
  setElements: (elements: ElementType) => void;
  setDirty: (dirty: boolean) => void;
  surveyType: string;
  language: "en" | "gr";
}) => {
  const [creator, setCreator] = useState(new SurveyCreator(creatorOptions));
  StylesManager.applyTheme("defaultV2");

  const { t } = useTranslation();
  // const language = useAppSelector(selectLanguage) === "enUS" ? "en" : "gr";
  const translations = editorLocalization.getLocale(language);
  translations.ed.choices_Item = t("wizard.steps.ballot_design.general");

  creator.showSaveButton = true;

  // console.log("SURVEY: ", SurveyQuestionEditorDefinition.definition);

  // const itemAdd = (sender: any, options: any) => {
  //   options.newItem.value =
  //     t("wizard.steps.ballot_design.general") + (options.itemValues.length + 1);
  // };

  useEffect(() => {
    creator.onSurveyInstanceCreated.add(function (sender, options) {
      // If we are creating a surface for designer surface
      if (options.reason === "designer") {
        options.survey.onShowingChoiceItem.add((sender: any, options: any) => {
          if (
            options.item === options.question.otherItem ||
            options.item === options.question.noneItem ||
            options.item === options.question.selectAllItem
          ) {
            options.visible = false; //disables none/other
          }
        });
      }
    });

    // creator.onItemValueAdded.add(function (sender, options) {
    //   if (options.itemValues.length >= 5) {
    //     // showNotification("number");
    //     console.log("ok");
    //     options.itemValues.pop();
    //     // throw "too many options";
    //   }
    // });

    creator.saveSurveyFunc = () => {
      const json = creator.JSON;
      // console.log("SAVE CREATOR: ", json.elements);
      setElements(json.elements[0]);
    };

    creator.onModified.add(function (sender, options) {
      setElements(sender.JSON.elements[0]);
      setDirty(true);
    });

    // creator.onShowingProperty.add(function (_, options) {
    //   // Hide properties found in `blackList`
    //   // options.canShow = blackList.indexOf(options.property.name) < 0;

    //   // Hide all properties except those found in `whiteList`
    //   options.canShow = whiteList.indexOf(options.property.name) > -1;
    // });
  }, []);

  // //load poll
  // useEffect(() => {
  //   // console.log("Load: ", poll);
  //   creator.JSON = poll;
  // });

  useEffect(() => {
    // console.log(surveyType);
    if (!poll.isDirty) {
      if (surveyType === "checkbox") {
        creator.JSON = {
          ...poll,
          elements: [
            {
              ...poll.elements[0],
              type: surveyType,
              // title: poll.title,
              // choices: [
              //   {
              //     value: "Item 1",
              //     text: `${t("wizard.steps.ballot_design.option")} 1`,
              //   },
              //   {
              //     value: "Item 2",
              //     text: `${t("wizard.steps.ballot_design.option")} 2`,
              //   },
              //   {
              //     value: "Item 2",
              //     text: `${t("wizard.steps.ballot_design.option")} 3`,
              //   },
              // ],
            },
          ],
        };
      } else {
        creator.JSON = {
          ...poll,
          elements: [
            {
              ...poll.elements[0],
              type: surveyType,
              // title: poll.title,
              // choices: [
              // {
              //   value: "Item 1",
              //   text: `${t("wizard.steps.ballot_design.candidate")} 1`,
              // },
              // {
              //   value: "Item 2",
              //   text: `${t("wizard.steps.ballot_design.candidate")} 2`,
              // },
              // {
              //   value: "Item 2",
              //   text: `${t("wizard.steps.ballot_design.candidate")} 3`,
              // },
              // ],
            },
          ],
        };
      }
      // console.log("POLL: ", poll);
    } else {
      creator.JSON = {
        ...poll,
        elements: [
          {
            ...poll.elements[0],
            type: surveyType,
          },
        ],
      };
    }
  }, [surveyType, language]);

  // Render the survey
  return (
    <Container className={styles.creatorContainer} maxWidth='xl'>
      <SurveyCreatorComponent creator={creator} />
    </Container>
  );
};

export default PollCreatorComponent;
