import { createAsyncThunk } from "@reduxjs/toolkit";
import { VOTER_ACTIONS } from "../actions/voterAction";
import { fetchVoters } from "../../services/api/voter.api";
import { Voter } from "../../types/voter";
import * as Sentry from "@sentry/react";

export const getVoters = createAsyncThunk<Voter[]>(
  VOTER_ACTIONS.GET_VOTERS,
  async (params, thunkAPI) => {
    try {
      const { data: responseData } = await fetchVoters();

      return responseData.data;
    } catch (error) {
      Sentry.captureException(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
