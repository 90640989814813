import { Box, Grid, Typography } from "@mui/material";
import React, { memo, useEffect } from "react";
import styles from "../styles/PollCards.module.scss";
type Props = {
  Icon: React.ElementType;
  handleClick: (label: string) => void;
  label: string;
  value: string;
};

const TabCard = memo(({ Icon, handleClick, label, value }: Props) => {
  return (
    <Grid
      container
      item
      flex={1}
      justifyContent={"center"}
      gap={1}
      alignItems={"center"}
      onClick={() => handleClick(value)}
    >
      <Icon className='cardContent' />
      <Typography variant='body2' className={styles.text}>
        {label}
      </Typography>
    </Grid>
  );
});

export default TabCard;
