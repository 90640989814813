import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectLanguage } from "../../store/selectors/uiSelector";
import { setLanguage } from "../../store/actions/uiActions";
import en from "../../assets/en.svg";
import gr from "../../assets/gr.svg";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // console.log(language);

  const getLanImage = (language: string) => {
    switch (language) {
      case "enUS":
        return en;
      case "elGR":
        return gr;
      default:
        return en;
    }
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (newLanguage: string) => {
    dispatch(setLanguage(newLanguage));
    handleLanguageClose();
  };

  return (
    <div>
      <IconButton
        color='inherit'
        aria-label='open language menu'
        onClick={(e) => handleLanguageClick(e)}
        edge='start'
        sx={{
          marginRight: 1,
          marginLeft: 2,
        }}
      >
        <img
          src={getLanImage(language)}
          width='30'
          height='30'
          alt={"Language"}
        />
      </IconButton>
      <Menu
        sx={{ padding: 10 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLanguageClose}
      >
        <MenuItem onClick={() => handleLanguageChange("enUS")}>
          <img src={en} width='20' height='20' alt={"English"} />
          <Typography>&nbsp;English</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange("elGR")}>
          <img src={gr} width='20' height='20' alt={"Greek"} />
          <Typography>&nbsp;Ελληνικά</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;
