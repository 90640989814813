import AuthCheck from "../AuthCheck";

const HomePage = () => {
  // const navigate = useNavigate();
  // const token = getToken();

  // useEffect(() => {
  //   if (token) {
  //     console.log("Auth: ", token);
  //     const role = getRole();
  //     role && navigate(redirectPaths[role]);
  //   }else{
  //     navigate('/login');
  //   }
  // }, [token]);

  return (
    <AuthCheck>
      <></>
    </AuthCheck>
  );
};
export default HomePage;
