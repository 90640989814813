import { createReducer } from "@reduxjs/toolkit";
import { UI_ACTIONS } from "../actions/uiActions";
import { ActionWithPayload, TableState } from "../../types/redux";
import i18n from "../../i18n";
import { PollStatus } from "../../types/poll";

export interface UiState {
  language: string;
  activeChoice: string;
  activeType: PollStatus;
  tableStateVoters: TableState;
  tableStateGroups: TableState;
  tableStatePolls: TableState;
  loading: boolean;
}

export const initialUiState: UiState = {
  language: "enUS",
  activeChoice: "groups",
  activeType: "ALL",
  tableStateVoters: { rpp: 10, page: 0 },
  tableStateGroups: { rpp: 10, page: 0 },
  tableStatePolls: { rpp: 5, page: 0 },
  loading: false,
};

const uiReducer = createReducer(initialUiState, (builder) => {
  builder.addCase(
    UI_ACTIONS.SET_LANGUAGE,
    (state, action: ActionWithPayload<string>) => {
      // console.log("REDUCER: ", action.payload);
      localStorage.setItem("i18nextLng", action.payload);
      i18n.changeLanguage(action.payload);
      return { ...state, language: action.payload };
    }
  );
  builder.addCase(
    UI_ACTIONS.GET_ACTIVE_CHOICE,
    (state, action: ActionWithPayload<string>) => {
      return { ...state, activeChoice: action.payload };
    }
  );
  builder.addCase(
    UI_ACTIONS.GET_ACTIVE_TYPE,
    (state, action: ActionWithPayload<PollStatus>) => {
      return { ...state, activeType: action.payload };
    }
  );
  builder.addCase(
    UI_ACTIONS.GET_TABLE_STATE_VOTERS,
    (state, action: ActionWithPayload<TableState>) => {
      return { ...state, tableStateVoters: action.payload };
    }
  );
  builder.addCase(
    UI_ACTIONS.GET_TABLE_STATE_GROUPS,
    (state, action: ActionWithPayload<TableState>) => {
      return { ...state, tableStateGroups: action.payload };
    }
  );
  builder.addCase(
    UI_ACTIONS.GET_TABLE_STATE_POLLS,
    (state, action: ActionWithPayload<TableState>) => {
      return { ...state, tableStatePolls: action.payload };
    }
  );
});

export default uiReducer;
