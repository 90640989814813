import { useState, useEffect } from "react";
import { getConnection, getContract } from "../services/bc/getConnection";
import { ethers } from "ethers-test";

export const useContract = () => {
  const [contract, setContract] = useState<any>();
  const [tCount, setTCount] = useState<number | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const provider = await getConnection();
        const tCount = await provider.getTransactionCount(
          "0x85881f42f71CC5c23fC10644fD1E2eF5d9E1FF9c"
        );

        console.log("Provider: ", tCount);
        const newContract = getContract(provider);
        setContract(newContract);
        tCount && setTCount(tCount);
      } catch (error) {
        console.error("Error fetching contract:", error);
      }
    };

    fetchData();
  }, []);

  return { contract, tCount };
};
