import Cookies from "js-cookie";
import { sendRefreshToken } from "../../services/auth/auth";
import { COOKIE_NAME, COOKIE_NAME_REF } from "../../consts/cookie";

export const useLogout = () => {
  const logout = () => {
    const reftoken = Cookies.get(COOKIE_NAME_REF);

    if (reftoken) {
      sendRefreshToken(reftoken);
      Cookies.remove(COOKIE_NAME);
      Cookies.remove(COOKIE_NAME_REF);
    }
  };

  return { logout };
};
