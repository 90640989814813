import { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import { LOGO } from "../consts/title";
import { StyledListButton, StyledListText } from "./styled/List";
import { useAppSelector, useAppThunkDispatch } from "../store/hooks";
import { selectProfile } from "../store/selectors/userSelector";
import { Link, NavLink } from "react-router-dom";
import { usePathname } from "../hooks/usePathname";
import { Typography } from "@mui/material";
import { useLogout } from "../hooks/auth/useLogout";
import { logoutAction } from "../store/actions/userActions";
import { persistor } from "../store/store";
import { motion } from "framer-motion";
import styles from "../styles/Layout.module.scss";
import variables from "../styles/variables.module.scss";
import { AppBar } from "./styled/AppBar";
import { getDetails } from "../utils/navUtils";

const variants = {
  open: { opacity: 1, width: "15rem", height: "15rem" },
  closed: { opacity: 0, height: 0 },
};

const NavPhone = () => {
  const { logout } = useLogout();
  const dispatch = useAppThunkDispatch();

  const [open, setOpen] = useState(false);
  const paths = ["Polls", "Electorate", "About", "Logout"];
  const pathName = usePathname().split("/");
  const temp = pathName.length > 2 ? pathName[2] : "";
  const subTitle = temp.charAt(0).toUpperCase() + temp.slice(1);
  const profile = useAppSelector(selectProfile);

  const getListItem = (text: string) => {
    const sxItemButton = {
      minHeight: 48,
      justifyContent: open ? "initial" : "center",
      px: 2.5,
    };

    const sxItemIcon = {
      color: "inherit",
      minWidth: 0,
      mr: open ? 3 : "auto",
      justifyContent: "center",
    };

    return (
      <Tooltip
        key={text}
        title={getDetails(text).title}
        disableHoverListener={open}
        placement='right'
      >
        <ListItem key={text} disablePadding sx={{ display: "block" }}>
          <StyledListButton
            sx={sxItemButton}
            component={NavLink}
            to={
              text === "Logout"
                ? "/login"
                : text === "Dashboard"
                ? "/dashboard"
                : `/dashboard/${text.toLowerCase()}`
            }
            onClick={() => {
              setOpen(false);
              if (text === "Logout") {
                dispatch(logoutAction());
                persistor.purge();
                logout();
              }
            }}
          >
            <ListItemIcon sx={sxItemIcon}>{getDetails(text).icon}</ListItemIcon>
            <StyledListText
              primary={getDetails(text).title}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </StyledListButton>
        </ListItem>
      </Tooltip>
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: variables.background_base,
          position: "absolute",
          zIndex: 1200,
        }}
      >
        <CssBaseline />
        <Box>
          <Box>
            <AppBar drawerwidth={0}>
              <Toolbar
                style={{
                  width: "100%",
                  display: "flex",
                  height: "5rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: variables.white,
                  background: variables.color_primary,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <motion.div
                    className='fixed z-40 lg:top-8 top-6 right-[17px] space-y-1 items-center justify-center px-4 py-3 rounded-tl-sm rounded-bl-sm'
                    onClick={() => setOpen(!open)}
                    initial={false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 4,
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <motion.div
                      className={styles.hamburger_top}
                      animate={{
                        y: open ? 6 : 0,
                        rotate: open ? 60 : 0,
                      }}
                      transition={{
                        delay: 0.1,
                        y: { duration: 0.1 },
                        rotate: { duration: 0.5 },
                        default: { ease: "linear" },
                      }}
                    ></motion.div>
                    <motion.div
                      className={styles.hamburger_middle}
                      animate={{
                        opacity: open ? 0 : 1,
                      }}
                      transition={{
                        delay: 0.1,
                        opacity: { duration: 0.5 },

                        default: { ease: "linear" },
                      }}
                    ></motion.div>
                    <motion.div
                      className={styles.hamburger_bottom}
                      animate={{
                        y: open ? -6 : 0,
                        rotate: open ? 120 : 0,
                      }}
                      transition={{
                        delay: 0.1,
                        y: { duration: 0.1 },
                        rotate: { duration: 0.5 },
                        default: { ease: "linear" },
                      }}
                    ></motion.div>
                  </motion.div>
                  <Link to={"/dashboard/polls"}>{LOGO}</Link>
                </Box>
                <Typography variant='h5'>
                  {getDetails(subTitle).title}
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
        </Box>
        <motion.div
          animate={open ? "open" : "closed"}
          initial='closed'
          transition={{ duration: 0.5 }}
          variants={variants}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: open ? 70 : 0,
            paddingTop: open ? 30 : 0,
            paddingBottom: open ? 10 : 0,
            background: variables.sidenav_background,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 0,
            position: "relative",
            zIndex: "1200",
          }}
        >
          <List>
            {paths.map((path, index) => (
              <Box key={index}>
                {/* {index !== 0 && <StyledDivider variant='middle' />} */}
                {getListItem(path)}
              </Box>
            ))}
          </List>
        </motion.div>
      </Box>
    </>
  );
};

export default NavPhone;
