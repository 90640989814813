import { Poll } from "../../types/poll";
import { Box, LinearProgress } from "@mui/material";
import { Timer } from "./Countdown";
import moment from "moment";
import { theme } from "../../consts/theme";
import { ThemeProvider } from "@emotion/react";

type Props = {
  poll: Poll;
  timer: Timer;
};

const ProgressBar = ({ poll, timer }: Props) => {
  const totalSeconds =
    timer.days * 24 * 60 * 60 +
    timer.hours * 60 * 60 +
    timer.minutes * 60 +
    timer.seconds;
  const startDate = moment(poll.startDate, "DD/MM/YYYY HH:mm:ss");
  const endDate = moment(poll.endDate, "DD/MM/YYYY HH:mm:ss");

  // Calculate the duration of the countdown in seconds
  const durationInSeconds = endDate.diff(startDate, "seconds");

  // Calculate the progress as a percentage
  const progress =
    ((durationInSeconds - totalSeconds) / durationInSeconds) * 100;

  return poll.status === "UPCOMING" || poll.status === "ACTIVE" ? (
    <Box sx={{ width: "50%", mr: 1 }}>
      <ThemeProvider theme={theme}>
        <LinearProgress
          value={progress}
          variant='determinate'
          color={timer.days < 1 ? "error" : "secondary"}
        />
      </ThemeProvider>
    </Box>
  ) : null;
};

export default ProgressBar;
