import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Group } from "../../../types/group";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import PreviewCandidateGroups from "../pollPreview/PreviewCandidateGroups";

const SelectGroups = ({
  storedGroups,
  groups,
  setGroups,
}: {
  storedGroups: Group[];
  groups: string[];
  setGroups: (groups: string[]) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Container className='select-groups' sx={styles.container} maxWidth={false}>
      <CustomAutocomplete
        items={storedGroups.map((group) => ({
          label: group.title,
          value: group._id,
        }))}
        selectedItems={storedGroups
          .filter((group) => groups.includes(group._id))
          .map((group) => ({ label: group.title, value: group._id }))}
        setSelectedItems={setGroups}
        label={t("electorate.form.fields.groups")}
      />
      <Box>
        {groups.length > 0 && (
          <PreviewCandidateGroups
            groups={groups}
            shrinked={false}
            fw={true}
            pollType={"ALL"}
          />
        )}
      </Box>
    </Container>
  );
};

export default SelectGroups;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "stretch",
    width: "100%",
  },
};
