import VoterComponent from "../../../../components/elecotrate/VoterComponent";
import { useAppSelector, useAppThunkDispatch } from "../../../../store/hooks";
import { useParams } from "react-router-dom";
import { getActiveVoter } from "../../../../store/actions/voterAction";
import { selectVoters } from "../../../../store/selectors/voterSelector";
import { useEffect } from "react";
import { Container, Grow } from "@mui/material";
import variables from "../../../../styles/variables.module.scss";

const PreviewVoterPage = () => {
  const dispatch = useAppThunkDispatch();
  const data = useAppSelector(selectVoters);
  const { id } = useParams();

  console.log(id);

  const activeVoter = data.find((voter) => voter._id === id?.toString());

  activeVoter && dispatch(getActiveVoter(activeVoter));

  useEffect(() => {
    return () => {
      dispatch(getActiveVoter(null));
    };
  }, []);
  console.log(activeVoter);

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      timeout={1000}
      unmountOnExit
    >
      <Container maxWidth={false} sx={{ paddingTop: variables.padTop }}>
        <VoterComponent />
      </Container>
    </Grow>
  );
};

export default PreviewVoterPage;
