import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import variables from "../../styles/variables.module.scss";
import { Poll } from "../../types/poll";

export interface Timer {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

type Props = {
  poll: Poll;
  timer: Timer;
};

const Countdown = ({ poll, timer }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid justifyContent={"start"} alignItems={"start"} textAlign={"end"}>
      <Box>
        {poll.status === "UPCOMING" && (
          <span
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: "20px",
              background: timer.days < 1 ? "red" : variables.color_secondary,
              color: "#fff",
            }}
          >
            {timer.days > 0
              ? timer.days === 1
                ? `${t("general.countdown.starts_in")} ${timer.days} ${t(
                    "general.countdown.day"
                  )}`
                : `${t("general.countdown.starts_in")} ${timer.days} ${t(
                    "general.countdown.days"
                  )}`
              : `${t("general.countdown.starts_in")} ${timer.hours}:${
                  timer.minutes >= 10 ? timer.minutes : `0${timer.minutes}`
                }:${timer.seconds >= 10 ? timer.seconds : `0${timer.seconds}`}`}
          </span>
        )}
        {poll.status === "ACTIVE" && (
          <span
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: "20px",
              background: variables.color_secondary,
              color: "#fff",
            }}
          >
            {timer.days !== undefined && timer !== undefined
              ? timer.days > 0
                ? timer.days === 1
                  ? `${t("general.countdown.left_sentence_s", {
                      number: timer.days,
                    })}`
                  : `${t("general.countdown.left_sentence_p", {
                      number: timer.days,
                    })}`
                : `${t("general.countdown.left", {
                    time: `${timer.hours}:${
                      timer.minutes >= 10 ? timer.minutes : `0${timer.minutes}`
                    }:${
                      timer.seconds >= 10 ? timer.seconds : `0${timer.seconds}`
                    }`,
                  })}`
              : "Completed"}
          </span>
        )}
        {poll.status === "PAST" && (
          <span
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
              borderRadius: "20px",
              background: "rgba(86, 202, 0, 0.12)",
              color: "#fff",
            }}
          >
            Completed
          </span>
        )}
      </Box>
    </Grid>
  );
};

export default Countdown;
