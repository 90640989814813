import styles from "../../../styles/PollCards.module.scss";
import { Container, Grid } from "@mui/material";
import { usePathname } from "../../../hooks/usePathname";
import PollCards from "../../../components/pollComponents/PollCards";
import { Key, useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { Poll, PollStatus } from "../../../types/poll";
import { selectPolls } from "../../../store/selectors/pollSelector";
import { useNavigate } from "react-router-dom";
import { removePoll } from "../../../store/actions/pollActions";
import PollSingle from "../../../components/pollComponents/PollSingle";
import { useTranslation } from "react-i18next";
import { getVoters } from "../../../store/effects/voterEffects";
import { useContract } from "../../../hooks/useContract";
import { selectProofZkMerkleTree } from "../../../store/selectors/blockchainSelector";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { getDrafts, getElections } from "../../../store/effects/pollEffects";
import { deletePoll } from "../../../services/api/polls.api";
import { selectType } from "../../../store/selectors/uiSelector";
import AlertDialog from "../../../components/utils/CustomAlertDialog";
import { selectVoters } from "../../../store/selectors/voterSelector";
import { getGroups } from "../../../store/effects/groupEffects";
import * as Sentry from "@sentry/react";

const PollPage = () => {
  const { t } = useTranslation();
  const activeChoice = useAppSelector(selectType);
  const ungroupedVoters = useAppSelector(selectVoters).filter(
    (voter) => voter.groups.length === 0
  );
  const [pollType, setPollType] = useState<PollStatus>(activeChoice);
  // const [page, setPage] = useState(1);
  const dispatch = useAppThunkDispatch();
  const router = useNavigate();
  const pathname = usePathname();
  const [alert, setAlert] = useState<null | string>(null);
  const [pollToDelete, setPollToDelete] = useState<Poll | null>(null);

  // const demo = useAppSelector((state) => state.blockchain);
  // console.log("Demo Proof", demo);

  useEffect(() => {
    dispatch(getVoters());
    dispatch(getGroups());
    dispatch(getDrafts());
  }, []);

  const { contract } = useContract();
  const proof = useAppSelector(selectProofZkMerkleTree).proof;
  const signals = useAppSelector(selectProofZkMerkleTree).publicSignals;
  const elections = useAppSelector(selectPolls).filter(
    (election) => !election.hidden
  );

  // const fetchElectionsAsElectionLeader = async () => {
  //   return await contract.fetchElectionsAsElectionLeader(proof, signals);
  // };

  console.log("ELECTIONS: ", elections);

  useEffect(() => {
    // console.log(contract);
    contract && dispatch(getElections({ contract, proof, signals }));
  }, [contract]);

  const handleDeleteConfirmation = () => {
    if (pollToDelete) {
      deletePoll(pollToDelete._id)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removePoll(pollToDelete._id));
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error("Axios request error:", error);
          setAlert(error);
        });
    }
    setAlert(null);
    setPollToDelete(null);
  };

  const ProcessSignleRow = (data: Poll): any => {
    const actions = [
      {
        label: t("general.menu.options.more"),
        action: (e: React.MouseEvent<HTMLElement>) => {
          const id = `${data._id}draft${data.draft_id}`;
          router(`${pathname}/${id}/`);
        },
        icon: <Visibility />,
        show: data.status !== "DRAFT",
      },
      {
        label: t("general.menu.options.edit"),
        action: (e: React.MouseEvent<HTMLElement>) => {
          router(`${pathname}/edit/${data._id}/`);
        },
        icon: <Edit />,
        show: data.status === "DRAFT",
      },
      {
        label: t("general.menu.options.delete"),
        action: (e: React.MouseEvent<HTMLElement>) => {
          console.log(data);
          setAlert(t("general.menu.warning.message", { id: data.title }));
          setPollToDelete(data);
        },
        icon: <Delete />,
        show: data.status === "DRAFT",
      },
    ];

    return { ...data, actions };
  };

  const memoizedData = useMemo(() => {
    if (pollType !== "ALL") {
      return elections.filter((poll) => poll.status === pollType);
    } else {
      const activePolls = elections.filter((poll) => poll.status === "ACTIVE");
      const otherPolls = elections.filter((poll) => poll.status !== "ACTIVE");
      return [...activePolls, ...otherPolls];
    }
  }, [pollType, elections]);

  return (
    <Container maxWidth={false} className={styles.container}>
      <div className={styles.grid}>
        <PollCards
          setPollType={setPollType}
          pollType={pollType}
          ungroupedVoters={ungroupedVoters.length}
        />
      </div>
      {/* <div>
          <TextField id="outlined-basic" onChange={(e)=>inputHandler(e)} label="Search" />
        </div> */}
      <Grid
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
        width={"100%"}
        style={{ marginTop: 20 }}
      >
        {memoizedData &&
          memoizedData.map((d: Poll, index: Key | null | undefined) => (
            <PollSingle key={index} poll={ProcessSignleRow(d)} />
          ))}
      </Grid>
      {/* <Pagination count={Math.ceil(data.length/6)} onChange={handlePageChange} sx={{
          display:'flex',
          justifyContent:'center',
          marginTop: 1,
        }}/> */}
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={
            pollToDelete
              ? [
                  { label: "Cancel", onClick: () => setAlert(null) },
                  { label: "Ok", onClick: () => handleDeleteConfirmation() },
                ]
              : [{ label: "Ok", onClick: () => setAlert(null) }]
          }
        />
      )}
    </Container>
  );
};

export default PollPage;
