import { Container } from "@mui/material";
import Chart, { ChartTypeRegistry } from "chart.js/auto";
import React, { memo, useEffect, useRef } from "react";
import styles from "../../styles/Chart.module.scss";
import { Colors } from "chart.js";

Chart.register(Colors);

const ChartComp = ({
  type,
  title,
  data,
  axis,
}: {
  type: string;
  title: string;
  data: {
    datasets: any[];
  };
  axis?: "x" | "y";
}) => {
  const canvas = useRef(null);

  useEffect(() => {
    const ctx = canvas.current;

    // console.log(type);

    const chartStatus = Chart.getChart("chart");
    if (chartStatus !== undefined) {
      chartStatus.destroy();
    }

    const chart = new Chart(ctx!, {
      type: type as keyof ChartTypeRegistry,
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: axis,
        scales: {
          x: {
            // stacked: data.datasets.length>1 ? true : false,
            display: type === "bar" && false,
          },
          y: {
            // stacked: data.datasets.length>1 ? true : false,
            display: type === "bar" && false,
          },
        },
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: title,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || "";
                if (label && type === "pie") {
                  return label + ": " + context.parsed.toFixed(2) + "%";
                }
              },
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <Container max-width='xl'>
      <div className={styles.chart}>
        <canvas id='myChart' ref={canvas}></canvas>
      </div>
    </Container>
  );
};

export default memo(ChartComp);
