import { useAppSelector, useAppThunkDispatch } from "../../../../store/hooks";
import { useParams } from "react-router-dom";
import { selectGroups } from "../../../../store/selectors/groupSelector";
import { getActiveGroup } from "../../../../store/actions/groupAction";
import GroupComponent from "../../../../components/elecotrate/GroupComponent";
import { useEffect } from "react";
import { Container, Grow } from "@mui/material";
import variables from "../../../../styles/variables.module.scss";

function PreviewGroupPage() {
  const dispatch = useAppThunkDispatch();
  const data = useAppSelector(selectGroups);
  const { id } = useParams();

  console.log(id);

  const activeGroup = data.find((group) => group._id === id?.toString());

  activeGroup && dispatch(getActiveGroup(activeGroup));

  // was commented out, why?!
  // group preview doesnt keep active group to mess with the create form
  // same with voters
  useEffect(() => {
    return () => {
      dispatch(getActiveGroup(null));
    };
  }, []);

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      timeout={1000}
      unmountOnExit
    >
      <Container maxWidth={false} sx={{ paddingTop: variables.padTop }}>
        <GroupComponent />
      </Container>
    </Grow>
  );
}

export default PreviewGroupPage;
