import { ApiResponse, CreatePayload } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { Voter, VoterApi } from "../../types/voter";

export const voterClient = () => Axios(`${getApiUrl()}/voter`);

export async function createNewVoter(values: CreatePayload<VoterApi>) {
  return await voterClient().post("/create", values);
}

export async function updateVoter(values: VoterApi) {
  return await voterClient().put("/update", values);
}

export async function deleteVoter(values: Voter) {
  return await voterClient().delete("/delete", {
    params: { email: values._id },
  });
}

export async function fetchVoters() {
  return voterClient().get<ApiResponse<Voter[]>>("/get/all");
}

export async function deleteRequest(email: string) {
  return await voterClient().post("/delete/mail", null, {
    params: { email },
  });
}

export async function deleteConfirm(code: string) {
  return await voterClient().delete(`/delete/${code}`);
}
