import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import { AppBar } from "./styled/AppBar";
import { Drawer, DrawerHeader, StyledDivider } from "./styled/Drawer";
import { LOGO } from "../consts/title";
import { StyledListButton, StyledListText } from "./styled/List";
import { useAppSelector, useAppThunkDispatch } from "../store/hooks";
import { selectProfile } from "../store/selectors/userSelector";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { usePathname } from "../hooks/usePathname";
import { Typography } from "@mui/material";
import { useLogout } from "../hooks/auth/useLogout";
import { getProfile, logoutAction } from "../store/actions/userActions";
import { persistor } from "../store/store";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./utils/LanguageSelector";
import { getDetails } from "../utils/navUtils";
import { getUsername } from "../services/api/utils.api";
import { fetchEL, fetchPhotoEL } from "../services/api/el.api";
import * as Sentry from "@sentry/react";

const drawerWidth = 250;

const Nav = () => {
  const { logout } = useLogout();
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();

  const [open, setOpen] = useState(false);
  const paths = ["Polls", "Electorate", "About", "Logout"];
  const pathName = usePathname().split("/");
  const temp = pathName.length > 2 ? pathName[2] : "";
  const subTitle = temp.charAt(0).toUpperCase() + temp.slice(1);
  const username = getUsername();
  const photo = useAppSelector(selectProfile)?.photo;
  const profile = useAppSelector(selectProfile);
  const router = useNavigate();

  useEffect(() => {
    console.log("photo");
    fetchEL(username)
      .then((profileData) => {
        fetchPhotoEL(username)
          .then(async (response) => {
            let photo;
            if (response.data === null) {
              photo = undefined;
            } else {
              console.log("RES: ", response.data);

              photo = URL.createObjectURL(new Blob([response.data]));
            }
            dispatch(getProfile({ ...profileData.data.data, photo }));
          })
          .catch((error) => {
            console.log(error);
            Sentry.captureException(error);
            alert(error.message);
            // setError(error.message);
          });
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
        alert(error.message);
        // setError(error.message);
      });
  }, []);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const getListItem = (text: string) => {
    const sxItemButton = {
      minHeight: 48,
      justifyContent: open ? "initial" : "center",
      px: 2.5,
    };

    const sxItemIcon = {
      color: "inherit",
      minWidth: 0,
      mr: open ? 3 : "auto",
      justifyContent: "center",
    };

    return (
      <Tooltip
        key={text}
        title={getDetails(text).title}
        disableHoverListener={open}
        placement='right'
      >
        <ListItem key={text} disablePadding sx={{ display: "block" }}>
          <StyledListButton
            sx={sxItemButton}
            component={NavLink}
            to={
              text === "Logout"
                ? "/login"
                : text === "Dashboard"
                ? "/dashboard"
                : `/dashboard/${text.toLowerCase()}`
            }
            onClick={() => {
              setOpen(false);
              if (text === "Logout") {
                dispatch(logoutAction());
                persistor.purge();
                logout();
              }
            }}
          >
            <ListItemIcon sx={sxItemIcon}>{getDetails(text).icon}</ListItemIcon>
            <StyledListText
              primary={getDetails(text).title}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </StyledListButton>
        </ListItem>
      </Tooltip>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position='fixed' open={open} drawerwidth={drawerWidth}>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 3,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawer}
              edge='start'
              sx={{
                marginRight: 5,
                height: 40,
                width: 40,
                // ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link to={"/dashboard/polls"}>{LOGO}</Link>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant='h5'>{getDetails(subTitle).title}</Typography>
            <LanguageSelector />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        transitionDuration={700}
        variant={open ? "temporary" : "permanent"}
        anchor='left'
        open={open}
        drawerwidth={drawerWidth}
        onClose={() => setOpen(false)}
      >
        <DrawerHeader>
          {photo ? (
            <img
              src={photo}
              // src={`data:image/jpeg;charset=utf-8;base64,${photo}`}
              height={"80%"}
              width={"80%"}
              style={{
                paddingLeft: 25,
                paddingRight: 25,
                paddingBottom: 15,
                paddingTop: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                router(`/dashboard/${username}`);
              }}
            />
          ) : (
            <AccountCircleIcon
              // viewBox={"5 2 10 20"}
              // preserveAspectRatio="none"
              style={{
                width: "75%",
                height: "75%",
                cursor: "pointer",
              }}
              onClick={() => {
                router(`/dashboard/${username}`);
              }}
            />
          )}
          <Typography>{username}</Typography>
        </DrawerHeader>
        <StyledDivider variant='middle' />
        <List>{paths.map((path) => getListItem(path))}</List>
        <StyledDivider variant='middle' />
      </Drawer>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box> */}
    </Box>
  );
};

export default Nav;
