import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { StyledButton } from "./styled/Button";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppThunkDispatch } from "../store/hooks";
import { useLogin } from "../hooks/auth/useLogin";
import { getProfile } from "../store/actions/userActions";
import { getRole } from "../services/api/utils.api";
import { redirectPaths } from "../utils/permissions";
import { StyledTextField } from "./styled/TextField";
import styles from "../styles/Login.module.scss";
import LanguageSelector from "./utils/LanguageSelector";
import { useTranslation } from "react-i18next";
import { prove } from "../services/bc/prove";
import keys from "../assets/privateKeys.json";
import {
  setPathItems,
  setProofzKAddress,
  setProofzKMerkleTree,
} from "../store/actions/blockchainActions";
import Loading from "./utils/Loading";
import { transformProof } from "../utils/bc/transformProof";
import { fetchElectionProof } from "../services/bc/electionLeaderMerkleProof";
import { useContract } from "../hooks/useContract";
import { bcState } from "../consts/demo";
import AlertDialog from "./utils/CustomAlertDialog";
import { Field, Formik } from "formik";
import { yupValidationLogin } from "../utils/yupValidation";
import { getJwt } from "../services/auth/auth";
import { AuthApi } from "../types/api";
import * as Sentry from "@sentry/react";
import { fetchEL, fetchPhotoEL } from "../services/api/el.api";
import { ZERO_ARR } from "../consts/bc";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Buffer } from "buffer";

function Login() {
  const { setCookie, login } = useLogin();
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useAppThunkDispatch();
  const { contract } = useContract();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jwt, setJwt] = useState<AuthApi | null>(null);

  return (
    <Paper
      elevation={1}
      style={{ height: "100%" }}
      sx={{ background: "rgba(255, 255, 255,.9)" }}
    >
      <Grid
        container
        alignItems={"center"}
        maxWidth='md'
        style={{ height: "100%" }}
      >
        <Box className={styles.paper}>
          <Box className={styles.box}>
            <Typography variant='h5'>Trevo</Typography>
            <Typography variant='h6'>{t("login.message")}</Typography>
            {isLoading ? (
              <Loading message={t("general.waitProofs")} h={"100%"} />
            ) : (
              <Formik
                initialValues={{ username: "", password: "" }}
                onSubmit={(values, actions) => {
                  console.log(values);
                  setLoading(true);

                  // getJwt(values.username, values.password)
                  login(values.username, values.password)
                    .then((res) => {
                      //   if (res.status === 200) {
                      //     setJwt(res.data.data);
                      //   }
                      dispatch(
                        setPathItems({
                          pathElements: bcState.pathItems.pathElements,
                          pathIndices: bcState.pathItems.pathIndices,
                        })
                      );
                      dispatch(
                        setProofzKAddress({
                          proof: bcState.zkEthereumAddress.proof,
                          publicSignals:
                            bcState.zkEthereumAddress.publicSignals,
                        })
                      );
                      dispatch(
                        setProofzKMerkleTree({
                          proof: bcState.zkMerkleTree.proof,
                          publicSignals: bcState.zkMerkleTree.publicSignals,
                        })
                      );
                      // dispatch(getProfile(values.username));

                      fetchEL(values.username)
                        .then((profileData) => {
                          fetchPhotoEL(values.username)
                            .then(async (response) => {
                              let photo;
                              if (response.data === null) {
                                photo = undefined;
                              } else {
                                console.log("RES: ", response.data);

                                photo = URL.createObjectURL(
                                  new Blob([response.data])
                                );
                              }
                              dispatch(
                                getProfile({ ...profileData.data.data, photo })
                              );

                              setLoading(false);
                              const role = getRole();
                              role && router(redirectPaths[role]);
                            })
                            .catch((error) => {
                              console.log(error);
                              Sentry.captureException(error);
                              alert(error.message);
                              // setError(error.message);
                              setLoading(false);
                            });
                        })
                        .catch((error) => {
                          console.log(error);
                          Sentry.captureException(error);
                          alert(error.message);
                          // setError(error.message);
                          setLoading(false);
                        });

                      //   prove({
                      //     electionId: "0",
                      //     option: "0",
                      //     secret: "0",
                      //     privateKey: keys[0].privTuple,
                      //     pathElements: ZERO_ARR,
                      //     pathIndices: ZERO_ARR,
                      //   })
                      //     .then(({ proof, publicSignals }) => {
                      //       console.log("Proof:", proof);
                      //       console.log("PublicSignals:", publicSignals);
                      //       const newProof = transformProof(proof);

                      //       fetchElectionProof(newProof, publicSignals, contract)
                      //         .then((res) => {
                      //           console.log("P1", res);
                      //           const pathElements = res[0].map(String);
                      //           const pathIndices = res[1].map(String);
                      //           console.log("Proof 1");
                      //           dispatch(
                      //             setPathItems({ pathElements, pathIndices })
                      //           );
                      //           dispatch(
                      //             setProofzKAddress({
                      //               proof: newProof,
                      //               publicSignals,
                      //             })
                      //           );

                      //           prove({
                      //             electionId: "0",
                      //             option: "0",
                      //             secret: "0",
                      //             privateKey: keys[0].privTuple,
                      //             pathElements: pathElements,
                      //             pathIndices: pathIndices,
                      //           })
                      //             .then(({ proof, publicSignals }) => {
                      //               console.log("Proof 2");
                      //               dispatch(
                      //                 setProofzKMerkleTree({
                      //                   proof: transformProof(proof),
                      //                   publicSignals,
                      //                 })
                      //               );
                      //               jwt &&
                      //                 setCookie(jwt).then(() => {
                      //                   setLoading(false);

                      //                   fetchEL(values.username)
                      //                     .then((res) => {
                      //                       dispatch(getProfile(res.data.data));
                      //                       setLoading(false);
                      //                       const role = getRole();
                      //                       role && router(redirectPaths[role]);
                      //                     })
                      //                     .catch((error) => {
                      //                       console.log(error);
                      //                       Sentry.captureException(error);
                      //                       alert(error.message);
                      //                       // setError(error.message);
                      //                       setLoading(false);
                      //                     });
                      //                 });
                      //             })
                      //             .catch((error: any) => {
                      //               Sentry.captureException(error);
                      //               console.log("Proof 2: ", error);
                      //               setError(
                      //                 "Please check that the private key,the \
                      //                   merkle tree wasm file and zkey you \
                      //                   have provided are correct and try again: "
                      //               );
                      //               setLoading(false);
                      //             });
                      //         })
                      //         .catch((error: any) => {
                      //           console.log("Error Fetch Election proof", error);
                      //           Sentry.captureException(error);
                      //           if (error.revert.args[0]) {
                      //             console.log(
                      //               "Error",
                      //               error.revert.args[0].replace(
                      //                 "execution reverted:",
                      //                 ""
                      //               )
                      //             );
                      //             setError(error.revert.args[0]);
                      //           } else {
                      //             setError("Error needs investigation");
                      //           }
                      //           setLoading(false);
                      //         });
                      //     })
                      //     .catch((error: any) => {
                      //       console.log("Proof 1: ", error);
                      //       Sentry.captureException(error);
                      //       setError(
                      //         "Please check that the private key,the \
                      //           ethereum address wasm file and zkey you \
                      //           have provided are correct and try again: "
                      //       );
                      //       setLoading(false);
                      //     });
                    })
                    .catch((error) => {
                      console.log(error);
                      Sentry.captureException(error);
                      alert(error.message);
                      // setError(error.message);
                      setLoading(false);
                    });
                }}
                validationSchema={yupValidationLogin}
              >
                {({
                  // values,
                  // errors,
                  // touched,
                  // setValues,
                  // setFieldValue,
                  // handleChange,
                  // handleBlur,
                  handleSubmit,
                  // isSubmitting,
                }) => (
                  <form
                    className={styles.form}
                    onSubmit={handleSubmit}
                    autoComplete='off'
                  >
                    <div className={styles.fields}>
                      <Field
                        as={StyledTextField}
                        required
                        id='username'
                        name='username'
                        label={t("login.username")}
                        variant='outlined'
                        helperText=''
                        // inputProps={{ maxLength: 8 }}
                      />
                      <Field
                        as={StyledTextField}
                        required
                        id='password'
                        name='password'
                        label={t("login.password")}
                        autoComplete='off'
                        variant='outlined'
                        type='password'
                      />

                      <StyledButton
                        className={styles.button}
                        variant='contained'
                        type='submit'
                        fullWidth
                      >
                        {t("login.name")}
                      </StyledButton>
                      <LanguageSelector />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Box>
      </Grid>
      {error && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={error}
          buttons={[{ label: "Ok", onClick: () => setError(null) }]}
        />
      )}
    </Paper>
  );
}

export default Login;
