import { Poll } from "../../types/poll";
import { createAction } from "@reduxjs/toolkit";
import { ChangePollType } from "../../types/redux";

export const POLLS_PREFIX = "polls";

export const POLL_ACTIONS = {
  GET_POLLS: `${POLLS_PREFIX}/GET_POLLS`,
  GET_DRAFTS: `${POLLS_PREFIX}/GET_DRAFTS`,
  GET_ACTIVE_POLL: `${POLLS_PREFIX}/GET_ACTIVE_POLL`,
  REMOVE_POLL: `${POLLS_PREFIX}/REMOVE_POLL`,
  ADD_POLL: `${POLLS_PREFIX}/ADD_POLL`,
  UPDATE_POLL: `${POLLS_PREFIX}/UPDATE_POLL`,
  CHANGE_STATUS_POLL: `${POLLS_PREFIX}/CHANGE_STATUS_POLL`,
} as const;

export const getActivePoll = createAction(
  POLL_ACTIONS.GET_ACTIVE_POLL,
  (poll: Poll | null): { payload: Poll | null } => {
    // console.log(user);
    return { payload: poll };
  }
);

export const changeStatusPoll = createAction(
  POLL_ACTIONS.CHANGE_STATUS_POLL,
  (poll: ChangePollType): { payload: ChangePollType } => {
    return { payload: poll };
  }
);

// export const addPoll = createAction(
//   POLL_ACTIONS.ADD_POLL,
//   (poll: Poll): { payload: Poll } => {
//     return { payload: poll };
//   }
// );

// export const updatePoll = createAction(
//   POLL_ACTIONS.UPDATE_POLL,
//   (poll: Poll): { payload: Poll } => {
//     return { payload: poll };
//   }
// );

export const removePoll = createAction(
  POLL_ACTIONS.REMOVE_POLL,
  (poll: string): { payload: string } => {
    return { payload: poll };
  }
);
