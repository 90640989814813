import { styled } from "@mui/material";
import variables from "../../styles/variables.module.scss";
import TextField from "@mui/material/TextField";

export const StyledTextField = styled(TextField)(
  () => `
width: 100%;

& label{
  color: ${variables.text_secondary};

  &.Mui-focused {
    color: ${variables.color_primary};
  }
}

& .MuiFormHelperText-root {
  text-align: right 
},


& .MuiOutlinedInput-root {
  & fieldset {
    border-color: ${variables.text_primary} ;
  }
  &:hover fieldset {
    border-color:${variables.color_primary};
  }
  &.Mui-focused fieldset {
    border-color: ${variables.color_primary};
  }
}

& .MuiFilledInput-underline:after {
  border-color: ${variables.color_primary} ;
}

`
);
