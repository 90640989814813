import { Container } from "@mui/material";
import PollHandler from "../../../components/pollComponents/Wizard";
import { selectDrafts } from "../../../store/selectors/pollSelector";
import { useAppSelector } from "../../../store/hooks";
import { useParams } from "react-router-dom";
import Loading from "../../../components/utils/Loading";

const EditPollPage = () => {
  const data = useAppSelector(selectDrafts);
  const { id } = useParams();
  const activePoll = data.find((poll) => poll._id === id?.toString());

  // console.log("Edit: ", activePoll);

  return (
    <Container maxWidth='xl'>
      {activePoll ? <PollHandler poll={activePoll} /> : <Loading />}
    </Container>
  );
};

export default EditPollPage;
