import { ApiResponse } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";
import { ElectionLeaderApi, ElectionLeaderUpdate } from "../../types/el";

export const elClient = () => Axios(`${getApiUrl()}/eleader`);
export const elClientPhotoGet = () =>
  Axios(`${getApiUrl()}/eleader`, false, true);
export const elClientPhotoUpload = () =>
  Axios(`${getApiUrl()}/eleader`, false, true);

export async function updateEL(values: ElectionLeaderUpdate) {
  return await elClient().put("/update", values);
}

export async function changePwd(username: string, password: string) {
  return await elClient().put("/update", { username, password });
}

export async function fetchEL(username: string) {
  return elClient().get<ApiResponse<ElectionLeaderApi>>("/get", {
    params: { username },
  });
}

export async function fetchPhotoEL(username: string) {
  return elClientPhotoGet().get<any>("/photo", {
    params: { username },
  });
}

export async function uploadPhotoEL(formData: any) {
  console.log("axios:", formData);
  return elClientPhotoUpload().put<ApiResponse<any>>("/photo", formData);
}
