import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload, ChangePollType } from "../../types/redux";
import { Poll } from "../../types/poll";
import { POLL_ACTIONS } from "../actions/pollActions";
import {
  getDrafts,
  getElectionById,
  getElections,
} from "../effects/pollEffects";

export interface PollState {
  polls: Poll[];
  drafts: Poll[];
  activePoll: Poll | null | undefined;
  loading: boolean;
}

export const initialPollState: PollState = {
  polls: [],
  drafts: [],
  activePoll: null,
  loading: false,
};

const pollReducer = createReducer(initialPollState, (builder) => {
  builder.addCase(getElectionById.fulfilled, (state, { payload }) => {
    // console.log(action.payload);
    console.log("Fetch Election RED: ", payload);
    // const polls: Poll[] = payload.map( poll => {
    //   return { ...poll,  };
    // });

    return {
      ...state,
      activePoll: payload,
      loading: false,
    };
  });
  builder.addCase(getElectionById.pending, (state) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(getElections.fulfilled, (state, { payload }) => {
    // console.log(action.payload);
    console.log("Elections RED: ", payload);
    // const polls: Poll[] = payload.map( poll => {
    //   return { ...poll,  };
    // });

    return {
      ...state,
      polls: payload,
      loading: false,
    };
  });
  builder.addCase(getElections.pending, (state) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(getElections.rejected, (state, { payload }) => {
    console.log("REJECTED: ", payload);
    return {
      ...state,
      loading: false,
    };
  });
  builder.addCase(getDrafts.fulfilled, (state, { payload }) => {
    // console.log(action.payload);
    console.log("Elections RED: ", payload);
    const drafts: Poll[] = payload.map((draft) => {
      return { ...draft, participation: 0, toVote: 0, votes: {} };
    });
    return {
      ...state,
      drafts,
      loading: false,
    };
  });
  builder.addCase(getDrafts.pending, (state) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(
    POLL_ACTIONS.GET_ACTIVE_POLL,
    (state, action: ActionWithPayload<Poll>) => {
      // console.log(action.payload);
      // console.log(action.type);

      return { ...state, activePoll: action.payload };
    }
  );
  builder.addCase(
    POLL_ACTIONS.REMOVE_POLL,
    (state, action: ActionWithPayload<string>) => {
      console.log("Remove: ", action.payload);
      return {
        ...state,
        drafts: state.drafts.filter((poll) => poll._id !== action.payload),
      };
    }
  );

  builder.addCase(
    POLL_ACTIONS.CHANGE_STATUS_POLL,
    (state, action: ActionWithPayload<ChangePollType>) => {
      console.log(state);
      console.log(state.polls.filter((p) => p._id == action.payload.pollId)[0]);
      return {
        ...state,
        polls: [
          ...state.polls.filter((p) => p._id !== action.payload.pollId),
          {
            ...state.polls.filter((p) => p._id === action.payload.pollId)[0],
            status: action.payload.status,
          },
        ],
      };
    }
  );
  builder.addCase(
    POLL_ACTIONS.ADD_POLL,
    (state, action: ActionWithPayload<Poll>) => {
      // console.log("RED; ", action.payload);
      // console.log("RED; ", {...state, polls: [...state.polls, action.payload]});
      return {
        ...state,
        polls: [...state.polls, action.payload],
      };
    }
  );
  builder.addCase(
    POLL_ACTIONS.UPDATE_POLL,
    (state, action: ActionWithPayload<Poll>) => {
      console.log("RED; ", action.payload);
      // console.log("RED; ", {...state, polls: [...state.polls, action.payload]});
      return {
        ...state,
        polls: state.polls.map((poll) => {
          if (poll._id === action.payload._id) {
            return action.payload;
          }
          return poll;
        }),
      };
    }
  );
});

export default pollReducer;
