import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Action, Column, TableSchema } from "../../../../types/table";
import { TableBar } from "../../../../components/styled/AppBar";
import {
  AddOne,
  StyledIconButton,
} from "../../../../components/styled/AddButton";
import CustomTable from "../../../../components/Table/CustomTable";
import { useAppSelector, useAppThunkDispatch } from "../../../../store/hooks";
import { usePathname } from "../../../../hooks/usePathname";
import { Outlet, useNavigate } from "react-router-dom";
import Loading from "../../../../components/utils/Loading";
import {
  Box,
  Container,
  FormControlLabel,
  IconButton,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  selectVoters,
  selectVotersLoading,
} from "../../../../store/selectors/voterSelector";
import { Voter } from "../../../../types/voter";
import {
  getActiveVoter,
  removeVoter,
} from "../../../../store/actions/voterAction";
import { useTranslation } from "react-i18next";
import Form from "../../../../components/forms/VoterForm";
import { Clear, Delete, Edit, Search, Visibility } from "@mui/icons-material";
import CustomModal from "../../../../components/utils/CustomModal";
import { deleteVoter } from "../../../../services/api/voter.api";
import { selectTSV } from "../../../../store/selectors/uiSelector";
import { getTableStateVoters } from "../../../../store/actions/uiActions";
import AlertDialog from "../../../../components/utils/CustomAlertDialog";
import { theme } from "../../../../consts/theme";
import { StyledFormControl } from "../../../../components/styled/StyledFormSelect";
import { StyledSwitch } from "../../../../components/styled/StyledSwitch";
import { StyledTextField } from "../../../../components/styled/TextField";
import * as Sentry from "@sentry/react";

export type VoterTable = Omit<Voter, "verified" | "registered"> & {
  verified: string;
  registered: string;
};

const Voters = () => {
  const { t } = useTranslation();
  const inputValueRef = useRef("");
  const title = t("electorate.table.title.voters");
  const voters = useAppSelector(selectVoters);
  const ungroupedVoters = voters.filter((voter) => voter.groups.length === 0);
  const [data, setData] = useState(voters);
  // const groups = useAppSelector(selectGroups);
  const ts = useAppSelector(selectTSV);
  const [page, setPage] = useState(ts.page);
  const [rowsPerPage, setRowsPerPage] = useState(ts.rpp);
  const isLoading = useAppSelector(selectVotersLoading);
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const router = useNavigate();
  const [alert, setAlert] = useState<null | string>(null);
  const [voterToDelete, setVoterToDelete] = useState<Voter | null>(null);
  const [showUngroupedVoters, setShowUngroupedVoters] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log(data);
  // console.log("vit", voters);

  useEffect(() => {
    dispatch(getTableStateVoters({ rpp: rowsPerPage, page }));
  }, [rowsPerPage, page]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(getRowsPerPage(10));
  //   };
  // }, []);

  const handleDeleteConfirmation = () => {
    if (voterToDelete) {
      deleteVoter(voterToDelete)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeVoter(voterToDelete._id));
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error("Axios request error:", error);
          setAlert(error);
        });
    }
    setAlert(null);
    setVoterToDelete(null);
  };

  const handleUngroupedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowUngroupedVoters(e.target.checked);
    if (e.target.checked) {
      setData(ungroupedVoters);
    } else {
      setData(voters);
    }
  };

  const handleSearchClick = (e: SyntheticEvent) => {
    const value = inputValueRef.current;

    if (value === "") {
      setData(voters);
    } else {
      // fetchVotersByEmail(value).then((data) => setData([data.data.data]));
      const filteredVoters = voters.filter((voter) =>
        voter._id.startsWith(value)
      );
      setData(filteredVoters);
    }
  };

  const handleClear = (e: SyntheticEvent) => {
    inputValueRef.current = "";
    setData(voters);
  };

  const ProcessRows = (data: Voter[]): TableSchema<VoterTable>[] => {
    return data.map((row) => {
      const actions: Action[] = [
        {
          label: t("general.menu.options.more"),
          icon: <Visibility />,
          action: (e: React.MouseEvent<HTMLElement>) => {
            router(`${pathname}/voters/${row._id}/`);
          },
          show: true,
        },
        {
          label: t("general.menu.options.edit"),
          icon: <Edit />,
          action: (e: React.MouseEvent<HTMLElement>) => {
            console.log(row._id);

            const activeUser = data.find(
              (user) => user._id === row._id?.toString()
            );

            activeUser && dispatch(getActiveVoter(activeUser));

            console.log(activeUser);
            handleOpen();
          },
          show: true,
        },
        {
          label: t("general.menu.options.delete"),
          icon: <Delete />,
          action: (e: React.MouseEvent<HTMLElement>) => {
            // console.log(row._id);
            setAlert(
              `${t(t("general.menu.warning.message2"), {
                id: row._id,
              })} ${t("general.menu.warning.message4")} ${t(
                "general.menu.warning.caution"
              )}`
            );
            const voter =
              data.find((voter) => voter._id === row._id.toString()) || null;
            setVoterToDelete(voter);
          },
          show: true,
        },
        // {
        //   label: t("general.menu.options.invite"),
        //   icon: <Email />,
        //   action: (e: React.MouseEvent<HTMLElement>) => {
        //     console.log("INVITE");
        //   },
        //   show: true,
        // },
      ];

      !actions.some((action) => action.show === true) &&
        actions.push({
          label: "No Available Actions",
          icon: <></>,
          action: (e: React.MouseEvent<HTMLElement>) => {
            return;
          },
          show: true,
        });

      // const titles =
      //   row.groups && row.groups.length > 0
      //     ? getTitles(groups, row.groups)
      //     : "";

      // return { ...row, actions, groups: [titles] };

      const verified = row.verified ? t("general.yes") : t("general.no");
      const registered = row.registered ? t("general.yes") : t("general.no");

      return { ...row, verified, registered, actions };
    });
  };

  const columns: Column<Voter, keyof Voter>[] = [
    // { name: "ID", key: "_id" },
    { name: t("electorate.table.columns.email"), key: "_id" },
    { name: t("electorate.table.columns.firstname"), key: "gov_firstname" },
    { name: t("electorate.table.columns.lastname"), key: "gov_lastname" },
    // { name: t("electorate.table.columns.groups"), key: "groups" },
    // { name: t("electorate.table.columns.status"), key: "status" },
    { name: t("electorate.table.columns.registered"), key: "registered" },
    { name: t("electorate.table.columns.verified"), key: "verified" },
  ];

  const VotersTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", sm: "10px", md: "20px" },
              marginRight: "10px",
            }}
          >
            <Typography variant='h2'>{title}</Typography>
            <StyledTextField
              variant='outlined'
              inputRef={inputValueRef}
              onChange={(event) => (inputValueRef.current = event.target.value)} //{(event) => setValue(event.target.value)}
              placeholder='Search…'
              sx={{
                width: "50%",
                backgroundColor: "white",
                borderRadius: "50px",
                "& fieldset": {
                  borderRadius: "50px",
                },
              }}
              autoFocus
              size='small'
              onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  handleSearchClick(ev);
                }
              }}
              InputProps={{
                startAdornment: (
                  <IconButton
                    title='Search by email'
                    aria-label='Search'
                    size='small'
                    // style={{ visibility: value ? "visible" : "hidden" }}
                    onClick={(e) => handleSearchClick(e)}
                  >
                    <Search fontSize='small' />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    title='Clear'
                    aria-label='Clear'
                    size='small'
                    style={{
                      visibility: inputValueRef.current ? "visible" : "hidden",
                    }}
                    onClick={(e) => handleClear(e)}
                  >
                    <Clear fontSize='small' />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "10px", sm: "20px", md: "30px" },
            }}
          >
            <ThemeProvider theme={theme}>
              <StyledFormControl
                fullWidth
                variant='standard'
                size='small'
                sx={{ minWidth: 120 }}
              >
                <FormControlLabel
                  control={
                    <StyledSwitch
                      name='case'
                      onChange={(e) => handleUngroupedChange(e)}
                    />
                  }
                  label={t("electorate.manage_voters.ungrouped")}
                  checked={showUngroupedVoters}
                  sx={{ color: theme.palette.primary.main, fontSize: "20px" }}
                />
              </StyledFormControl>
            </ThemeProvider>
            <Tooltip
              title={t("electorate.table.button.add_new_voter")}
              placement='bottom'
            >
              <StyledIconButton
                aria-label='add'
                onClick={handleOpen}
                edge='end'
              >
                <AddOne />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  // console.log("Data: ", data);
  // console.log("Columns: ", columns);

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth={"xl"}>
      <CustomTable
        toolbar={VotersTableToolbar}
        rows={ProcessRows(data)}
        columns={columns}
        pg={page}
        setPg={setPage}
        rpp={rowsPerPage}
        setRpp={setRowsPerPage}
      />
      <CustomModal
        props={{
          open: open,
          handleClose: handleClose,
        }}
      >
        <Form close={handleClose} />
      </CustomModal>
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={
            voterToDelete
              ? [
                  { label: "Cancel", onClick: () => setAlert(null) },
                  { label: "Ok", onClick: () => handleDeleteConfirmation() },
                ]
              : [{ label: "Ok", onClick: () => setAlert(null) }]
          }
        />
      )}
      <Outlet />
    </Container>
  );
};

export default Voters;
