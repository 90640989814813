import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./consts/theme";
import AppRouter from "./AppRouter";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
import Loading from "./components/utils/Loading";
import App from "./App";
import * as Sentry from "@sentry/react";

// import reportWebVitals from './reportWebVitals';

// const Loading = (): JSX.Element => {
//   return <div>Please Wait...</div>;
// };

Sentry.init({
  dsn: "https://e30a46fb8543b568f5a9e6037cd74032@o4506104894259200.ingest.us.sentry.io/4506908557049856",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// remove cookies when the tab/browser is closed
// const handleBeforeUnload = () => {
//   Cookies.remove(COOKIE_NAME);
//   Cookies.remove(COOKIE_NAME_REF);
// };
// window.addEventListener("beforeunload", handleBeforeUnload);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Loading />}>
          <AppRouter>
            <App />
          </AppRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
