import { styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import variables from "../../styles/variables.module.scss";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
interface AppBar extends AppBarProps {
  drawerwidth: number;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBar>(({ theme, open, drawerwidth }) => ({
  backgroundColor: variables.color_primary,
  color: variables.text_color_light,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerwidth,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const TableBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  backgroundColor: variables.background_toolbar,
  zIndex: 2,
  borderTopRightRadius: variables.border_radius,
  borderTopLeftRadius: variables.border_radius,
  // boxShadow: theme.shadows[1],
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
