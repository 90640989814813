import { memo } from "react";
import ChartComp from "./Chart";
import { isEqual } from "lodash";
import { PollStatus } from "../../types/poll";
import { getPollColor, getPollColorLight } from "../../utils/getColours";
import { darken, lighten } from "@mui/material/styles";
import { BACKGROUND_COLORS, BORDER_COLORS, HOVER_COLORS } from "./colours";

export type PieProps = {
  data: any;
  title: string;
  pollType?: PollStatus;
};

const PieChart: React.FC<PieProps> = memo((props) => {
  const data = {
    labels: Object.keys(props.data),
    datasets: [
      {
        label: props.title,
        data: Object.values(props.data),
        backgroundColor: props.pollType
          ? [
              getPollColor(props.pollType),
              lighten(getPollColor(props.pollType), 0.3),
            ]
          : BACKGROUND_COLORS,
        borderColor: props.pollType
          ? [
              darken(getPollColor(props.pollType), 0.1),
              darken(getPollColor(props.pollType), 0.2),
            ]
          : BORDER_COLORS,
        hoverBackgroundColor: props.pollType
          ? [
              getPollColorLight(props.pollType),
              darken(getPollColorLight(props.pollType), 0.1),
            ]
          : HOVER_COLORS,
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  console.log(data);
  console.log(Object.keys(props.data));

  const pieChartConfig = {
    type: "pie",
    title: props.title,
    data: data,
  };

  console.log(data);

  return (
    <ChartComp
      key={pieChartConfig.title}
      type={pieChartConfig.type}
      title={pieChartConfig.title}
      data={data}
    />
  );
}, isEqual);

export default PieChart;
