import { FormControl, styled } from "@mui/material";
import variables from "../../styles/variables.module.scss";

export const StyledFormControl = styled(FormControl)`
  & label.Mui-focused {
    color: ${variables.color_primary};
  }

  & .MuiFilledInput-underline:after {
    border-color: ${variables.color_primary};
  }

  & .MuiOutlinedInput-root {
    border-color: ${variables.color_primary};

    /* & fieldset {
      border-color: "white";
    } */
    &:hover fieldset {
      border-color: ${variables.text_color};
    }
    &.Mui-focused fieldset {
      border-color: ${variables.color_primary};
    }
  }

  /* & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: "green";
  } */
`;

export const menuStyles = {
  sx: {
    "& .MuiMenu-paper": {
      backgroundColor: variables.white,
    },
    "& .MuiMenuItem-root": {
      borderRadius: variables.menu_item_br,
      padding: variables.menu_item_pd,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: variables.color_primary_hover,
    },
    "& .Mui-selected": {
      backgroundColor: variables.background_toolbar,
      color: variables.color_primary,
    },
    "& .Mui-selected:hover": {
      backgroundColor: variables.background_toolbar_secondary,
      color: variables.text_color,
    },
  },
};
