import { Admin, Role, User } from "../types/user";
import { GroupFormData, UserFormData, VoterFormData } from "../types/formik";
import { Voter, VoterApi } from "../types/voter";
import { Group } from "../types/group";

export const getFormUser = (values: UserFormData): User => {
  let user: User;
  // console.log("schools: ", values.schools);

  console.log("User: ", values);

  switch (values.role) {
    default:
      user = new Admin(values);
      break;
  }

  Object.keys(user).forEach((key) => {
    if (
      user[key as keyof User] === "" ||
      user[key as keyof User] === null ||
      user[key as keyof User] === undefined
    ) {
      delete user[key as keyof User];
    }
  });

  return user;
};

export const getFullDataUser = (
  user: User | null,
  role: Role
): UserFormData => {
  // console.log(user);
  if (user !== null) {
    const fullname = user.fullname !== null ? user.fullname : "";
    const email = user.email ? user.email : "";
    const username = user.username ? user.username : ""; //user._id ? user._id : "";
    const password = user.password ? user.password : ""; //user._id ? user._id : "";

    return {
      fullname: fullname,
      username: username,
      password: password,
      email: email,
      role: user.role,
    };
  } else
    return {
      fullname: "",
      username: "",
      password: "",
      email: "",
      role: role,
    };
};

export const getFullDataVoter = (user: Voter | null): VoterFormData => {
  // console.log(user);
  if (user !== null) {
    return {
      // fullname: user.fullname,
      email: user._id,
      groups: user.groups,
      blockchain_address: user.blockchain_address
        ? user.blockchain_address
        : "",
    };
  } else
    return {
      // fullname: "",
      email: "",
      groups: [],
      blockchain_address: "",
    };
};

export const getFormVoter = (values: VoterFormData): VoterApi => {
  // const voter: Voter = {...values, verified: true, status: "", polls: [], _id:"", DID:""};
  const voter: VoterApi = { ...values, email: "", groups: [] };

  console.log("Voter: ", values);

  Object.keys(voter).forEach((key) => {
    if (
      voter[key as keyof VoterApi] === "" ||
      voter[key as keyof VoterApi] === null ||
      voter[key as keyof VoterApi] === undefined
    ) {
      delete voter[key as keyof VoterApi];
    }
  });

  return voter;
};

export const getFullDataGroup = (group: Group | null): GroupFormData => {
  // console.log(user);
  if (group !== null) {
    return {
      title: group.title,
      description: group.description,
      members: group.members,
    };
  } else
    return {
      title: "",
      description: "",
      members: [],
    };
};
