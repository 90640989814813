import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Poll } from "../../types/poll";

export const selectPolls = (state: RootState) => [
  ...state.polls.polls,
  ...state.polls.drafts,
];
export const selectElections = (state: RootState) => state.polls.polls;
export const selectDrafts = (state: RootState) => state.polls.drafts;
export const selectPollsLoading = (state: RootState) => state.polls.loading;
export const selectActivePoll = (state: RootState) => state.polls.activePoll;

export const selectPollsbyStatus = createSelector(
  [
    (state) => [state.polls.polls, state.polls.drafts],
    (state, status) => status,
  ],
  (polls, status) =>
    polls.filter((poll: Poll) => poll.status === status || status === "ALL")
);

// export const selectPollNumbers = (state: RootState) => {
//   const polls = [...state.polls.polls, ...state.polls.drafts];
//   const statusCounts = {
//     UPCOMING: 0,
//     ACTIVE: 0,
//     PAST: 0,
//     DRAFT: 0,
//     CANCELLED: 0,
//     ALL: 0,
//   };

//   for (const poll of polls) {
//     const status = poll.status;
//     // eslint-disable-next-line no-prototype-builtins
//     if (statusCounts.hasOwnProperty(status)) {
//       statusCounts[status]++;
//     }
//   }

//   return statusCounts;
// };
