import { CreatePollApi, Poll, PollApi } from "./../../types/poll";
import { ApiResponse, CreatePayload } from "../../types/api";
import { Axios } from "./axios";
import { getApiUrl } from "../../consts/api";

export const pollClient = () => Axios(`${getApiUrl()}/poll/template`);
export const pollClient2 = () => Axios(`${getApiUrl()}/poll/templates`);

export async function createPoll(values: CreatePayload<CreatePollApi>) {
  return await pollClient().post("", values);
}

export async function updatePoll(values: PollApi) {
  return await pollClient().put("", values);
}

export async function validatePoll(values: PollApi) {
  return await pollClient().post("/validate", values);
}

export async function deletePoll(id: string) {
  return await pollClient().delete("", {
    params: { template_id: id },
  });
}

export async function fetchDrafts() {
  return pollClient().get<ApiResponse<Poll[]>>("/draft");
}

export async function fetchPolls() {
  return pollClient().get<ApiResponse<Poll[]>>("/all");
}

export async function fetchPollsEL() {
  return pollClient2().get<ApiResponse<Poll[]>>("/el");
}

export async function fetchPollById(id: string) {
  return await pollClient().get("", {
    params: { template_id: id },
  });
}
