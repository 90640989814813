import axios, { AxiosError } from "axios";
import { getToken } from "./utils.api";
import { ApiResponse } from "../../types/api";
import { useLogout } from "../../hooks/auth/useLogout";

export const Axios = (
  url: string,
  withoutHeaders = false,
  photoGet = false,
  photoUpload = false
) => {
  // console.log("axios", getToken());

  const instance = axios.create({
    baseURL: url,
    timeout: 30000,
    timeoutErrorMessage: "Time out!",
    headers: {
      Authorization: withoutHeaders || `Bearer ${getToken()}`,
      "Content-Type":
        photoUpload || photoGet ? "multipart/form-data'" : "application/json",
    },
    responseType: photoGet ? "blob" : "json",
  });

  // Request interceptor
  instance.interceptors.request.use(
    (req) => {
      return req;
    },
    (e) => {
      // console.log("Error from request interceptor: ", e.response);
      return handleError(e);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (e) => {
      // console.log("Error intercepted from response: ", e.response);
      return handleError(e);
    }
  );

  return instance;
};

const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<ApiResponse<any>>
): Promise<AxiosError> => {
  const { logout } = useLogout();
  let errorMsg = "System Error";

  if (error.response) {
    if (error.response.status === 403 || error.response.status === 401) {
      logout();
      window.location.replace("/login");
      return Promise.reject(error);
    }
    // console.log("Error Axios: ", error.message);
    if (error.response.data.errors[1]) {
      errorMsg = error.response.data.errors[1];
    } else if (error.response.data.errors[0]) {
      errorMsg = error.response.data.errors[0];
    }
    console.log("Error: ", errorMsg);
    // alert(errorMsg);
  }

  return Promise.reject(errorMsg); //Promise.resolve(error);
};
