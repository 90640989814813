import { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell } from "./TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import { Column, ITableRow, TableProps } from "../../types/table";
import { Box, Collapse, Container, Fade, IconButton } from "@mui/material";
import { CustomTablePagination } from "./TablePagination";
import styles from "../../styles/Table.module.scss";
import SelectPagination from "./SelectPagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { CustomIconMenu } from "./CustomMenu";

function Row<T extends ITableRow, K extends keyof T>(props: {
  row: T;
  columns: Column<T, K>[];
}) {
  const { row } = props;
  const { columns } = props;
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset", height: "52px" } }}>
        {columns.map((column, index) => (
          // <Fade
          //   key={index}
          //   in={true}
          //   style={{ transformOrigin: "0 0 0" }}
          //   timeout={1000 + index * 200}
          // >
          <StyledTableCell
            key={index}
            align='center'
            component='th'
            scope='row'
            greyed={index !== 0}
          >
            {row[column.key]}
          </StyledTableCell>
          // </Fade>
        ))}
        {row.actions ? (
          // <Fade
          //   in={true}
          //   style={{ transformOrigin: "0 0 0" }}
          //   timeout={1000 + (columns.length + 1) * 200}
          // >
          <StyledTableCell align='center'>
            {/* <CustomMenu actions = {row.actions}/> */}
            <CustomIconMenu actions={row.actions} />
          </StyledTableCell>
        ) : // </Fade>
        row.collapsible ? (
          <></>
        ) : (
          <StyledTableCell />
        )}
        {row.collapsible && (
          <StyledTableCell align='center'>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpenCollapse(!openCollapse)}
              className={styles.icon}
            >
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </StyledTableCell>
        )}
      </TableRow>
      {row.collapsible && (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={openCollapse} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size='small' aria-label='collapsible'>
                  <TableHead>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                      {row.collapsible.columns.map((column, index) => (
                        <StyledTableCell align='left' key={index}>
                          {column["name"]}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.collapsible.rows.map((r, index) => (
                      <TableRow key={index}>
                        {row.collapsible?.columns.map((column, index) => (
                          <StyledTableCell
                            key={index}
                            align={index === 0 ? "left" : "right"}
                            component='th'
                            scope='row'
                          >
                            {r[column.key]}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </TableRow>
      )}
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "none" }}
          colSpan={columns.length + 1}
        ></StyledTableCell>
      </TableRow>
    </Fragment>
  );
}

const CustomTable = <T extends ITableRow, K extends keyof T>({
  rows,
  columns,
  toolbar,
  disablePaginationSelect = false,
  pg = 0,
  setPg,
  rpp = 10,
  setRpp,
  rppOptions = [5, 10, 25, 100],
}: // locale = 'elGR'
TableProps<T, K>): JSX.Element => {
  const { t } = useTranslation();
  const rowsPerPageOptions = disablePaginationSelect ? [5] : rppOptions;
  const [page, setPage] = useState(pg);
  const [rowsPerPage, setRowsPerPage] = useState(rpp);
  const Toolbar = toolbar;

  // const [locale, setLocale] = useState<SupportedLocales>("elGR");

  // const themeWithLocale = useMemo(
  //   () => createTheme(theme, locales[locale]),
  //   [locale, theme]
  // );

  // console.log(themeWithLocale);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPg && setPg(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log("EV: ", event.target.value);
    if (event !== null) {
      const newValue = parseInt(event.target.innerText);
      setRowsPerPage(newValue);
      setRpp && setRpp(newValue);
    } else {
      setRowsPerPage(rpp);
      setRpp && setRpp(rpp);
    }
    setPage(0);
    setPg && setPg(0);
  };

  return (
    <Fade in={true} style={{ transformOrigin: "0 0 0" }} timeout={500}>
      <Container className={styles.tableContainer} maxWidth={false}>
        <Paper elevation={2} sx={{ overflowX: "auto", overflowY: "hidden" }}>
          <Toolbar />
          <TableContainer
            sx={{ overflowX: "auto", overflowY: "hidden", borderRadius: "0px" }}
          >
            <Table stickyHeader aria-label='simple table'>
              <TableHead>
                <TableRow sx={{ height: "56px" }}>
                  {columns.map((column, index) => (
                    <StyledTableCell align='center' key={index}>
                      {column["name"]}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row key={index} row={row} columns={columns} />
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ height: "56px" }}>
                  <CustomTablePagination
                    sx={{ position: "relative", zIndex: "2" }}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={t(
                      "electorate.table.pagination.rows_per_page"
                    )}
                    colSpan={
                      rows.length > 0 && rows[0].actions
                        ? columns.length + 1
                        : columns.length
                    }
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                        //   nextButton: (action: any) => (
                        //     <Tooltip
                        //       title={t("")}
                        //       placement="top"
                        //     >
                        //       <IconButton onClick={action}>
                        //         <ArrowForward />
                        //       </IconButton>
                        //     </Tooltip>
                        //   ),
                        //   backButton: (action: any) => (
                        //     <Tooltip
                        //       title={t("")}
                        //       placement="top"
                        //     >
                        //       <IconButton onClick={action}>
                        //         <ArrowBack />
                        //       </IconButton>
                        //     </Tooltip>
                        //   ),
                      },
                    }}
                    slots={{ select: SelectPagination }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </Fade>
  );
};
export default CustomTable;
