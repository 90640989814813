import React from "react";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";
import styles from "../../styles/PollCards.module.scss";
import { PollStatus } from "../../types/poll";

interface CustomAvatarGroupProps {
  avatars: string[];
  status: PollStatus;
}

// interface AvatarData {
//   alt: string;
// }

const CustomAvatarGroup: React.FC<CustomAvatarGroupProps> = ({
  avatars,
  status,
}) => {
  return (
    <AvatarGroup max={4} classes={{ avatar: styles.cardFooterIcon }}>
      {avatars.map((avatar, index) => (
        <Tooltip key={index} title={avatar} placement='top'>
          <Avatar className={styles[status]} sx={{ width: 32, height: 32 }}>
            {avatar[0]}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default CustomAvatarGroup;
