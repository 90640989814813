import { ethers } from "ethers-test";
import { getProviderHost } from "../../consts/api";
import keys from "../../assets/privateKeys.json";
import zklogin from "../../assets/zkLoginAbi.json";
import addresses from "../../assets/addresses.json";

export const getConnection = async () => {
  const provider = await ethers.getDefaultProvider(getProviderHost());
  // console.log(
  //   "Provider: ",
  //   await provider.getTransactionCount(
  //     "0x85881f42f71CC5c23fC10644fD1E2eF5d9E1FF9c"
  //   )
  // );
  return provider;
};

const loadWallet = (provider: any) => {
  return new ethers.Wallet(`0x` + keys[0].privateKeyHex, provider);
};

export const getContract = (provider: any) => {
  const wallet = loadWallet(provider);
  // Connect to Contract
  const zkLoginAddr = addresses.zkLogin;
  // Load the contract ABI and bytecode
  const zkloginAbi = zklogin.abi; // Contract ABI

  return new ethers.Contract(zkLoginAddr, zkloginAbi, wallet);
};
