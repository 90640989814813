import variables from "../styles/variables.module.scss";
import { Theme, createTheme, lighten } from "@mui/material";
import { elGR } from "@mui/material/locale";

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: variables.color_primary,
      light: lighten(variables.color_primary, 0.8),
      // dark: darken(variables.color_primary, 0.1)
    },
    secondary: {
      main: variables.color_secondary,
    },
    error: {
      main: variables.color_accent,
    },
    warning: {
      main: variables.color_warn,
    },
    success: {
      main: variables.color_success,
    },
  },
  typography: {
    fontFamily: ["Lato", "Ubuntu", "sans-serif"].join(","), //variables.font_family,
  },
  // components: {
  //   MuiTextField: {
  //     styleOverrides: {
  //       root: {
  //         fieldset: {
  //           borderColor: variables.color_primary,
  //         },
  //       },
  //     },
  //   },
  // },
});

export const themeWithLocale = createTheme(theme, elGR);
