import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import { StyledButton } from "../../styled/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConfirmPublish = () => {
  const router = useNavigate();
  const {t} = useTranslation();

  return (
    <Container sx={styles.container} maxWidth="xl">
      <Paper sx={styles.paper} elevation={3}>
        <Box sx={styles.box}>
          <Typography sx={styles.text}>{t("wizard.steps.publish.message")}</Typography>
          <StyledButton
            sx={{ fontSize: "14px" }}
            variant="contained"
            type="button"
            onClick={() => {
              router('/dashboard/polls');
            }}
          >
           {t("wizard.nav.go_back")} 
          </StyledButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ConfirmPublish;

const styles = {
  container: {
    display: "flex",
    alignItems: "center", 
    justifyContent: "center",
    marginTop: "100px",
    marginBottom: "100px",
  },
  paper: {
    display: "flex",
    
  },
  box:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center", 
    justifyContent: "center",
    padding: "50px",
    gap: "40px"
  },
  text:{
    fontSize:"20px",
    fontWeight: "600"
  }
};
