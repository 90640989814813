import { Choice } from "../types/poll";
import { Voter } from "../types/voter";

function checkDuplicates(choices: Choice[]) {
  const encounteredValues = new Set();

  for (const choice of choices) {
    if (encounteredValues.has(choice.text)) {
      return true;
    } else {
      encounteredValues.add(choice.text);
    }
  }

  return false;
}

export const getError = (
  dateTimeError: string | null,
  title: string,
  description: string,
  isDirty: boolean | undefined,
  groups: string[],
  excludedVoters: Voter[],
  voterList: string[],
  choices: Choice[],
  axiosError: boolean
) => {
  console.log("form", choices);

  if (axiosError) {
    return "systemError";
  }

  //  || title.trim() === 'title'
  if (
    title.trim() === "" ||
    title.trim() === "title" ||
    !/^([^|]*)$/.test(title)
  ) {
    return "invalidTitle";
  }
  // || description.trim() === 'description'
  if (description.trim() === "") {
    return "emptyDescription";
  }

  if (dateTimeError !== null) {
    return "invalidDate";
  }

  if (
    !isDirty ||
    choices[0].text === undefined ||
    (choices && choices.length === 0)
  ) {
    console.log("error");
    return "defaultPoll";
  }

  if (checkDuplicates(choices)) {
    return "sameOptions";
  }

  if (excludedVoters.length > 0) {
    return "excludedVoters";
  }

  if (groups.length === 0) {
    return "emptyGroups";
  }

  if (voterList.length === 0) {
    return "noVoters";
  }

  return "";
};
