import { Container } from "@mui/material";
import PollComponent from "../../../components/pollComponents/PollComponent";
import { useParams } from "react-router-dom";
import Loading from "../../../components/utils/Loading";
import styles from "../../../styles/PollCards.module.scss";
import { useEffect } from "react";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { selectActivePoll } from "../../../store/selectors/pollSelector";
import { getElectionById } from "../../../store/effects/pollEffects";
import { selectProofZkMerkleTree } from "../../../store/selectors/blockchainSelector";
import { useContract } from "../../../hooks/useContract";
import { getActivePoll } from "../../../store/actions/pollActions";

const PollPreviewPage = () => {
  const { id } = useParams();
  const bc_id = id && id.split("draft")[0];
  const draft_id = id && id.split("draft")[1];
  const activePoll = useAppSelector(selectActivePoll);
  const dispatch = useAppThunkDispatch();

  // console.log(bc_id, draft_id);

  const { contract } = useContract();
  const proof = useAppSelector(selectProofZkMerkleTree).proof;
  const signals = useAppSelector(selectProofZkMerkleTree).publicSignals;

  useEffect(() => {
    contract &&
      bc_id &&
      draft_id &&
      dispatch(getElectionById({ contract, proof, signals, bc_id, draft_id }));
  }, [contract]);

  useEffect(() => {
    return () => {
      dispatch(getActivePoll(null));
    };
  }, []);

  return (
    <Container maxWidth={false} className={styles.container}>
      {activePoll ? <PollComponent poll={activePoll} /> : <Loading />}
    </Container>
  );
};

export default PollPreviewPage;
