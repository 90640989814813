import { RootState } from "../store";

export const selectGroups = (state: RootState) => state.groups.groups;
export const selectGroupsLoading = (state: RootState) => state.groups.loading;
export const selectActiveGroup = (state: RootState) => state.groups.activeGroup;

// const selectUserById = createSelector(
//   [(state) => state.users, (state, id) => id],
//   (users, id) => users.filter((user: User) => user._id === id)
// );
