export const BACKGROUND_COLORS = [
  "rgba(255, 103, 110, 0.5)",
  "rgba(255, 153, 94, 1)",
  "rgba(255, 204, 110, 0.8)",
  "rgba(44, 212, 100, 0.5)",
  "rgba(68, 218, 205, 0.7)",
  "rgba(93, 181, 216, 1)",
  "rgba(115, 136, 255, 0.8)",
  "rgba(225, 95, 251, 0.5)",
  "rgba(255, 89, 166, 0.6)",
  "rgba(252, 106, 130, 0.8)",
];

export const BORDER_COLORS = [
  "rgba(255, 99, 132, 1)",
  "rgba(243, 130, 17, 1)",
  "rgba(250, 195, 58, 1)",
  "rgba(138, 172, 83, 1)",
  "rgba(99, 151, 112, 1)",
  "rgba(55, 156, 156, 1)",
  "rgba(99, 102, 241, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 89, 166, 1)",
  "rgba(252, 106, 130, 1)",
];

export const HOVER_COLORS = [
  "rgba(255, 103, 111, 0.251)",
  "rgba(255, 103, 111, 0.161)",
];
