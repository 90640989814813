import { Box, Typography } from "@mui/material";

const Loading = ({
  message,
  h = "100vh",
}: {
  message?: string;
  h?: string;
}) => {
  // console.log("loading");
  return (
    <div className="loader" style={{ height: h }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={"20px"}
        gap={"10px"}
      >
        <div className="spinner"></div>
        {message && <Typography fontStyle={"italic"}>{message}</Typography>}
      </Box>
    </div>
  );
};

export default Loading;
