import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { selectLanguage } from "../../store/selectors/uiSelector";
import "dayjs/locale/en";
import "dayjs/locale/el";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../consts/theme";

interface Props {
  props: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    error: string | null;
    size: "small" | "medium";
    setStartDate: (date: Dayjs | null) => void;
    setEndDate: (date: Dayjs | null) => void;
    setError: (error: string | null) => void;
  };
}

const RangePicker: React.FC<Props> = ({ props }) => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectLanguage);

  return (
    <>
      <LocalizationProvider adapterLocale={"en-GB"} dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={t("wizard.steps.election_info.start_date")}
          format='DD/MM/YYYY hh:mm'
          value={props.startDate}
          onChange={(newValue) => props.setStartDate(newValue)}
          disablePast
          maxDate={props.endDate}
          onError={(newError) => props.setError(newError)}
          slotProps={{
            textField: {
              required: true,
              size: "medium",
              sx: { width: "100%" },
            },
          }}
        />
      </LocalizationProvider>
      <LocalizationProvider
        adapterLocale={locale.substring(0, 2)}
        dateAdapter={AdapterDayjs}
      >
        <DateTimePicker
          label={t("wizard.steps.election_info.end_date")}
          format='DD/MM/YYYY hh:mm'
          value={props.endDate}
          onChange={(newValue) => props.setEndDate(newValue)}
          disablePast
          minDate={props.startDate}
          onError={(newError) => props.setError(newError)}
          slotProps={{
            textField: {
              required: true,
              size: "medium",
              sx: { width: "100%" },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default RangePicker;

export const TwoPartRangePicker: React.FC<Props> = ({ props }) => {
  const { t } = useTranslation();
  // const currentDate = dayjs(new Date(), "DD/MM/YYYY HH:mm");

  return (
    <>
      <LocalizationProvider adapterLocale='en-gb' dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{ overflow: "hidden" }}
          components={["DateTimePicker", "DateTimePicker"]}
        >
          <DateTimePicker
            label={t("wizard.steps.election_info.start_date")}
            format='DD/MM/YYYY HH:mm'
            ampm={false}
            value={props.startDate}
            onChange={(newValue) => props.setStartDate(newValue)}
            disablePast
            // minDateTime={currentDate}
            maxDateTime={props.endDate}
            onError={(newError) => props.setError(newError)}
            slotProps={{
              textField: {
                required: true,
                size: props.size,
                sx: { width: "100%" },
              },
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider adapterLocale='en-gb' dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{ overflow: "hidden" }}
          components={["DateTimePicker", "DateTimePicker"]}
        >
          <DateTimePicker
            label={t("wizard.steps.election_info.end_date")}
            format='DD/MM/YYYY HH:mm'
            ampm={false}
            value={props.endDate}
            onChange={(newValue) => props.setEndDate(newValue)}
            disablePast
            minDateTime={props.startDate}
            onError={(newError) => props.setError(newError)}
            slotProps={{
              textField: {
                required: true,
                size: props.size,
                sx: { width: "100" },
              },
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};
