import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../../types/redux";
import { VOTER_ACTIONS } from "../actions/voterAction";
import { Voter } from "../../types/voter";
import { getVoters } from "../effects/voterEffects";

export interface VoterState {
  voters: Voter[];
  activeVoter: Voter | null;
  loading: boolean;
}

export const initialVoterState: VoterState = {
  voters: [],
  activeVoter: null,
  loading: false,
};

const voterReducer = createReducer(initialVoterState, (builder) => {
  builder.addCase(getVoters.fulfilled, (state, { payload }) => {
    return { ...state, voters: payload, loading: false };
  });
  builder.addCase(getVoters.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(
    VOTER_ACTIONS.GET_ACTIVE_VOTER,
    (state, action: ActionWithPayload<Voter>) => {
      // console.log(action.payload);
      console.log(action.type);

      return { ...state, activeVoter: action.payload };
    }
  );
  builder.addCase(
    VOTER_ACTIONS.REMOVE_VOTER,
    (state, action: ActionWithPayload<string>) => {
      return {
        ...state,
        voters: state.voters.filter((voter) => voter._id !== action.payload),
      };
    }
  );
  // builder.addCase(
  //   VOTER_ACTIONS.ADD_VOTER,
  //   (state, action: ActionWithPayload<Voter>) => {
  //     return {
  //       ...state,
  //       voters: [...state.voters, action.payload],
  //     };
  //   }
  // );
  // builder.addCase(
  //   VOTER_ACTIONS.UPDATE_VOTER,
  //   (state, action: ActionWithPayload<Voter>) => {
  //     console.log("RED: ", action.payload);
  //     return {
  //       ...state,
  //       voters: state.voters.map((voter) => {
  //         if (voter._id === action.payload._id) {
  //           return action.payload;
  //         }
  //         return voter;
  //       }),
  //     };
  //   }
  // );
});

export default voterReducer;
