import * as React from "react";
import { Select, SelectProps, SelectRootSlotProps } from "@mui/base/Select";
import { Option, optionClasses } from "@mui/base/Option";
import { Popper } from "@mui/base/Popper";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import variables from "../../styles/variables.module.scss";

export default function SelectPagination(params: any) {
  return (
    <CustomSelect
      // slotProps={}
      value={params.value.toString()} //{params.children[1].key}
      onChange={(event) => {
        // console.log(event);
        params.onChange(event);
      }}
    >
      {params.children.map((child: any) => (
        <StyledOption key={child.key} value={child.key}>
          {child.key}
        </StyledOption>
      ))}
    </CustomSelect>
  );
}

const CustomSelect = React.forwardRef(function CustomSelect<
  TValue extends object,
  Multiple extends boolean
>(
  props: SelectProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <Select {...props} ref={ref} slots={slots} />;
});

const Button = React.forwardRef(function Button<
  TValue extends object,
  Multiple extends boolean
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props;
  return (
    <button type='button' {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

// transition-property: all;
// transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
// transition-duration: 120ms;

const StyledButton = styled(Button, { shouldForwardProp: () => true })(
  () => `
  font-size: ${variables.font_size};
  box-sizing: border-box;
  min-width: 75px;;
  min-height: 25px;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
  background: transparent; //${variables.white};
  border: 1px solid ${variables.text_secondary};
  color: ${variables.text_color};
  position: relative;
  z-index: 0;

  transition: ${variables.trans_ease_in_out};

  &:hover {
    background: ${variables.white};
    border: 1.5px solid ${variables.text_color};
  }

  &:focus {
    background: transparent; //${variables.white};
    border: 2px solid ${variables.color_primary};
    // color: ${variables.color_primary};
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const StyledListbox = styled("ul")(
  () => `
  font-family: ${variables.font_family};
  font-size: ${variables.font_size_table};
  font-weight: ${variables.font_weight_medium};
  box-sizing: border-box;
  margin: 2px 0px;
  min-width: 75px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${variables.white};
  // border: 1px solid  ${variables.text_color};
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.12), 0px 1px 5px 0px rgba(0,0,0,0.18);
  color: ${variables.text_color};
  `
);

const StyledOption = styled(Option)(
  () => `
  list-style: none;
  padding: ${variables.menu_item_pd};
  border-radius: ${variables.menu_item_br};
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    // background-color:  ${variables.background_toolbar};
    color:  ${variables.color_primary};
  }

  &.${optionClasses.highlighted} {
    background-color:  ${variables.background_toolbar};
    color:  ${variables.color_primary};

    &:hover:not(.${optionClasses.disabled}) {
      background-color: ${variables.background_toolbar};
      color: ${variables.text_color};
    }

    &:active:not(.${optionClasses.disabled}) {
      background-color: ${variables.background_secondary_toolbar};
      color: ${variables.text_color};
      animation: activeEffect 1s linear;
    }
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${variables.background_toolbar};
    color:  ${variables.color_primary};
  }

  &.${optionClasses.disabled} {
    color:  ${variables.text_color};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${variables.color_primary_hover};
    color: ${variables.text_color};
  }

  &:active:not(.${optionClasses.disabled}) {
    animation: activeEffect 1s linear;
  }

  @keyframes activeEffect {
    0%{
      transform: scale(1,1);
      opacity: 1;
    }
    50%{
      transform: scale(0.2,0.2);
      opacity: 0.5;
    }
    100%{
      transform: scale(1,1);
      opacity: 1;
    }
  }
  
  `
);

const StyledPopper = styled(Popper)`
  z-index: 1;
`;
