import LoginLayout from "./LoginLayout";
import { Grid, Typography } from "@mui/material";
import styles from "../../styles/Login.module.scss";
import Login from "../../components/Login";

const LoginPage = () => {
  return (
    <LoginLayout>
      <Grid
        container
        flexDirection={"row"}
        height='100vh'
        justifyContent={"space-between"}
        display={"flex"}
        style={{
          background:
            "url(https://www.lmc.org.uk/wp-content/uploads/2023/01/Election-ballot-box.png)",
          backgroundSize: "cover",
        }}
      >
        <Grid
          item
          container
          direction={"column"}
          height='100vh'
          flex={1}
          className={styles.grid}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={10}
          >
            <Typography
              variant='h4'
              bgcolor={"white"}
              borderRadius={1}
              p={4}
              letterSpacing={1.25}
            >
              Trevo Dashboard
            </Typography>
          </Grid>
        </Grid>
        <Grid className={styles.expand}>
          <Login />
        </Grid>
      </Grid>
    </LoginLayout>
  );
};
export default LoginPage;
