import { useState } from "react";
import styles from "../../styles/PollCards.module.scss";
import IconButton from "@mui/material/IconButton";
import { Grid, Box, Paper, Typography, Divider, Tooltip } from "@mui/material";
import { TableSchema } from "../../types/table";
import useSubString from "../../hooks/useSubstring";
import { Link, useNavigate } from "react-router-dom";
import { usePathname } from "../../hooks/usePathname";
import CustomAvatarGroup from "../utils/CustomAvatarGroup";
import ProgressBar from "../utils/ProgressBar";
import { useCountdownHook } from "../../hooks/useCustomDate";
import { useTranslation } from "react-i18next";
import { getTitles } from "../../utils/getGroupTitles";
import Countdown from "../utils/Countdown";
import { StyledButton } from "../styled/Button";
import { useContract } from "../../hooks/useContract";
import { selectProofZkMerkleTree } from "../../store/selectors/blockchainSelector";
import { useAppSelector } from "../../store/hooks";
import { selectGroups } from "../../store/selectors/groupSelector";
import { Poll } from "../../types/poll";
import variables from "../../styles/variables.module.scss";
import { getPollColorLight } from "../../utils/getColours";
import CustomModal from "../utils/CustomModal";
import AlertDialog from "../utils/CustomAlertDialog";
import CustomLoader from "../utils/CustomLoader";
import * as Sentry from "@sentry/react";
import { convertToLocal } from "./Wizard";

// const variants = {
//   active: { height: "auto" },
//   inactive: { height: "250px" },
// };

const calculatePopularVote = (votes: any) => {
  let totalVotes = 0;
  let mostPopular = null;
  let maxVotes = 0;

  for (const option in votes) {
    totalVotes += votes[option];
  }

  if (totalVotes === 0) {
    return "0 (100%)";
  }

  for (const option in votes) {
    if (votes[option] > maxVotes) {
      maxVotes = votes[option];
      mostPopular = option;
    }
  }

  const percentage = (maxVotes / totalVotes) * 100;

  return `${mostPopular} (${percentage.toFixed(2)}%)`;
};

const PollSingle = ({
  poll,
  absent = false,
}: {
  poll: TableSchema<Poll>;
  absent?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const groups = useAppSelector(selectGroups);
  const ids = poll.groups || [];
  const groupNames = getTitles(groups, ids).split(", ");
  const { bcTrigger, timer } = useCountdownHook(poll);
  const pathname = usePathname();
  const title = useSubString(poll.title, 50);
  const description = useSubString(poll.description, 130);
  const { contract, tCount } = useContract();
  const proof = useAppSelector(selectProofZkMerkleTree).proof;
  const signals = useAppSelector(selectProofZkMerkleTree).publicSignals;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const getLocalDate = (date: string) => {
    const local = convertToLocal(date);
    // console.log("LOC: ", local);
    return local.format("DD/MM/YYYY HH:mm");
  };

  const sendHeartBeat = async () => {
    const heartbeatTx = await contract.heartbeat(proof, signals);
    console.log("heartbeat: ", tCount);
    return heartbeatTx.wait();
  };

  const startElection = async () => {
    const startElectionTx = await contract.startElection(
      proof,
      signals,
      poll._id
    );
    console.log("wait");
    return await startElectionTx.wait();
  };

  const endElection = async () => {
    const endElectionTx = await contract.endElection(proof, signals, poll._id);
    console.log("wait");
    return await endElectionTx.wait();
  };

  const cancelElection = async () => {
    const cancelElectionTx = await contract.cancelElection(
      proof,
      signals,
      poll._id
    );
    console.log("wait");
    return await cancelElectionTx.wait();
  };

  const setTriggerError = (trig: string, e: any) => {
    Sentry.captureException(e);

    if (e.revert && e.revert.args[0]) {
      console.log("BC request error:", e.revert.args[0]);
      setError(e.revert.args[0].replace("execution reverted:", ""));
    } else if (e.shortMessage) {
      console.log(`${trig} trigger error message: `, e);
      setError(e.shortMessage);
    } else {
      console.log(`${trig} trigger error: `, e);
      if (typeof e === "object") {
        setError("Error needs investigation");
      } else {
        setError(e);
      }
    }
    setIsLoading(false);
  };

  const handleTrigger = () => {
    console.log("handletrigger");
    setIsLoading(true);
    {
      contract
        ? sendHeartBeat().then(() => {
            switch (bcTrigger) {
              case "start":
                startElection()
                  .then((res) => {
                    if (res) {
                      navigate(0);
                    }
                  })
                  .catch((e) => setTriggerError("Start", e));
                break;
              case "end":
                endElection()
                  .then((res) => {
                    if (res) {
                      navigate(0);
                    }
                  })
                  .catch((e) => setTriggerError("End", e));
                break;
              case "cancel":
                cancelElection()
                  .then((res) => {
                    if (res) {
                      navigate(0);
                    }
                  })
                  .catch((e) => setTriggerError("Cancel", e));
                break;
              default:
                return;
            }
          })
        : setIsLoading(false);
    }
  };

  // console.log("here: ", poll._id, poll.title, bcTrigger);

  return (
    <Paper elevation={3} className={styles.cardSingle}>
      <div className={`poll-single ${absent ? styles.overlay : ""}`}>
        <Box
          className={styles.cardHeader}
          paddingTop={"1.25rem"}
          paddingX={"1.25rem"}
        >
          <Box
            className={styles.cardHeaderTitle}
            sx={{
              backgroundColor: getPollColorLight(poll.status),
              borderRadius: 2,
              paddingTop: 1,
              paddingBottom: 1,
              paddingRight: 3,
              paddingLeft: 3,
              marginBottom: 2,
            }}
          >
            <Typography
              variant={"h6"}
              style={{ minHeight: 64, flex: "0 0 75%" }}
            >
              <Link
                to={
                  poll.status === "DRAFT"
                    ? `${pathname}/edit/${poll._id}/`
                    : `${pathname}/${poll._id}draft${poll.draft_id}/`
                }
              >
                {poll.title.length > 80
                  ? `${title.trimmedString}...`
                  : poll.title}
              </Link>
            </Typography>
            <Grid container display={"flex"} justifyContent={"end"}>
              <Grid container display={"flex"} justifyContent={"end"}>
                {poll.actions &&
                  poll.actions.map((action, index) => (
                    <Box key={index}>
                      {action.show && (
                        <Tooltip title={action.label}>
                          <IconButton
                            onClick={(e) => action.action(e)}
                            className={styles.icon}
                          >
                            {action.icon}
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  ))}
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={1}
          >
            <Typography variant={"body1"} color={"rgba(58, 53, 65, 0.6)"}>
              <span style={{ fontWeight: 600 }}>
                {t("polls.details.leader")}:{" "}
              </span>
              {poll.author}
              {/* EL Municipal Admin */}
            </Typography>
            <Grid
              display={"flex"}
              item
              alignItems={"center"}
              justifyContent={"center"}
              textAlign={"center"}
            >
              <Box
                className={styles[poll.status]}
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: "20px",
                  color: "#fff",
                }}
              >
                {t(`polls.status.${poll.status.toLowerCase()}`)}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container flexDirection={"column"} justifyContent={"start"}>
          <Grid
            container
            item
            flex={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingX={"1.25rem"}
          >
            <Grid item>
              <Typography variant={"body1"} color={"rgba(58, 53, 65, 0.6)"}>
                <span style={{ fontWeight: 600 }}>
                  {t("polls.details.start_date")}
                </span>
                :
                {poll.startDate !== null && poll.startDate !== undefined
                  ? poll.status === "DRAFT"
                    ? getLocalDate(poll.startDate)
                    : poll.startDate
                  : "Invalid date"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"} color={"rgba(58, 53, 65, 0.6)"}>
                <span style={{ fontWeight: 600 }}>
                  {t("polls.details.end_date")}
                </span>
                :
                {poll.endDate !== null && poll.endDate !== undefined
                  ? poll.status === "DRAFT"
                    ? getLocalDate(poll.endDate)
                    : poll.endDate
                  : "Invalid Date"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent={"start"}
            alignItems={"start"}
            paddingX={"1.25rem"}
            minHeight={90}
          >
            {poll.description.length > 120 ? (
              <Typography variant={"body2"} className={styles.cardBodyContent}>
                {description.trimmedString}...
              </Typography>
            ) : (
              <Typography variant={"body2"} className={styles.cardBodyContent}>
                {poll.description}
              </Typography>
            )}
          </Grid>
          <Divider />
        </Grid>
        <Grid paddingX={"1.25rem"} paddingY={"1rem"}>
          {/* changed typogrpaphy -> box, p cannot appear as a descendant... error*/}
          <Box color={"rgba(58, 53, 65, 0.6)"}>
            <Grid flexDirection={"column"}>
              {poll.status === "PAST" ? (
                <Typography>
                  <span>{t("polls.details.result")}: </span>
                  <span style={{ fontWeight: 700 }}>
                    {calculatePopularVote(poll.votes)}
                  </span>
                </Typography>
              ) : poll.status !== "UPCOMING" ? (
                <Typography>
                  <span style={{ fontWeight: 600 }}>
                    {t("polls.details.result")}:{" "}
                  </span>
                  <span
                    style={{
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: "20px",
                      background: variables.color_secondary,
                      color: "#fff",
                    }}
                  >
                    {t("general.pending")}
                  </span>
                </Typography>
              ) : null}
            </Grid>
          </Box>
        </Grid>
        {poll.status !== "UPCOMING" && <Divider />}
        <Grid
          container
          flexDirection={"column"}
          paddingX={"1.25rem"}
          paddingBottom={"1.25rem"}
          paddingTop={"1.25rem"}
          gap={2}
        >
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            {poll.status !== "PAST" &&
              (bcTrigger !== "invalid" ? (
                <>
                  <ProgressBar poll={poll} timer={timer} />
                  <Countdown poll={poll} timer={timer} />
                </>
              ) : (
                <></>
              ))}
          </Grid>
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            flex={1}
          >
            <Grid
              item
              container
              justifyContent={"start"}
              alignItems={"center"}
              flexDirection={"row"}
              gap={0.5}
            >
              <Box>
                <CustomAvatarGroup avatars={groupNames} status={poll.status} />
              </Box>
              <Box display={"flex"} flexDirection={"row"}>
                <Typography variant={"body1"} color={"rgba(58, 53, 65, 0.6)"}>
                  {groupNames.length > 1
                    ? `${groupNames[0]}...`
                    : groupNames[0]}
                </Typography>
              </Box>
              <Grid
                item
                container
                flexDirection={"row"}
                justifyContent={"end"}
                alignItems={"center"}
                flex={1}
              >
                <Typography
                  textAlign={"right"}
                  variant={"body1"}
                  color={"rgba(58, 53, 65, 0.6)"}
                >
                  {`${poll.committed?.length} ${t(
                    "polls.details.committed"
                  )} ${t("polls.details.voters")}`}
                </Typography>
              </Grid>
            </Grid>

            {bcTrigger.length > 0 && bcTrigger !== "invalid" && (
              <Grid
                item
                container
                flexDirection={"row"}
                justifyContent={"center"}
                flex={1}
                marginTop={4}
              >
                <StyledButton onClick={handleTrigger}>{bcTrigger}</StyledButton>
              </Grid>
            )}
            {bcTrigger === "invalid" && (
              <Grid
                item
                container
                flexDirection={"row"}
                justifyContent={"center"}
                flex={1}
                marginTop={4}
              >
                <Typography variant={"h5"} color={variables.color_warn}>
                  {t("general.invalid_poll")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      {isLoading && (
        <CustomModal
          props={{
            open: true,
            handleClose: () => {
              console.log("closed");
            },
          }}
        >
          <CustomLoader />
        </CustomModal>
      )}
      {error && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={error}
          buttons={[{ label: "Ok", onClick: () => setError(null) }]}
        />
      )}
    </Paper>
  );
};

export default PollSingle;
