import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Voter } from "../../types/voter";
import { getTitles } from "../../utils/getGroupTitles";
import { useAppSelector } from "../../store/hooks";
import { selectGroups } from "../../store/selectors/groupSelector";
import CustomTypographyInfoTitle from "../utils/CustomTypography";
import DetailsCard from "../DetailsCard";

const PreviewDetails = ({ voter }: { voter: Voter }) => {
  const { t } = useTranslation();
  const groups = useAppSelector(selectGroups).filter((group) =>
    voter.groups.includes(group._id)
  );
  const fullName =
    voter.gov_firstname && voter.gov_lastname
      ? `${voter.gov_firstname} ${voter.gov_lastname}`
      : undefined;

  return (
    <DetailsCard
      title={fullName ? fullName : voter._id}
      width={{ lg: "100%", xl: "50%" }}
    >
      <Box sx={containerStyles.header}>
        <Typography variant='h4'>{t("polls.details.details")}</Typography>
      </Box>
      <Divider />
      <Box sx={containerStyles.boxDown}>
        <Box sx={containerStyles.boxIn}>
          <CustomTypographyInfoTitle>DID: </CustomTypographyInfoTitle>
          <Typography variant='body1'>&nbsp; {voter.did}</Typography>
        </Box>
        {fullName && (
          <Box sx={containerStyles.boxIn}>
            <CustomTypographyInfoTitle>
              {t("electorate.manage_voters.voters.email")}:{" "}
            </CustomTypographyInfoTitle>
            <Typography variant='body1'>&nbsp; {voter._id}</Typography>
          </Box>
        )}
        <Box sx={containerStyles.boxIn}>
          <CustomTypographyInfoTitle>
            {t("electorate.manage_voters.voters.verified")}:{" "}
          </CustomTypographyInfoTitle>
          <Typography variant='body1'>
            &nbsp; {voter.verified ? t("general.yes") : t("general.no")}{" "}
          </Typography>
        </Box>
        <Box sx={containerStyles.boxIn}>
          <CustomTypographyInfoTitle>
            {t("electorate.manage_voters.voters.registered")}:{" "}
          </CustomTypographyInfoTitle>
          <Typography variant='body1'>
            &nbsp; {voter.registered ? t("general.yes") : t("general.no")}{" "}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={containerStyles.boxDown}>
        <Box sx={{ ...containerStyles.boxIn, flexWrap: "wrap" }}>
          <CustomTypographyInfoTitle>
            {t("electorate.manage_voters.voters.groups")}:{" "}
          </CustomTypographyInfoTitle>
          <Typography variant='body1'>
            &nbsp;
            {groups.length !== 1
              ? getTitles(groups, voter.groups)
              : groups[0].title}
          </Typography>
        </Box>
      </Box>
    </DetailsCard>
  );
};

const containerStyles = {
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
    marginBottom: "10px",
  },
  description: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "20px",
    // maxWidth: "200px",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  boxDown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
  },
  boxIn: {
    display: "flex",
    flexDirection: "row",
    wrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    overflowWrap: "break-word",
  },
};

export default PreviewDetails;
