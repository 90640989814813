import { Box, Container, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import VoterPage from "./voters/VoterPage";
import GroupsPage from "./groups/GroupPage";
import { Groups, Person } from "@mui/icons-material";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import { getGroups } from "../../../store/effects/groupEffects";
import { getVoters } from "../../../store/effects/voterEffects";
import { selectChoice } from "../../../store/selectors/uiSelector";
import { getActiveChoice } from "../../../store/actions/uiActions";
import variables from "../../../styles/variables.module.scss";

const buttonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: { xs: "8px", sm: "10px", lg: "15px" },
  fontSize: { xs: "15px", sm: "15px", lg: "20px" },
};

const ElectorateManagementPage = () => {
  const { t } = useTranslation();
  const choice = useAppSelector(selectChoice);
  const [type, setType] = useState<string>(choice);
  const dispatch = useAppThunkDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
    dispatch(getActiveChoice(newValue));
  };

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getVoters());
    // return () => {
    //   dispatch(getActiveVoter(null));
    //   dispatch(getActiveGroup(null));
    // };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(getActiveChoice("groups"));
    };
  }, []);

  useEffect(() => {
    if (type !== choice) {
      setType(choice);
    }
  }, [type, choice]);

  return (
    <Container maxWidth={false} sx={{ paddingTop: variables.padTop }}>
      <Box sx={{ marginBottom: "20px" }}>
        <Tabs centered value={type} onChange={handleChange}>
          <Tab
            value='groups'
            label={
              <Box sx={buttonStyle}>
                <Groups fontSize='medium' />
                {t("electorate.manage_groups.title")}
              </Box>
            }
          />
          <Tab
            value='voters'
            label={
              <Box sx={buttonStyle}>
                <Person fontSize='medium' />
                {t("electorate.manage_voters.title")}
              </Box>
            }
          />
        </Tabs>
      </Box>
      {type === "groups" ? <GroupsPage /> : <VoterPage />}
    </Container>
  );
};

export default ElectorateManagementPage;
