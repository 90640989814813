import { Container } from "@mui/material";
import { memo } from "react";
import PreviewDetails from "./pollPreview/PreviewDetails";
import PreviewResults from "./pollPreview/PreviewResults";
import PreviewCandidateGroups from "./pollPreview/PreviewCandidateGroups";
import { Poll } from "../../types/poll";

const PollComponent = memo(
  ({ poll, shrinked }: { poll: Poll; shrinked?: boolean }) => {
    console.log("Poll:", poll);
    return (
      <Container
        sx={{
          ...containerStyles.container,
          width: { lg: "100%", xl: shrinked ? "100%" : "80%" },
        }}
        maxWidth={false}
      >
        <PreviewDetails poll={poll} shrinked={shrinked || false} />
        <PreviewResults poll={poll} shrinked={shrinked || false} />
        <PreviewCandidateGroups
          groups={poll.groups}
          pollType={poll.status}
          committed={poll.committed}
          shrinked={shrinked || false}
        />
      </Container>
    );
  }
);

const containerStyles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" },
    justifyContent: "space-between",
    gap: { md: "30px", lg: "15px", xl: "30px" },
    height: "100%",
    alignItems: "stretch",
  },
};

export default PollComponent;
