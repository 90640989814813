import PreviewDetails from "./GroupPreviewDetails";
import { Container, Grow, Toolbar, Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { selectActiveGroup } from "../../store/selectors/groupSelector";
import CustomTable from "../Table/CustomTable";
import { useTranslation } from "react-i18next";
import { Voter } from "../../types/voter";
import { selectVoters } from "../../store/selectors/voterSelector";
import { Column } from "../../types/table";
import { TableBar } from "../styled/AppBar";

function sumEntries(objectEntries: { [id: string]: string }) {
  const keys = Object.keys(objectEntries);

  return keys.length;
}

type CustomType = Omit<Voter, "verified" | "registered"> & {
  verified: string;
  registered: string;
  invited: string;
  committed: string;
  voted: string;
  // participation: string;
  // absency: string;
};

const GroupComponent = () => {
  const { t } = useTranslation();
  const title = t("electorate.table.title.voters");
  // const ts = useAppSelector(selectTSV);
  // const [rowsPerPage, setRowsPerPage] = useState(ts.rpp);
  // const [page, setPage] = useState(ts.page);
  const activeGroup = useAppSelector(selectActiveGroup);
  const data = useAppSelector(selectVoters).filter((voter) =>
    activeGroup?.members.includes(voter._id)
  );

  const ProcessRows = (data: Voter[]): CustomType[] => {
    return data.map((row) => {
      console.log(row);

      const verified = row.verified ? t("general.yes") : t("general.no");
      const registered = row.registered ? t("general.yes") : t("general.no");

      const invited = sumEntries(row.invited);
      const committed = sumEntries(row.committed);
      const voted = sumEntries(row.voted);

      // const participation = voted + "/" + (invited + committed) || "0";
      // const absency = invited + "/" + (invited + committed) || "0";

      return {
        ...row,
        verified,
        registered,
        invited: (invited + committed).toString(),
        committed: committed.toString(),
        voted: voted.toString(),
      }; //participation: `${votedCount}/${latestPolls.length}` };
    });
  };

  // useEffect(() => {
  //   dispatch(getTableStatePolls({ rpp: rowsPerPage, page }));
  // }, [rowsPerPage]);

  const VotersTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant='h2'>{title}</Typography>
        </Toolbar>
      </TableBar>
    );
  };

  const columns: Column<CustomType, keyof CustomType>[] = [
    // { name: t("electorate.table.columns.fullname"), key: "fullname" },

    { name: t("electorate.table.columns.email"), key: "_id" },
    { name: t("electorate.table.columns.firstname"), key: "gov_firstname" },
    { name: t("electorate.table.columns.lastname"), key: "gov_lastname" },
    { name: t("electorate.table.columns.registered"), key: "registered" },
    { name: t("electorate.table.columns.verified"), key: "verified" },
    { name: t("electorate.table.columns.invited"), key: "invited" },
    { name: t("electorate.table.columns.committed"), key: "committed" },
    { name: t("electorate.table.columns.voted"), key: "voted" },
    // { name: t("electorate.table.columns.participation"), key: "participation" },
    // { name: t("electorate.table.columns.absency"), key: "absency" },
  ];

  return (
    <Container sx={containerStyles.container} maxWidth={false}>
      {activeGroup && <PreviewDetails group={activeGroup} />}
      {activeGroup && (
        <Grow
          in={true}
          style={{ transformOrigin: "0 0 0" }}
          timeout={2000}
          unmountOnExit
        >
          <Container maxWidth={false}>
            <CustomTable
              rows={ProcessRows(data)}
              columns={columns}
              toolbar={VotersTableToolbar}
              // pg={page}
              // setPg={setPage}
              // rpp={rowsPerPage}
              // setRpp={setRowsPerPage}
              rppOptions={[5, 10]}
            />
          </Container>
        </Grow>
      )}
    </Container>
  );
};

const containerStyles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "center",
    gap: { xs: "30px", sm: "30px", md: "30px", lg: "10px", xl: "30px" },
    height: "100%",
    alignItems: "stretch",
    width: { lg: "95%", xl: "80%" },
  },
};

export default GroupComponent;
