import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import variables from "../../styles/variables.module.scss";
import { useTranslation } from "react-i18next";
import { ALLOWED_TYPES, MAX_FILE_SIZE } from "../../consts/upload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type UploadProps = {
  setFile: (file: string) => void;
  setFileToUpload: (file: File) => void;
  setError: (error: string) => void;
};

const InputFileUpload: React.FC<UploadProps> = ({
  setFile,
  setFileToUpload,
  setError,
}) => {
  const { t } = useTranslation();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        console.log("File size exceeds maximum allowed size");
        setError(t("profile.account.max_size"));
        return;
      }
      if (!ALLOWED_TYPES.includes(file.type)) {
        console.log("File type not allowed");
        setError(t("profile.account.invalid_type"));
        return;
      }
      setFile(URL.createObjectURL(file));
      setFileToUpload(file);
    }
  };

  return (
    <Button
      component='label'
      role={undefined}
      variant='contained'
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      sx={{
        backgroundColor: variables.color_secondary,
        "&:hover": {
          backgroundColor: variables.color_secondary,
        },
      }}
      onClick={() => console.log("click")}
    >
      {t("profile.account.upload")}
      <VisuallyHiddenInput
        type='file'
        accept={ALLOWED_TYPES.join(",")}
        onChange={(e) => handleUpload(e)}
      />
    </Button>
  );
};

export default InputFileUpload;
