import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import variables from "../../styles/variables.module.scss";

const selectAll = {
  label: "Select All",
  value: "selectAll",
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface Item {
  label: string;
  value: string;
  secondary?: string;
}

const CustomAutocomplete = ({
  items,
  selectedItems,
  setFieldValue,
  setSelectedItems,
  label,
  fieldTag,
}: {
  items: Item[];
  selectedItems: Item[];
  label: string;
  fieldTag?: string;
  setFieldValue?: (field: string, value: string[]) => void;
  setSelectedItems?: (value: string[]) => void;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Item[]>(selectedItems);
  const allSelected = items.length === selectedOptions.length;

  // console.log("Items: ", items);

  useEffect(() => {
    // console.log("Selected Options: ", selectedOptions);

    allSelected && setSelectedOptions([...selectedOptions]);
  }, [selectedItems]);

  const handleToggleOption = (selectedOptions: Item[]) =>
    setSelectedOptions(selectedOptions);

  const handleClearOptions = () => setSelectedOptions([]);

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (
    event: any,
    selectedOptions: Item[],
    reason: string
  ) => {
    // console.log(reason);

    if (reason === "selectOption") {
      if (
        selectedOptions.find((option: Item) => option.value === "selectAll")
      ) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el) => el.value !== "selectAll");
        const selectedItems = result.map((item) => item.value);
        setSelectedItems && setSelectedItems(selectedItems);
        setFieldValue && fieldTag && setFieldValue(fieldTag, selectedItems);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        const selectedItems = selectedOptions.map((item) => item.value);
        setSelectedItems && setSelectedItems(selectedItems);
        setFieldValue && fieldTag && setFieldValue(fieldTag, selectedItems);
      }
    } else if (reason === "removeOption") {
      if (
        selectedOptions.find((option: Item) => option.value === "selectAll")
      ) {
        handleClearOptions && handleClearOptions();
        setSelectedItems && setSelectedItems([]);
        setFieldValue && fieldTag && setFieldValue(fieldTag, []);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);

        let result = [];
        result = selectedOptions.filter((el) => el.value !== "selectAll");
        const selectedItems = result.map((item) => item.value);

        setSelectedItems && setSelectedItems(selectedItems);
        setFieldValue && fieldTag && setFieldValue(fieldTag, selectedItems);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
      setSelectedItems && setSelectedItems([]);
      setFieldValue && fieldTag && setFieldValue(fieldTag, []);
    }
  };
  const optionRenderer = (
    props: any,
    option: Item,
    { selected }: { selected: boolean }
  ) => {
    const selectAllProps =
      option.value === "selectAll" ? { checked: allSelected } : {};

    return (
      <li {...props} key={option.value}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
          key={option.value}
        />
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography fontSize={16}>{option.label}</Typography>
          {option.secondary && (
            <Typography fontSize={12} color={variables.text_hint}>
              {option.secondary}
            </Typography>
          )}
        </Box>
      </li>
    );
  };

  const inputRenderer = (params: any) => (
    <TextField
      {...params}
      variant='filled'
      label={label}
      InputLabelProps={{ children: params.id }}
    />
  );

  const getOptionSelected = (option: Item, anotherOption: Item) =>
    option.value === anotherOption.value;

  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      // size="small"
      limitTags={3}
      fullWidth
      options={[selectAll, ...items]}
      disableCloseOnSelect
      getOptionLabel={(option: Item) => option.label}
      value={selectedOptions}
      // getOptionSelected={getOptionSelected}
      // filterOptions={(options, params) => {
      //   const filtered = filter(options, params);
      //   return [{ fullname: "Select All", _id: "selectAll" }, ...filtered];
      // }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={(tagValue, getTagProps) => {
        return tagValue
          .filter((option) => option.value !== "selectAll")
          .map((option, index) => (
            <Chip
              size='small'
              {...getTagProps({ index })}
              key={index}
              label={option.label}
            />
          ));
      }}
      // filterSelectedOptions
    />
  );
};

export default CustomAutocomplete;
