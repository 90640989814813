import React from "react";
import AuthCheck from "../../AuthCheck";

export default function LoginLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div>
      <div>
        <AuthCheck>{children}</AuthCheck>
      </div>
    </div>
  );
}
