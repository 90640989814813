import { Container } from "@mui/material";
import Form from "../../components/forms/RequestForm";
import styles from "../../styles/Layout.module.scss";

const RequestRemovalPage = () => {
  return (
    <Container
      maxWidth={false}
      className={styles.mainContainer}
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        paddingTop: "5rem",
      }}
    >
      <Form />
    </Container>
  );
};

export default RequestRemovalPage;
