import { useState, useEffect } from "react";
import moment from "moment";
import { Poll } from "../types/poll";

const useCountdownHook = (poll: Poll) => {
  let interval: any;
  const today = moment();
  const [countDownDate, setCountDownDate] = useState(
    moment(
      poll.status === "ACTIVE" ? poll.endDate : poll.startDate,
      "DD/MM/YYYY HH:mm:ss"
    )
  );
  const [countDown, setCountDown] = useState(countDownDate.diff(today));
  const [bcTrigger, setBcTrigger] = useState<string>("");

  const getReturnValues = (countDown: any, poll: Poll) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };
  useEffect(() => {
    const updateCountdownStatus = () => {
      if (poll.status === "ACTIVE" || poll.status === "UPCOMING") {
        // console.log("id: ", poll._id, poll.status, poll.title);
        const now = moment();
        const startDate = moment(poll.startDate, "DD/MM/YYYY HH:mm:ss");
        const endDate = moment(poll.endDate, "DD/MM/YYYY HH:mm:ss");
        if (now.isBefore(startDate) && poll.status === "UPCOMING") {
          setBcTrigger("cancel");
          setCountDown(startDate.diff(now));
          // dispatch(changeStatusPoll({ poll, status: "UPCOMING" }));
        } else if (
          now.isBetween(startDate, endDate) &&
          poll.status === "UPCOMING"
        ) {
          // console.log("poll", poll);
          setBcTrigger("start");
          setCountDown(now.diff(now));
        } else if (
          now.isBetween(startDate, endDate) &&
          poll.status === "ACTIVE"
        ) {
          setCountDown(endDate.diff(now));
          setBcTrigger("");
        } else if (now.isAfter(endDate) && poll.status === "ACTIVE") {
          setCountDown(now.diff(now));
          setBcTrigger("end");
          // dispatch(changeStatusPoll({ poll, status: "PAST" }));
        } else if (now.isAfter(endDate) && poll.status === "UPCOMING") {
          setBcTrigger("invalid");
          setCountDown(startDate.diff(now));
        }
      } else {
        setBcTrigger("");
      }
    };

    updateCountdownStatus(); // Initial update

    interval = setInterval(updateCountdownStatus, 1000);
    return () => clearInterval(interval);
  }, [countDownDate, poll]);
  return { bcTrigger, timer: getReturnValues(countDown, poll) };
};

export { useCountdownHook };
