import React, { useCallback, useEffect, useState } from "react";

import { Box, Container, Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TabCard from "../TabCard";
import styles from "../../styles/PollCards.module.scss";
import AccountDetails from "./AccountDetails";
import AccountPassword from "./AccountPassword";
import { useTranslation } from "react-i18next";

const AccountSettings = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState("account");
  const paths = [
    { label: t("profile.account.name"), value: "account", Icon: PersonIcon },
    { label: t("profile.privacy.name"), value: "privacy", Icon: LockOpenIcon },
  ];

  const handleClick = useCallback(
    (value: string) => {
      setActive(value);
    },
    [setActive]
  );

  const handleActive = () => {
    switch (active) {
      case "account":
        return <AccountDetails />;
      case "privacy":
        return <AccountPassword />;
    }
  };
  return (
    <Container maxWidth={"xl"}>
      <Box paddingX={{ xs: 0, md: 12 }} paddingBottom={12}>
        <Grid
          display={"flex"}
          justifyContent={"flex-start"}
          gap={4}
          className={styles.grid}
        >
          {paths.map((path, id: number) => (
            <div
              key={id}
              className={
                active === path.value ? styles.activeCard : styles.card
              }
            >
              <TabCard
                Icon={path.Icon}
                label={path.label}
                value={path.value}
                handleClick={handleClick}
              />
            </div>
          ))}
        </Grid>
        <Box marginTop={5}>{handleActive()}</Box>
      </Box>
    </Container>
  );
};

export default AccountSettings;
