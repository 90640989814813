import { Box, Typography } from "@mui/material";
import logo from "../assets/logo_bg.svg";
import text from "../assets/logo_txt.svg";

import variables from "../styles/variables.module.scss";

export const TITLE = "Trevo";

export const LOGO = (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      color: variables.text_color_light,
      gap: 1,
    }}
  >
    <img src={logo} width='35' height='35' alt={TITLE} />
    {/* <Typography variant='h4' fontWeight={"500"}>
      {TITLE}
    </Typography> */}
    <img src={text} width='70' height='70' alt={TITLE} />
  </Box>
);
