import {
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../consts/theme";
import { Dayjs } from "dayjs";
import { StyledTextField } from "../../styled/TextField";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectLanguage } from "../../../store/selectors/uiSelector";
import { ChangeEvent, useMemo } from "react";
import * as locales from "@mui/material/locale";
import "dayjs/locale/en";
import "dayjs/locale/el";
import { TwoPartRangePicker } from "../../utils/RangePicker";

interface Props {
  props: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    title: string;
    description: string;
    // support: Support | undefined;
    dateTimeError: string | null;
    setStartDate: (date: Dayjs | null) => void;
    setEndDate: (date: Dayjs | null) => void;
    setDateTimeError: (error: string | null) => void;
    setTitle: (title: string) => void;
    setDescription: (description: string) => void;
    // setSupport: (support: Support | undefined) => void;
  };
}
const InitialDetails: React.FC<Props> = ({ props }) => {
  const CHARACTER_LIMIT_D = 500;
  const CHARACTER_LIMIT_T = 60;

  const { t } = useTranslation();
  const size = useMediaQuery(theme.breakpoints.down("md")) ? "small" : "medium";
  const locale = useAppSelector(selectLanguage);

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale as keyof typeof locales]),
    [locale, theme]
  );

  const handleStringChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    cB: { (title: string): void }
  ) => {
    const inputValue = e.target.value;
    cB(inputValue);
  };

  // const handleSupport = () => {
  //   setShow(!show);
  //   show
  //     ? props.setSupport({ name: "", email: "" })
  //     : props.setSupport(undefined);
  // };

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Container maxWidth={"sm"} sx={styles.propsSurvey}>
        {/* <InstructionsComponent text={"Insert Instructions Here"}></InstructionsComponent> */}
        <StyledTextField
          required
          id='title'
          name='title'
          label={t("wizard.steps.election_info.title")}
          autoComplete='off'
          variant='outlined'
          value={props.title}
          onChange={(e) => handleStringChange(e, props.setTitle)}
          size={size}
          inputProps={{
            maxLength: CHARACTER_LIMIT_T,
          }}
          error={!/^([^|]*)$/.test(props.title)}
          helperText={
            !/^([^|]*)$/.test(props.title)
              ? "Invalid input. '|' character not allowed."
              : `${props.title.length}/${CHARACTER_LIMIT_T}`
          }
        />
        <StyledTextField
          multiline
          rows={8}
          required
          id='description'
          name='description'
          label={t("wizard.steps.election_info.description")}
          autoComplete='off'
          variant='outlined'
          size={size}
          value={props.description}
          onChange={(e) => handleStringChange(e, props.setDescription)}
          inputProps={{ maxLength: CHARACTER_LIMIT_D }}
          helperText={`${props.description.length}/${CHARACTER_LIMIT_D}`}
        />
      </Container>
      <Container maxWidth={"md"} sx={styles.propsTime}>
        <TwoPartRangePicker
          props={{
            startDate: props.startDate,
            endDate: props.endDate,
            error: props.dateTimeError,
            size: size,
            setStartDate: props.setStartDate,
            setEndDate: props.setEndDate,
            setError: props.setDateTimeError,
          }}
        />
      </Container>
      {/* <Container maxWidth={"sm"} sx={styles.propsSupport}>
        <Box sx={styles.propsTime}>
          <Typography>
            {t("wizard.steps.election_info.support.question")}
          </Typography>
          <StyledCheckbox checked={show} onChange={handleSupport} />
        </Box>
        {show && (
          <Box sx={styles.propsTime}>
            <StyledTextField
              id="name"
              name="name"
              label={t("wizard.steps.election_info.support.name")}
              autoComplete="off"
              variant="outlined"
              value={props.support ? props.support.name : ""}
              onChange={(e) =>
                props.setSupport({
                  name: e.target.value,
                  email: props.support ? props.support.email : "",
                })
              }
            />
            <StyledTextField
              id="title"
              name="title"
              label={t("wizard.steps.election_info.support.email")}
              autoComplete="off"
              variant="outlined"
              value={props.support ? props.support.email : ""}
              onChange={(e) =>
                props.setSupport({
                  name:  props.support ? props.support.name : "",
                  email: e.target.value,
                })
              }
            />
          </Box>
        )}
      </Container> */}
    </ThemeProvider>
  );
};

const styles = {
  propsSurvey: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    paddingTop: { xs: "20px", sm: "30px", md: "50px" },
    paddingBottom: "20px",
  },
  propsTime: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    flexWrap: {
      xs: "wrap",
      sm: "wrap",
      md: "nowrap",
      lg: "nowrap",
      xl: "nowrap",
    },
    width: "100%",
  },
  propsSupport: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    gap: "20px",
  },
};

export default InitialDetails;
