import Cookies from "js-cookie";
import { User } from "../../types/authUser";
import { getJwt } from "../../services/auth/auth";
import { COOKIE_NAME, COOKIE_NAME_REF } from "../../consts/cookie";
import { AuthApi } from "../../types/api";

export const useLogin = () => {
  const login = async (username: string, password: string) => {
    const { data } = await getJwt(username, password);

    if (data.errors) {
      throw data.errors[0];
    }
    const jwt = {
      accessToken: data.data.jwt.access_token,
      // expiredAt: data.data.jwt.expires_in,
      refreshToken: data.data.jwt.refresh_token,
    };

    console.log(jwt);

    if (jwt) {
      Cookies.set(COOKIE_NAME, JSON.stringify(jwt.accessToken));
      Cookies.set(COOKIE_NAME_REF, JSON.stringify(jwt.refreshToken));
    }
    return jwt as User;
  };

  const setCookie = async ({ jwt }: AuthApi) => {
    console.log(jwt);

    Cookies.set(COOKIE_NAME, JSON.stringify(jwt.access_token));
    Cookies.set(COOKIE_NAME_REF, JSON.stringify(jwt.refresh_token));
  };

  return { login, setCookie };
};
