function useSubString(str: string, maxLength: number) {
  // const maxLength = 130;
  let trimmedString = str.substring(0, maxLength);
  trimmedString = trimmedString.substring(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );

  return { trimmedString };
}

export default useSubString;
