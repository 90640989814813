import { Backdrop, Fade, Modal } from "@mui/material";
import styles from "../../styles/Layout.module.scss";
import React, { ReactNode } from "react";

interface Props {
  props: {
    open: boolean;
    handleClose: (open: boolean) => void;
  };
  children: ReactNode;
}

const CustomModal: React.FC<Props> = ({ props, children }) => {
  return (
    <Modal
      closeAfterTransition
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        zIndex: "2",
        mt: 5,
      }}
      className={styles.modal}
      open={props.open}
      onClose={props.handleClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade
        in={props.open}
        style={{ transformOrigin: "center center" }}
        timeout={props.open ? 2000 : 500}
        unmountOnExit
      >
        <div>{children}</div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
