import { Container, Typography, lighten } from "@mui/material";
import variables from "../../../styles/variables.module.scss";

const InstructionsComponent = ({ text }: { text: string }) => {
  return (
    <Container maxWidth={"md"}>
      <Typography align='center' sx={styles}>
        {text}
      </Typography>
    </Container>
  );
};

const styles = {
  fontSize: "16px",
  marginBottom: "20px",
  color: lighten(variables.text_color, 0.45),
  fontStyle: "italic",
};

export default InstructionsComponent;
