import { Switch, styled } from "@mui/material";
import variables from "../../styles/variables.module.scss";

export const StyledSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: variables.color_primary,
    "&:hover": {
      backgroundColor: variables.color_primary_hover,
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: variables.color_primary,
  },
}));
