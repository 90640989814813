import { mapToStatus } from "./mapToStatus";
import { transformDate, reverseTransformDate } from "./transformDates";
import { Poll, PollApi } from "../../types/poll";
import { CreatePollBlockchain } from "../../types/poll";

export const parseElection = (poll: PollApi): CreatePollBlockchain => {
  const title = poll.title + "|" + poll.elements[0].title;
  const description = poll.description;
  const startDate: bigint =
    poll.startDate !== null ? transformDate(poll.startDate) : BigInt(0);
  const endDate: bigint =
    poll.endDate !== null ? transformDate(poll.endDate) : BigInt(0);
  const options = poll.elements[0].choices.map((choice) => choice.text);
  const groups = poll.groups;

  return { title, description, startDate, endDate, options, groups };
};

export const parseElectionReverse = (fetchedElection: any): Poll => {
  // console.log("Fetched: ", fetchedElection);
  // console.log(fetchedElection[2].toString());

  const { title, description, groups, startDate, endDate, options } =
    fetchedElection[1];

  const choices = options.map((option: string) => ({
    text: option,
    value: option,
  }));

  const votes = fetchedElection[7].map((vote: string) => parseInt(vote));
  // console.log(votess);

  const electionTitle = title.split("|")[0];
  const question = title.split("|")[1];

  const poll: Poll = {
    _id: fetchedElection[0].toString(),
    title: electionTitle,
    description,
    startDate: reverseTransformDate(startDate),
    endDate: reverseTransformDate(endDate),
    createdAt: reverseTransformDate(fetchedElection[3]),
    status: mapToStatus(fetchedElection[2].toString()),
    groups: groups.map((group: string) => group),
    elements: [{ type: "radiogroup", name: "", title: question, choices }],
    participation: parseInt(fetchedElection[5]),
    toVote: parseInt(fetchedElection[6]),
    votes: options.reduce(
      (acc: { [x: string]: number }, option: string, index: number) => {
        acc[option] = votes[index];
        return acc;
      },
      {}
    ),
    is_draft: false,
    hidden: false,
    author: "",
    committed: [],
    invited: [],
  };

  // console.log("parsed:", poll.title);

  return poll;
};
