import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styles from "../../styles/Table.module.scss";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Action } from "../../types/table";

const CustomMenu = ({ actions }: { actions: Action[] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label='edit button'
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.icon}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions.map((ac: Action, index: number) => {
          return (
            ac.show && (
              <MenuItem
                key={index}
                onClick={(e) => {
                  ac.action(e);
                  handleClose();
                }}
              >
                {ac.icon && <ListItemIcon>{ac.icon}</ListItemIcon>}
                {ac.label}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default CustomMenu;

export const CustomIconMenu = ({ actions }: { actions: Action[] }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box style={{ gap: "5px" }}>
      {actions.map((action, index) => (
        <IconButton
          key={index}
          onClick={(e) => {
            action.action(e);
            handleClick();
          }}
        >
          {action.icon && (
            <Tooltip title={action.label} placement='top'>
              {action.icon}
            </Tooltip>
          )}
        </IconButton>
      ))}
    </Box>
  );
};
