import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledButton } from "./styled/Button";
import { useState } from "react";
import AlertDialog from "./utils/CustomAlertDialog";
import { deleteConfirm } from "../services/api/voter.api";
import * as Sentry from "@sentry/react";

const ConfirmRemoval = ({ code }: { code: string }) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<string | null>("");

  const handleConfirm = (e: any) => {
    e.preventDefault();
    console.log(code);
    deleteConfirm(code)
      .then((res: any) => {
        console.log(res);
        if (res.status === 200) {
          setAlert(t("pages.removal.confirmed"));
        }
      })
      .catch((error) => {
        console.error("Axios request error:", error);
        Sentry.captureException(error);
        setAlert(t("wizard.errors.systemError"));
      });
  };

  return (
    <Container sx={styles.container} maxWidth='xl'>
      <Paper sx={styles.paper} elevation={3}>
        <Box sx={styles.box}>
          <Typography sx={styles.text}>
            {t("pages.removal.confirm_text")}
          </Typography>
          <StyledButton
            sx={{ fontSize: "14px" }}
            variant='contained'
            type='button'
            onClick={(e) => handleConfirm(e)}
          >
            {t("pages.removal.confirm")}
          </StyledButton>
        </Box>
      </Paper>
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={[{ label: "Ok", onClick: () => setAlert(null) }]}
        />
      )}
    </Container>
  );
};

export default ConfirmRemoval;

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "15rem",
  },
  paper: {
    display: "flex",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
    gap: "40px",
  },
  text: {
    fontSize: "20px",
    fontWeight: "600",
  },
};
