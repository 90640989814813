import Cookies from "js-cookie";
import { COOKIE_NAME } from "../../consts/cookie";
import { getExpiration } from "../../services/api/utils.api";
import * as Sentry from "@sentry/react";

export const useAuth = () => {
  const currentUser = Cookies.get(COOKIE_NAME);
  if (currentUser) {
    try {
      const expiration = getExpiration();
      const now = Math.floor(Date.now() / 1000);
      // console.log("EXP: ", expiration);
      // console.log("NOW: ", now);
      if (now < expiration) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(`Invalid token error: ${error}`);
      console.log(`Invalid token error: ${error}`);
      return false;
    }
  } else {
    return false;
  }
};
