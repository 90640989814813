import { useEffect, useState } from "react";
import { Action, Column, TableSchema } from "../../../../types/table";
import { TableBar } from "../../../../components/styled/AppBar";
import {
  AddOne,
  StyledIconButton,
} from "../../../../components/styled/AddButton";
import CustomTable from "../../../../components/Table/CustomTable";
import { useAppSelector, useAppThunkDispatch } from "../../../../store/hooks";
import { usePathname } from "../../../../hooks/usePathname";
import { Outlet, useNavigate } from "react-router-dom";
import Loading from "../../../../components/utils/Loading";
import { Box, Container, Toolbar, Tooltip, Typography } from "@mui/material";
import {
  selectGroups,
  selectGroupsLoading,
} from "../../../../store/selectors/groupSelector";
import { Group } from "../../../../types/group";
import {
  getActiveGroup,
  removeGroup,
} from "../../../../store/actions/groupAction";
import { useTranslation } from "react-i18next";
import Form from "../../../../components/forms/GroupForm";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import CustomModal from "../../../../components/utils/CustomModal";
import { deleteGroup } from "../../../../services/api/group.api";
import { selectTSG } from "../../../../store/selectors/uiSelector";
import { getTableStateGroups } from "../../../../store/actions/uiActions";
import AlertDialog from "../../../../components/utils/CustomAlertDialog";
import * as Sentry from "@sentry/react";

type CustomType = TableSchema<Group> & { numberVoters: number };

const GroupsPage = () => {
  const { t } = useTranslation();
  const title = t("electorate.table.title.groups");
  const ts = useAppSelector(selectTSG);
  const [page, setPage] = useState(ts.page);
  const [rowsPerPage, setRowsPerPage] = useState(ts.rpp);
  const data = useAppSelector(selectGroups);
  // const voters = useAppSelector(selectVoters);
  const isLoading = useAppSelector(selectGroupsLoading);
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const router = useNavigate();
  const [alert, setAlert] = useState<null | string>(null);
  const [groupToDelete, setGroupToDelete] = useState<Group | null>(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log(users);

  useEffect(() => {
    dispatch(getTableStateGroups({ rpp: rowsPerPage, page }));
  }, [rowsPerPage, page]);

  console.log("Data: ", data);

  const handleDeleteConfirmation = () => {
    if (groupToDelete) {
      deleteGroup(groupToDelete)
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeGroup(groupToDelete._id));
          }
        })
        .catch((error) => {
          console.error("Axios request error:", error);
          Sentry.captureException(error);
          setAlert(error);
        });
    }
    setAlert(null);
    setGroupToDelete(null);
  };

  const ProcessRows = (data: Group[]): CustomType[] => {
    return data.map((row) => {
      const actions: Action[] = [
        {
          label: t("general.menu.options.more"),
          icon: <Visibility />,
          action: (e: React.MouseEvent<HTMLElement>) => {
            router(`${pathname}/groups/${row._id}/`);
          },
          show: true,
        },
        {
          label: t("general.menu.options.edit"),
          icon: <Edit />,
          action: (e: React.MouseEvent<HTMLElement>) => {
            console.log(row._id);

            const activeGroup = data.find(
              (group) => group._id === row._id?.toString()
            );

            activeGroup && dispatch(getActiveGroup(activeGroup));

            console.log(activeGroup);
            handleOpen();
          },
          show: true,
        },
        {
          label: t("general.menu.options.delete"),
          icon: <Delete />,
          action: (e: React.MouseEvent<HTMLElement>) => {
            // console.log(row._id);
            setAlert(
              `${t(t("general.menu.warning.message3"), {
                id: row.title,
              })} ${t("general.menu.warning.message4")} ${t(
                "general.menu.warning.group"
              )} ${t("general.menu.warning.caution")}`
            );
            const group =
              data.find((group) => group._id === row._id.toString()) || null;
            setGroupToDelete(group);
          },
          show: true,
        },
      ];

      !actions.some((action) => action.show === true) &&
        actions.push({
          label: "No Available Actions",
          icon: <></>,
          action: (e: React.MouseEvent<HTMLElement>) => {
            return;
          },
          show: true,
        });

      // const names =
      //   row.voters && row.voters.length > 0 ? getNames(voters, row.voters) : "";

      return { ...row, actions, numberVoters: row.members.length };
    });
  };

  const columns: Column<CustomType, keyof CustomType>[] = [
    // { name: "ID", key: "_id" },
    { name: t("electorate.table.columns.title"), key: "title" },
    { name: t("electorate.table.columns.description"), key: "description" },
    { name: t("electorate.table.columns.voters"), key: "numberVoters" },
  ];

  const GroupsTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant='h2'>{title}</Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            <Tooltip
              title={t("electorate.table.button.add_new_group")}
              placement='bottom'
            >
              <StyledIconButton
                aria-label='add'
                onClick={handleOpen}
                edge='end'
              >
                <AddOne />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </TableBar>
    );
  };

  // console.log("Data: ", data);
  // console.log("Columns: ", columns);

  if (isLoading) return <Loading />;

  return (
    <Container maxWidth='xl'>
      <CustomTable
        toolbar={GroupsTableToolbar}
        rows={ProcessRows(data)}
        columns={columns}
        pg={page}
        setPg={setPage}
        rpp={rowsPerPage}
        setRpp={setRowsPerPage}
      />

      <CustomModal
        props={{
          open: open,
          handleClose: handleClose,
        }}
      >
        <Form close={handleClose} />
      </CustomModal>
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={
            groupToDelete
              ? [
                  { label: "Cancel", onClick: () => setAlert(null) },
                  { label: "Ok", onClick: () => handleDeleteConfirmation() },
                ]
              : [{ label: "Ok", onClick: () => setAlert(null) }]
          }
        />
      )}
      <Outlet />
    </Container>
  );
};

export default GroupsPage;
