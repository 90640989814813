import { Box, Paper } from "@mui/material";
import { CustomTypographyTitle } from "./utils/CustomTypography";
import { ReactNode } from "react";
import { PollStatus } from "../types/poll";
import { getPollColor, getPollColorLight } from "../utils/getColours";

const containerStyles = {
  container: {
    // minWidth: "400px",
  },
  title: {
    borderRadius: "10px",
    padding: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginBottom: "30px",
  },
};

interface DetailsCardProps {
  title: string;
  children: ReactNode;
  pollType?: PollStatus;
  width?: {
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
  shrinked?: boolean;
}

const DetailsCard: React.FC<DetailsCardProps> = ({
  title,
  children,
  width = "100%",
  shrinked = false,
  pollType = "ALL",
}) => {
  return (
    <Paper
      sx={{
        ...containerStyles.container,
        width,
        padding: shrinked
          ? { xs: "20px", md: "40px", lg: "25px", xl: "35px" }
          : { xs: "20px", lg: "40px" },
      }}
      elevation={2}
    >
      <Box
        sx={{
          ...containerStyles.title,
          backgroundColor: getPollColorLight(pollType),
          color: getPollColor(pollType),
          border: "2px solid",
          borderColor: getPollColor(pollType),
        }}
      >
        <CustomTypographyTitle shrinked={shrinked}>
          {title}
        </CustomTypographyTitle>
      </Box>
      {children}
    </Paper>
  );
};

export default DetailsCard;
