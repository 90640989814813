import { PollStatus } from "../../types/poll";

export const mapToStatus = (status: string): PollStatus => {
  switch (status) {
    case "0":
      return "UPCOMING";
    case "1":
      return "ACTIVE";
    case "2":
      return "PAST";
    case "3":
      return "CANCELLED";
    default:
      return "DRAFT";
  }
};
