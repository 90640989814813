import { StyledButton, StyledTransparentButton } from "../styled/Button";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { StyledTextField } from "../styled/TextField";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Formik } from "formik";
import { yupValidationPrivacy } from "../../utils/yupValidation";
import { getUsername } from "../../services/api/utils.api";
import { Visibility } from "@mui/icons-material";
import { useState } from "react";
import { changePwd } from "../../services/api/el.api";
import * as Sentry from "@sentry/react";
import AlertDialog from "../utils/CustomAlertDialog";
import CustomLoader from "../utils/CustomLoader";
import CustomModal from "../utils/CustomModal";

const AccountPassword = () => {
  const { t } = useTranslation();
  const username = getUsername();
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdConfirm, setShowPwdConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <Paper elevation={0} sx={{ p: 8, borderRadius: 4 }}>
      <Formik
        // enableReinitialize
        initialValues={{ password: "", confirm_password: "" }}
        onSubmit={(values, actions) => {
          console.log("Change Password: ", values);

          setIsLoading(true);
          changePwd(username, values.password)
            .then((res: any) => {
              console.log(res);
              if (res.status === 200) {
                setIsLoading(false);
                setError(t("profile.privacy.confirm_message"));
              }
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Axios request error:", error);
              Sentry.captureException(error);
              setError(t("wizard.errors.systemError"));
            });
        }}
        validationSchema={yupValidationPrivacy}
      >
        {({
          values,
          errors,
          touched,
          // setValues,
          setFieldValue,
          handleChange,
          // handleBlur,
          handleSubmit,
          // isSubmitting,
          resetForm,
        }) => (
          <form
            // className={styles.form}
            onSubmit={handleSubmit}
            autoComplete='off'
          >
            <Grid flexDirection={"column"} container gap={4}>
              <Grid>
                <Typography variant='h5'>
                  {t("profile.privacy.change_pass")}
                </Typography>
              </Grid>
              <Grid container gap={4}>
                {/* <Field
                  as={StyledTextField}
                  required
                  id='password'
                  name='password'
                  label={t("profile.privacy.current_pass")}
                  autoComplete='off'
                  variant='outlined'
                  type='password'
                  // inputProps={{ minLength: 8 }}
                /> */}
                <Field
                  as={StyledTextField}
                  required
                  id={"password"}
                  name={"password"}
                  label={t("profile.privacy.new_pass")}
                  autoComplete='off'
                  variant='outlined'
                  type={showPwd ? "text" : "password"}
                  helperText={<ErrorMessage name={"password"} />}
                  error={errors["password"] && touched["password"]}
                  // inputProps={{ minLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Visibility
                          sx={{ cursor: "pointer" }}
                          onClick={() => setShowPwd(!showPwd)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  as={StyledTextField}
                  required
                  id='confirm_password'
                  name='confirm_password'
                  label={t("profile.privacy.confirm_pass")}
                  autoComplete='off'
                  variant='outlined'
                  type={showPwdConfirm ? "text" : "password"}
                  helperText={<ErrorMessage name={"confirm_password"} />}
                  error={
                    errors["confirm_password"] && touched["confirm_password"]
                  }
                  // inputProps={{ minLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Visibility
                          sx={{ cursor: "pointer" }}
                          onClick={() => setShowPwdConfirm(!showPwdConfirm)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Box>
                <Typography
                  variant='body2'
                  sx={{ color: "rgba(58, 53, 65, 0.6)", marginBottom: 2 }}
                >
                  {t("profile.privacy.pass_requirements")}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ color: "rgba(58, 53, 65, 0.6)" }}
                >
                  <ul style={{ gap: 4 }}>
                    <li>{t("profile.privacy.length")}</li>
                    <li>{t("profile.privacy.case")}</li>
                    <li>{t("profile.privacy.symbol")}</li>
                  </ul>
                </Typography>
              </Box>
              <Grid marginTop={4} gap={2} flexDirection={"row"} container>
                <StyledButton type='submit'>
                  {t("profile.privacy.save_changes")}
                </StyledButton>
                <StyledTransparentButton
                  type={"reset"}
                  onClick={() => resetForm()}
                >
                  {t("profile.privacy.reset")}
                </StyledTransparentButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {isLoading && (
        <CustomModal
          props={{
            open: true,
            handleClose: () => {
              console.log("closed");
            },
          }}
        >
          <CustomLoader />
        </CustomModal>
      )}
      {error && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={error}
          buttons={[{ label: "Ok", onClick: () => setError(null) }]}
        />
      )}
    </Paper>
  );
};

export default AccountPassword;
