import { Box, Container, Paper, Typography } from "@mui/material";
import styles from "../../styles/Wizard.module.scss";
import CustomizedStepper from "./wizard/PollStepper";
import { useEffect, useRef, useState } from "react";
import InitialDetails from "./wizard/InitialDetails";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import { getActivePoll } from "../../store/actions/pollActions";
import PollCreatorComponent from "./wizard/PollCreator";
import SelectPollType from "./wizard/SelectPollType";
import PollComponent from "./PollComponent";
import ConfirmPublish from "./wizard/ConfirmPublish";
import variables from "../../styles/variables.module.scss";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "../../store/selectors/uiSelector";
import { parseElection } from "../../utils/bc/parseElection";
import { selectProofZkMerkleTree } from "../../store/selectors/blockchainSelector";
import { useContract } from "../../hooks/useContract";
import { updatePoll, validatePoll } from "../../services/api/polls.api";
import { getDrafts } from "../../store/effects/pollEffects";
import { getGroups } from "../../store/effects/groupEffects";
import { selectGroups } from "../../store/selectors/groupSelector";
import { selectVoters } from "../../store/selectors/voterSelector";
import Loading from "../utils/Loading";
import { getError } from "../../utils/isFormValid";
import { getBcElectionHash, getBcId, getHash } from "../../utils/bc/getHash";
import { CreatePollBlockchain, Poll, PollApi } from "../../types/poll";
import SelectGroupsAlt from "./wizard/SelectGroupsAlt";
import AlertDialog from "../utils/CustomAlertDialog";
import { Voter } from "../../types/voter";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import InstructionsComponent from "./wizard/Instructions";
import * as Sentry from "@sentry/react";

const steps = [
  "election_info",
  "ballot_design",
  "voters",
  "preview",
  "publish",
];

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const convertToUTC = (date: Dayjs) => {
  const utcDate = date.utc();

  // console.log("UTC:", utcDate);
  // console.log("UTC:", utcDate.format("DD/MM/YYYY HH:mm:ss"));
  const formattedDate = utcDate.format("DD/MM/YYYY HH:mm");

  if (formattedDate === "Invalid Date") return null;
  else return formattedDate;
};

export const convertToLocal = (date: string) => {
  // console.log("UTC: ", date);
  const localDate = dayjs.utc(date, "DD/MM/YYYY HH:mm"); //dayjs(date, "DD/MM/YYYY HH:mm").utc();
  // console.log("LOCAL: ", localDate.local());
  return localDate.local();
};

const PollHandler = ({ poll }: { poll: Poll }) => {
  const { t } = useTranslation();
  const language = useAppSelector(selectLanguage);
  const { contract } = useContract();
  const dispatch = useAppThunkDispatch();
  const initialRender = useRef(true);

  const [activeStep, setActiveStep] = useState(0);
  // const [disableNext, setDisableNext] = useState(false);
  const [error, setError] = useState("");

  const [startDate, setStartDate] = useState<Dayjs | null>(
    poll.startDate ? convertToLocal(poll.startDate) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    poll.endDate ? convertToLocal(poll.endDate) : null
  );
  const [title, setTitle] = useState<string>(poll.title);
  const [description, setDescription] = useState<string>(poll.description);
  const [dateTimeError, setDateTimeError] = useState<string | null>(null);
  // const [support, setSupport] = useState<Support | undefined>();

  const [surveyType, setSurveyType] = useState<string>(poll.elements[0].type);
  const [elements, setElements] = useState(poll.elements[0]);

  const [groups, setGroups] = useState<string[]>(poll.groups);
  const GROUPS = useAppSelector(selectGroups);
  const VOTERS = useAppSelector(selectVoters);

  const [newPoll, setNewPoll] = useState<PollApi>(poll);

  const proof = useAppSelector(selectProofZkMerkleTree).proof;
  const signals = useAppSelector(selectProofZkMerkleTree).publicSignals;

  const [published, setPublished] = useState(false);
  const [excludedVoters, setExcludedVoters] = useState<Voter[]>([]);

  const [alert, setAlert] = useState<null | string>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(true);

  const [axiosError, setAxiosError] = useState(false);

  const invalidPollError = t("wizard.steps.errors.invalidPoll");

  const setDirty = () => {
    setNewPoll({ ...newPoll, isDirty: true });
  };

  // console.log(
  //   "POLL WIZ: ",
  //   parseElection({
  //     ...newPoll,
  //     startDate: startDate && convertToUTC(startDate),
  //     endDate: endDate && convertToUTC(endDate),
  //   })
  // );

  useEffect(() => {
    if (activeStep === 3) {
      const { excludedVoters } = getVoters();
      const { voterList } = getVoters();
      setExcludedVoters(excludedVoters);
      setError(
        getError(
          startDate === null || endDate === null ? "null" : dateTimeError,
          title,
          description,
          newPoll.isDirty,
          groups,
          excludedVoters,
          voterList,
          elements.choices,
          axiosError
        )
      );
    }
  }, [newPoll]);

  useEffect(() => {
    poll && dispatch(getActivePoll(poll));
    dispatch(getGroups());
  }, []);

  useEffect(() => {
    if (!initialRender.current) {
      handleSave();
    } else {
      initialRender.current = false;
    }
  }, [elements]);

  useEffect(() => {
    return () => {
      dispatch(getActivePoll(null));
    };
  }, []);

  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [activeStep]);

  const handleSave = () => {
    console.log("called");
    const elementsCheck = elements.choices
      ? elements
      : { ...elements, choices: [] };
    const new_poll: PollApi = {
      ...newPoll,
      startDate: startDate ? convertToUTC(startDate) : null,
      endDate: endDate ? convertToUTC(endDate) : null,
      title,
      description,
      elements: [{ ...elementsCheck, type: surveyType }],
      status: "DRAFT",
      groups: groups,
      is_draft: true,
    };
    console.log("SAVE: ", new_poll);
    console.log(">>>>");

    setNewPoll(new_poll);
    updatePoll(new_poll)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setAxiosError(false);
          setSnackbarOpen(true);
          dispatch(getDrafts());
        }
      })
      .catch((error) => {
        console.error("Axios request error:", error);
        Sentry.captureException(error);
        setAlert(error);
        setSnackbarOpen(false);
        setAxiosError(true);
      });
  };

  const getVoters = () => {
    const voters = GROUPS.filter((group) => groups.includes(group._id)).flatMap(
      (group) => group.members
    );

    const voterList = VOTERS.filter(
      (voter) =>
        voters.includes(voter._id) &&
        voter.registered &&
        voter.blockchain_address !== undefined &&
        voter.blockchain_address !== "" &&
        voter.blockchain_address !==
          "0x0000000000000000000000000000000000000000"
    ).map((voter) => voter.blockchain_address);

    const excludedVoters = VOTERS.filter(
      (voter) =>
        voters.includes(voter._id) &&
        (!voter.registered ||
          voter.blockchain_address === undefined ||
          voter.blockchain_address.length < 1 ||
          voter.blockchain_address ===
            "0x0000000000000000000000000000000000000000")
    );

    console.log("Voters: ", voterList);
    console.log("Excluded: ", excludedVoters);

    return { voterList, excludedVoters };
  };

  const registerElection = async (
    election: CreatePollBlockchain,
    voterList: string[]
  ) => {
    const tx = await contract.registerElection(
      proof,
      signals,
      election,
      voterList
    );
    console.log(tx);
    return await tx.wait();
  };

  const handlePublish = () => {
    const new_poll: PollApi = {
      ...newPoll,
      status: "UPCOMING",
    };
    console.log("Publish: ", new_poll);
    console.log(">>>>");

    setNewPoll(new_poll);

    validatePoll(new_poll)
      .then((res) => {
        if (res.status === 200 && res.data.data === "Valid") {
          console.log("Validate: ", res.data);

          if (startDate && endDate) {
            /* BC CreateNewElection */
            const election = parseElection({
              ...new_poll,
              startDate: convertToUTC(startDate),
              endDate: convertToUTC(endDate),
            });

            const { voterList } = getVoters();

            console.log("BC ELECTION: ", election);
            console.log("BC Voterlist: ", voterList);

            registerElection(election, voterList)
              .then((res) => {
                if (res.hash && new_poll.startDate !== null) {
                  const newElectionHash = getHash(
                    startDate.format("DD/MM/YYYY HH:mm"),
                    title
                  );
                  // console.log("new election hash:", newElectionHash);
                  contract &&
                    contract
                      .fetchElectionsAsElectionLeader(proof, signals)
                      .then((res: any[]) => {
                        let foundMatch = false;
                        res.map((election: any) => {
                          const electionHash = getBcElectionHash(election);
                          // console.log("election hash: ", electionHash);
                          if (electionHash === newElectionHash) {
                            // console.log("found :", electionHash);
                            foundMatch = true;
                            updatePoll({
                              ...new_poll,
                              bc_id: getBcId(election),
                              startDate: convertToUTC(startDate),
                              endDate: convertToUTC(endDate),
                              is_draft: false,
                            })
                              .then((res) => {
                                if (res.status === 200) {
                                  console.log(res);
                                  // dispatch(getDrafts());
                                  setPublished(true);
                                }
                              })
                              .catch((error) => {
                                console.error("Axios request error:", error);
                                Sentry.captureException(error);
                                setAlert(error);
                              });
                          }
                        });
                        if (!foundMatch) {
                          throw new Error("No matching election hash found");
                        }
                      })
                      .catch((error: any) => {
                        console.log(error);
                        Sentry.captureException(error);
                        setAlert("An error has occured");
                      });
                }
              })
              .catch((error: any) => {
                //BAD DATA type error -> address not an address
                console.log(error);
                Sentry.captureException(error);
                if (error.code) {
                  console.log("BC request error:", error.code);
                  setAlert(error.code.toString());
                } else if (error.revert.args[0]) {
                  console.log("BC request error:", error.revert.args[0]);
                  setAlert(
                    error.revert.args[0].replace("execution reverted:", "")
                  );
                } else {
                  setAlert("Error needs investigation");
                }
              });
          }
        } else {
          throw new Error(invalidPollError);
        }
      })
      .catch((error: any) => {
        console.log(error);
        Sentry.captureException(error);
        if (error === invalidPollError) {
          setAlert(invalidPollError);
        }
        setAlert("An error has occured");
      });
  };

  return (
    <Container
      id='wizard'
      ref={ref}
      className={styles.wizardContainer}
      maxWidth='xl'
    >
      {activeStep !== 4 && (
        <>
          <Box
            sx={{
              backgroundColor: variables.color_primary_lighter,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Typography
              sx={{ fontSize: { sm: "30px", md: "35px" }, fontWeight: "800" }}
            >
              {t("wizard.message")}
            </Typography>
          </Box>
          <CustomizedStepper
            props={{
              steps,
              activeStep,
              setActiveStep,
              handleSave,
              handlePublish,
              disableNext: error !== "excludedVoters" && error !== "",
              excludedVoters,
              snackbarOpen,
            }}
          >
            <Paper
              sx={{
                paddingBottom: {
                  xs: "30px",
                  sm: "50px",
                  lg: "60px",
                  xl: "80px",
                },
                paddingTop: { xs: "30px", sm: "50px", lg: "60px", xl: "80px" },
                paddingLeft: { xs: "30px", sm: "50px", lg: "15px", xl: "50px" },
                paddingRight: {
                  xs: "30px",
                  sm: "50px",
                  lg: "15px",
                  xl: "50px",
                },
              }}
              elevation={1}
            >
              {activeStep === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InstructionsComponent
                    text={t("wizard.steps.election_info.instructions")}
                  />
                  <InitialDetails
                    props={{
                      startDate,
                      endDate,
                      title,
                      description,
                      dateTimeError,
                      // support,
                      setStartDate,
                      setEndDate,
                      setDateTimeError,
                      setTitle,
                      setDescription,
                      // setSupport,
                    }}
                  />
                </Box>
              )}
              {activeStep === 1 && (
                // <Container className={styles.container} maxWidth="xl">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InstructionsComponent
                    text={t("wizard.steps.ballot_design.instructions")}
                  />
                  <SelectPollType type={surveyType} setType={setSurveyType} />
                  <PollCreatorComponent
                    poll={newPoll}
                    setElements={setElements}
                    // saveData={handleSave}
                    setDirty={setDirty}
                    surveyType={surveyType}
                    language={language === "enUS" ? "en" : "gr"}
                  />
                </Box>
                // </Container>
              )}
              {activeStep === 2 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InstructionsComponent
                    text={t("wizard.steps.groups.instructions")}
                  />{" "}
                  {/* <SelectGroups
                    storedGroups={GROUPS}
                    groups={groups}
                    setGroups={setGroups}
                  /> */}
                  <SelectGroupsAlt
                    storedGroups={GROUPS}
                    groups={groups}
                    setGroups={setGroups}
                  />
                </Box>
              )}
              {activeStep === 3 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {error !== "" && (
                    <Typography
                      sx={{
                        fontSize: "18px",
                        marginBottom: "10px",
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {error === "excludedVoters"
                        ? t(`wizard.errors.${error}`) +
                          excludedVoters.map((voter) => voter._id)
                        : t(`wizard.errors.${error}`)}
                    </Typography>
                  )}
                  {/* <InstructionsComponent text={"Insert Instructions Here"} /> */}
                  <PollComponent
                    poll={{
                      ...newPoll,
                      startDate: startDate
                        ? startDate.format("DD/MM/YYYY HH:mm")
                        : null,
                      endDate: endDate
                        ? endDate.format("DD/MM/YYYY HH:mm")
                        : null,
                      participation: 0,
                      toVote: 0,
                      votes: {},
                    }}
                    shrinked={true}
                  />
                </Box>
              )}
            </Paper>
          </CustomizedStepper>
        </>
      )}
      {activeStep === 4 && (published ? <ConfirmPublish /> : <Loading />)}
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setAlert(null);
                // router(-1);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default PollHandler;
