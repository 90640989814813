import { RootState } from "../store";

export const selectVoters = (state: RootState) => state.voters.voters;
export const selectVotersLoading = (state: RootState) => state.voters.loading;
export const selectActiveVoter = (state: RootState) => state.voters.activeVoter;

// const selectUserById = createSelector(
//   [(state) => state.users, (state, id) => id],
//   (users, id) => users.filter((user: User) => user._id === id)
// );
