import { Box, Container, Toolbar, Typography } from "@mui/material";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import CustomTable from "../Table/CustomTable";
import { useTranslation } from "react-i18next";
import { selectActiveVoter } from "../../store/selectors/voterSelector";
import { Column } from "../../types/table";
import { TableBar } from "../styled/AppBar";
import { Poll } from "../../types/poll";
import PreviewDetails from "./VoterPreviewDetails";
import { selectPolls } from "../../store/selectors/pollSelector";
import { useEffect, useState } from "react";
import { getActiveChoice } from "../../store/actions/uiActions";

type CustomType = Omit<Poll, "committed" | "invited" | "voted"> & {
  committed: string;
  invited: string;
  voted: string;
};

const VoterComponent = () => {
  const dispatch = useAppThunkDispatch();
  const { t } = useTranslation();
  // const rpp = 5;
  // const ts = useAppSelector(selectTSP);
  // const [rowsPerPage, setRowsPerPage] = useState(ts.rpp);
  // const [page, setPage] = useState(ts.page);
  const title = t("electorate.table.title.ballots");
  const activeVoter = useAppSelector(selectActiveVoter);
  const polls = useAppSelector(selectPolls);
  const [data, setData] = useState<CustomType[]>([]);

  console.log(activeVoter);

  // console.log("RPPS: ", ts);

  useEffect(() => {
    return () => {
      dispatch(getActiveChoice("voters"));
    };
  }, []);

  // useEffect(() => {
  //   dispatch(getTableStatePolls({ rpp: rowsPerPage, page }));
  // }, [rowsPerPage]);

  useEffect(() => {
    setData(
      activeVoter
        ? polls
            .filter((poll) => {
              return (
                poll._id in activeVoter.committed ||
                poll._id in activeVoter.invited ||
                poll._id in activeVoter.voted
              );
            })
            .map((poll) => {
              // const committed = activeVoter.committed.find(
              //   (commitment) => commitment[poll._id]
              // );

              // const committedTimestamp = committed
              //   ? timestampToDate(committed[poll._id])
              //   : null;

              const invitedTimestamp = activeVoter.invited[parseInt(poll._id)]
                ? activeVoter.invited[parseInt(poll._id)].slice(0, -3) //timestampToDate(activeVoter.invited[parseInt(poll._id)])
                : null;

              const committedTimestamp = activeVoter.committed[
                parseInt(poll._id)
              ]
                ? activeVoter.committed[parseInt(poll._id)].slice(0, -3) //timestampToDate(activeVoter.committed[parseInt(poll._id)])
                : null;

              const votedTimestamp = activeVoter.voted[parseInt(poll._id)]
                ? activeVoter.voted[parseInt(poll._id)].slice(0, -3) //timestampToDate(activeVoter.invited[parseInt(poll._id)])
                : null;

              console.log(committedTimestamp);
              return {
                ...poll,
                committed: committedTimestamp ? committedTimestamp : "No",
                invited: invitedTimestamp ? invitedTimestamp : "Yes",
                voted: votedTimestamp ? votedTimestamp : "No",
              };
            })
        : []
    );
  }, [polls, activeVoter]);

  // const data = activeVoter?.polls
  //   ? polls
  //       .filter((poll) =>
  //         activeVoter.polls.some((voterPoll) => voterPoll.poll_id === poll.id)
  //       )
  //       .map((poll) => ({
  //         ...poll,
  //         participation: activeVoter.polls.find(
  //           (voterPoll) => voterPoll.poll_id === poll.id
  //         )?.voted
  //           ? t("electorate.manage_voters.voters.voted")
  //           : t("electorate.manage_voters.voters.not_voted"),
  //       }))
  //   : [];

  // const pollCards = polls
  //   .filter((poll) =>
  //     activeVoter?.polls.some((voterPoll) => voterPoll.poll_id === poll.id)
  //   )
  //   .map((poll) => ({
  //     poll,
  //     participation: activeVoter?.polls.find(
  //       (voterPoll) => voterPoll.poll_id === poll.id
  //     )?.voted,
  //   }));

  console.log(activeVoter);

  const ProcessRows = (data: CustomType[]): CustomType[] => {
    return data.map((row) => {
      console.log(row);
      //   {
      //     label: t("general.menu.options.more"),
      //     action: (e: React.MouseEvent<HTMLElement>) => {
      //       router(`${pathname}/${row.id}/`);
      //     },
      //     show: true,
      //   },
      // ];

      return { ...row };
    });
  };

  const VotersTableToolbar = (): JSX.Element => {
    return (
      <TableBar position='relative'>
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant='h2'>{title}</Typography>
        </Toolbar>
      </TableBar>
    );
  };

  const columns: Column<CustomType, keyof CustomType>[] = [
    // { name: "ID", key: "_id" },
    { name: t("polls.title").toUpperCase(), key: "title" },
    { name: t("polls.details.status").toUpperCase(), key: "status" },
    { name: t("polls.details.invited").toUpperCase(), key: "invited" },
    { name: t("polls.details.committed").toUpperCase(), key: "committed" },
    { name: t("polls.details.voted").toUpperCase(), key: "voted" },
    // { name: t("electorate.table.columns.participation"), key: "participation" },
  ];

  return (
    <Container sx={containerStyles.container} maxWidth={false}>
      {activeVoter && <PreviewDetails voter={activeVoter} />}
      {activeVoter && (
        <CustomTable
          rows={ProcessRows(data)}
          columns={columns}
          toolbar={VotersTableToolbar}
          // pg={page}
          // setPg={setPage}
          // rpp={rowsPerPage}
          // setRpp={setRowsPerPage}
          rppOptions={[5, 10]}
        />
      )}
      <Box sx={containerStyles.box}>
        {/* {activeVoter &&
          pollCards
            .splice(pollCards.length - 3, pollCards.length)
            .map((poll, index) => {
              console.log("POLL:", poll);
              return (
                <Grow
                  key={index}
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  timeout={2000+index*1000}
                  unmountOnExit
                >
                  <div>
                    <PollSingle poll={poll.poll} absent={poll.participation} />
                  </div>
                </Grow>
              );
            })} */}
      </Box>
    </Container>
  );
};

const containerStyles = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", lg: "column", xl: "row" },
    justifyContent: "center",
    height: "100%",
    gap: { xs: "20px", sm: "20px", md: "30px", lg: "30px" },
    width: { lg: "90%", xl: "80%" },
  },
  box: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

export default VoterComponent;
