import { Box, Divider, Typography } from "@mui/material";
import { Poll, PollStatus } from "../../../types/poll";
import BarChartIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import { useTranslation } from "react-i18next";
import CustomTypographyInfoTitle from "../../utils/CustomTypography";
import DetailsCard from "../../DetailsCard";
import { getPollColor } from "../../../utils/getColours";

const PreviewDetails = ({
  poll,
  shrinked,
}: {
  poll: Poll;
  shrinked: boolean;
}) => {
  const { t } = useTranslation();
  // const timer = useCountdownHook(poll);
  // const participationData = poll.status === "PAST" && {
  //   [t("polls.details.p_voters")]: (poll.participation / poll.toVote) * 100,
  //   [t("polls.details.a_voters")]:
  //     100 - (poll.participation / poll.toVote) * 100,
  // };

  const title =
    poll.status === "PAST"
      ? t("polls.details.results")
      : poll.status === "ACTIVE"
      ? t("polls.details.title_active")
      : t("polls.details.title_upcoming");

  const getIcon = (pollType: PollStatus) => {
    const iconStyles = {
      fontSize: shrinked ? 80 : 90,
      color: getPollColor(pollType),
    };

    switch (pollType) {
      case "PAST":
        return <BarChartIcon sx={iconStyles} />;
      case "ACTIVE":
        return <HowToVoteIcon sx={iconStyles} />;
      case "UPCOMING":
        return (
          // <Box
          //   sx={{
          //     backgroundColor: getPollColorLight(pollType),
          //     color: getPollColor(pollType),
          //     borderRadius: "10px",
          //     padding: 2,
          //   }}
          // >
          <CampaignIcon sx={iconStyles} />
          // </Box>
        );
      case "DRAFT":
        return <ModeEditIcon sx={iconStyles} />;
      default:
        return <></>;
    }
  };

  const fontSize = shrinked ? "17px" : "18px";

  return (
    <DetailsCard
      title={title}
      pollType={poll.status}
      width={{ xs: "100%", lg: "30%", xl: "30%" }}
      shrinked={shrinked}
    >
      <Box sx={containerStyles.header}>
        {getIcon(poll.status)}
        <Typography fontSize={shrinked ? "22px" : "25px"} variant='h4'>
          {t("polls.details.details")}
        </Typography>
      </Box>
      <Divider />
      <Box sx={containerStyles.description}>
        <Typography
          variant='body1'
          sx={{
            textAlign: "justify",
            width: "100%",
          }}
        >
          {poll.description}
        </Typography>
      </Box>
      <Divider />
      <Box sx={containerStyles.boxDown}>
        <Box sx={containerStyles.boxIn}>
          <CustomTypographyInfoTitle fontSize={fontSize}>
            {t("polls.details.status")}:{" "}
          </CustomTypographyInfoTitle>
          <Typography variant='body1'>
            &nbsp; {t(`polls.status.${poll.status.toLowerCase()}`)}
          </Typography>
        </Box>
        <Box sx={containerStyles.boxIn}>
          <CustomTypographyInfoTitle fontSize={fontSize}>
            {t("polls.details.start_date")}:{" "}
          </CustomTypographyInfoTitle>
          <Typography variant='body1'>
            &nbsp; {poll.startDate && poll.startDate}
          </Typography>
        </Box>
        <Box sx={containerStyles.boxIn}>
          <CustomTypographyInfoTitle fontSize={fontSize}>
            {t("polls.details.end_date")}:{" "}
          </CustomTypographyInfoTitle>
          <Typography variant='body1'>
            &nbsp; {poll.endDate && poll.endDate}
          </Typography>
        </Box>
      </Box>
      {(poll.status === "PAST" ||
        poll.status === "ACTIVE" ||
        poll.status === "UPCOMING") && (
        <>
          <Divider />
          <Box sx={containerStyles.boxDown}>
            <Box sx={containerStyles.boxIn}>
              <CustomTypographyInfoTitle>
                {t("polls.details.r_voters")}:{" "}
              </CustomTypographyInfoTitle>
              <Typography variant='body1'>&nbsp; {poll.toVote} </Typography>
            </Box>
            <Box sx={containerStyles.boxIn}>
              <CustomTypographyInfoTitle>
                {t("polls.details.c_voters")}:{" "}
              </CustomTypographyInfoTitle>
              <Typography variant='body1'>
                &nbsp; {poll.committed.length}{" "}
              </Typography>
            </Box>
            <Box sx={containerStyles.boxIn}>
              <CustomTypographyInfoTitle>
                {t("polls.details.p_voters")}:{" "}
              </CustomTypographyInfoTitle>
              <Typography variant='body1'>
                &nbsp; {poll.participation}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      {/* {(poll.status === "ACTIVE" || poll.status === "UPCOMING") && (
          <Box
            sx={{
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Countdown poll={poll} timer={timer} />
          </Box>
        )} */}
      {poll.status === "PAST" && (
        <>
          <Divider />
          <Box sx={containerStyles.boxDown}>
            <Box sx={containerStyles.boxIn}>
              <CustomTypographyInfoTitle>
                {t("polls.details.participation")}:
              </CustomTypographyInfoTitle>
              <Typography variant='body1'>
                &nbsp;{" "}
                {((poll.participation / poll.toVote) * 100).toFixed(2) + "%"}
              </Typography>
            </Box>
            <Box sx={containerStyles.boxIn}>
              <CustomTypographyInfoTitle>
                {t("polls.details.a_voters")}:
              </CustomTypographyInfoTitle>
              <Typography variant='body1'>
                &nbsp;{" "}
                {(100 - (poll.participation / poll.toVote) * 100).toFixed(2) +
                  "%"}
              </Typography>
            </Box>
            {/* <BarChart
              title={t("polls.details.participation")}
              data={participationData}
            /> */}
          </Box>
        </>
      )}
      {/* <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        {getIcon(poll.status)}
      </Box> */}
    </DetailsCard>
  );
};

const containerStyles = {
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
    marginBottom: "10px",
  },
  description: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "20px",
    // maxWidth: "200px",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  boxDown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
  },
  boxIn: {
    display: "flex",
    flexDirection: "row",
    wrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
};

export default PreviewDetails;
