import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";
import variables from "../../styles/variables.module.scss";
import { Choice, PollStatus, Results } from "../../types/poll";
import PieChart from "../../components/charts/PieChart";
import { getPollColor, getPollColorLight } from "../getColours";
import { useTranslation } from "react-i18next";
import { CustomTypographyQuestion } from "../../components/utils/CustomTypography";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; secondaryValue: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 80 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(
          props.value
        )}% (${props.secondaryValue})`}</Typography>
      </Box>
    </Box>
  );
}

export const renderResults = (
  results: Results,
  participation: number,
  instructions: string,
  pollType: PollStatus
) => {
  return (
    <>
      <Box sx={{ marginBottom: 2, color: getPollColor("ALL") }}>
        <CustomTypographyQuestion>{instructions}</CustomTypographyQuestion>
      </Box>
      {Object.entries(results).map(([title, value], index) => {
        const percentage = ((value / participation) * 100) | 0;
        return (
          <Box key={index} sx={{ width: "100%" }}>
            <Typography>{title}</Typography>

            <LinearProgressWithLabel
              value={percentage}
              secondaryValue={value}
            />
          </Box>
        );
      })}
    </>
  );
};

export const renderParticipation = (
  data: any,
  title: string,
  pollType: PollStatus
) => (
  <Box
    sx={{
      backgroundColor: getPollColorLight(pollType),
      borderRadius: "10px",
      padding: "40px",
      marginTop: "50px",
      width: "100%",
    }}
  >
    <PieChart data={data} title={title} pollType={pollType} />
  </Box>
);

export const renderResultsChart = (
  data: any,
  title: string,
  pollType: PollStatus
) => (
  <Box
    sx={{
      backgroundColor: getPollColorLight(pollType),
      borderRadius: "10px",
      padding: "40px",
      marginTop: "50px",
      width: "100%",
    }}
  >
    <PieChart data={data} title={title} />
    {/* <BarChart data={pollResults?.results} /> */}
  </Box>
);

export const renderChoices = (
  title: string,
  choices: Choice[],
  pollType: PollStatus,
  shrinked?: boolean
) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        ...containerStyles.box,
        backgroundColor: getPollColorLight(pollType),
      }}
    >
      <Box
        sx={{
          // ...containerStyles.title
          // backgroundColor: getPollColorLight(pollType),
          color: getPollColor("ALL"),
          borderRadius: "10px",
          padding: shrinked ? "5px" : "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          marginBottom: shrinked ? "10px" : "20px",
          // border: "2px solid",
          // borderColor: getPollColor(pollType),
        }}
      >
        <CustomTypographyQuestion shrinked={shrinked}>
          {title}
        </CustomTypographyQuestion>
      </Box>
      {choices.length > 0 ? (
        Object.entries(choices).map(([text, choice], index) => (
          <Box
            key={index}
            sx={{
              ...containerStyles.choices,
              border: `2px solid ${getPollColor(pollType)}`,
              borderRadius: "0.5rem",
              backgroundColor: variables.white,
            }}
          >
            <Typography
              sx={{
                fontSize: shrinked ? "18px" : "20px",
                marginBottom: "10px",
              }}
            >
              {`${index + 1}. ${choice.text}`}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography
          sx={{
            alignSelf: "center",
            fontSize: shrinked ? "18px" : "20px",
            marginBottom: "10px",
          }}
        >
          {t("wizard.errors.emptyPoll")}
        </Typography>
      )}
    </Box>
  );
};

const containerStyles = {
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    borderRadius: "10px",
    padding: "50px",
    width: "100%",
    // backgroundColor: lighten(variables.background_toolbar, 0.6),
  },
  choices: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    minWidth: "50%",
  },
};
