import { Typography } from "@mui/material";

const CustomTypographyInfoTitle = ({
  children,
  fontSize = "18px",
}: {
  children: React.ReactNode;
  fontSize?: string;
}) => {
  return (
    <Typography variant='h6' sx={{ fontSize, fontWeight: 700 }}>
      {children}
    </Typography>
  );
};

export default CustomTypographyInfoTitle;

export const CustomTypographyTitle = ({
  children,
  shrinked = false,
}: {
  children: React.ReactNode;
  shrinked?: boolean;
}) => {
  return (
    <Typography
      variant='h1'
      sx={{
        fontSize: shrinked ? "25px" : "30px",
        fontWeight: "600",
        overflowWrap: "break-word",
      }}
    >
      {children}
    </Typography>
  );
};

export const CustomTypographyQuestion = ({
  children,
  shrinked = false,
}: {
  children: React.ReactNode;
  shrinked?: boolean;
}) => {
  return (
    <Typography
      variant='h1'
      align='center'
      sx={{
        fontSize: shrinked ? "18px" : "20px",
        fontWeight: "600",
        overflowWrap: "break-word",
      }}
    >
      {children}
    </Typography>
  );
};
