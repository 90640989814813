import { Box, Container, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Group } from "../../types/group";
import DetailsCard from "../DetailsCard";

const PreviewDetails = ({ group }: { group: Group }) => {
  const { t } = useTranslation();

  return (
    <DetailsCard title={group.title} width={{ xs: "100%", lg: "30%" }}>
      <Box sx={containerStyles.header}>
        <Typography variant='h4'>{t("polls.details.details")}</Typography>
      </Box>
      <Divider />
      <Box sx={containerStyles.description}>
        <Typography
          variant='body1'
          sx={{
            textAlign: "justify",
            width: "100%",
          }}
        >
          {group.description}
        </Typography>
      </Box>
      {/* <Divider /> */}
    </DetailsCard>
  );
};

const containerStyles = {
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
    marginBottom: "10px",
  },
  description: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "20px",
    // maxWidth: "200px",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
};

export default PreviewDetails;
