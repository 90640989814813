import {
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import userReducer, { UserState } from "./reducers/userReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { USERS_PREFIX, USER_ACTIONS } from "./actions/userActions";
import { PersistPartial } from "redux-persist/es/persistReducer";
import pollReducer, { PollState } from "./reducers/pollReducer";
import { POLLS_PREFIX } from "./actions/pollActions";
import { UI_PREFIX } from "./actions/uiActions";
import uiReducer, { UiState } from "./reducers/uiReducer";
import voterReducer, { VoterState } from "./reducers/voterReducer";
import { VOTERS_PREFIX } from "./actions/voterAction";
import groupReducer, { GroupState } from "./reducers/groupReducer";
import { GROUPS_PREFIX } from "./actions/groupAction";
import i18n from "../i18n";
import { BLOCKCHAIN_PREFIX } from "./actions/blockchainActions";
import blockchainReducer, {
  BlockchainState,
} from "./reducers/blockchainReducer";

const userPersistConfig = {
  key: "user",
  storage,
  blacklist: ["loading"],
};

const voterPersistConfig = {
  key: "voter",
  storage,
  blacklist: ["loading"],
};

const groupPersistConfig = {
  key: "group",
  storage,
  blacklist: ["loading"],
};

const pollPersistConfig = {
  key: "poll",
  storage,
  blacklist: ["loading"],
};

const uiPersistConfig = {
  key: "ui",
  storage,
  blacklist: ["loading"],
};

const blockchainPersistConfig = {
  key: "blockchain",
  storage,
  blacklist: ["loading"],
};

// const initialState = {
//   [USERS_PREFIX]: initialUserState,
//   [VOTERS_PREFIX]: initialVoterState,
//   [GROUPS_PREFIX]: initialGroupState,
//   [POLLS_PREFIX]: initialPollState,
//   [UI_PREFIX]: initialUiState,
//   [BLOCKCHAIN_PREFIX]: initialBlockchainState,
// };

const appReducer = combineReducers({
  [USERS_PREFIX]: persistReducer(userPersistConfig, userReducer),
  [VOTERS_PREFIX]: persistReducer(voterPersistConfig, voterReducer),
  [GROUPS_PREFIX]: persistReducer(groupPersistConfig, groupReducer),
  [POLLS_PREFIX]: persistReducer(pollPersistConfig, pollReducer),
  [UI_PREFIX]: persistReducer(uiPersistConfig, uiReducer),
  [BLOCKCHAIN_PREFIX]: persistReducer(
    blockchainPersistConfig,
    blockchainReducer
  ),
});

const rootReducer = (state: StateCombined, action: AnyAction) => {
  if (action.type === USER_ACTIONS.LOGOUT) {
    console.log("LOGOUT");
    i18n.changeLanguage("enUS");
    // localStorage.setItem('i18nextLng', 'en-US');
    // storage.removeItem('persist:root')
    return appReducer(state, action);
  }

  return appReducer(state, action);
};

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  // reducer: reducers,
  reducer: rootReducer,
  middleware: [thunk], // (getDefaultMiddleware) => getDefaultMiddleware(),//.concat(myLoggingMiddleware)
  // preloadedState,
  // devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

// create types for state and dispatch
// export type RootState = ReturnType<typeof store.getState>;
export interface RootState {
  [USERS_PREFIX]: UserState;
  [GROUPS_PREFIX]: GroupState;
  [VOTERS_PREFIX]: VoterState;
  [POLLS_PREFIX]: PollState;
  [UI_PREFIX]: UiState;
  [BLOCKCHAIN_PREFIX]: BlockchainState;
}

type StateCombined =
  | CombinedState<{
      users: UserState & PersistPartial;
      voters: VoterState & PersistPartial;
      groups: GroupState & PersistPartial;
      polls: PollState & PersistPartial;
      ui: UiState & PersistPartial;
      blockchain: BlockchainState & PersistPartial;
    }>
  | undefined;

/* To check

import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
export const myLoggingMiddleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  // Here you have access to `action.type` and `action.payload`
  console.log('Logging action with type', action.type)
  
  // You should always do this at the end of your middleware
  return next(action)
}


*/
