import Nav from "../../components/Nav";
import variables from "../../styles/variables.module.scss";
import AuthCheck from "../../AuthCheck";
import { Outlet } from "react-router-dom";
import styles from "../../styles/Layout.module.scss";
import NavPhone from "../../components/NavPhone";

export default function DashboardLayout({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <AuthCheck>
      <div className={styles.phoneNav}>
        <NavPhone />
      </div>
      <div className={styles.nav}>
        <Nav />
      </div>
      <div
        className={styles.mainContainer}
        style={{
          width: "100%",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: variables.background_base,
          }}
        >
          <main className='container'>
            <Outlet />
          </main>
        </div>
      </div>
    </AuthCheck>
  );
}
