import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload, Proof } from "../../types/redux";
import { BLOCKCHAIN_ACTIONS } from "../actions/blockchainActions";

export interface BlockchainState {
  zkEthereumAddress: { proof: Proof; publicSignals: string[] };
  zkMerkleTree: { proof: string[]; publicSignals: string[] };
  pathItems: { pathElements: string[]; pathIndices: string[] };
  loading: boolean;
}

export const initialBlockchainState: BlockchainState = {
  zkEthereumAddress: {
    proof: { pi_a: [], pi_b: [], pi_c: [], protocol: "", curve: "" },
    publicSignals: [],
  },
  zkMerkleTree: { proof: [], publicSignals: [] },
  pathItems: { pathElements: [], pathIndices: [] },
  loading: false,
};

const blockchainReducer = createReducer(initialBlockchainState, (builder) => {
  builder.addCase(
    BLOCKCHAIN_ACTIONS.SET_PATH_ITEMS,
    (state, action: ActionWithPayload<any>) => {
      // console.log(action.payload);
      console.log("PATH RED: ", action.payload);

      return {
        ...state,
        pathItems: {
          pathElements: action.payload.pathElements,
          pathIndices: action.payload.pathIndices,
        },
      };
    }
  );
  builder.addCase(
    BLOCKCHAIN_ACTIONS.SET_PROOF_zkAddress,
    (state, action: ActionWithPayload<any>) => {
      // console.log(action.payload);
      console.log("PROOF Address RED: ", action.payload);

      return {
        ...state,
        zkEthereumAddress: {
          proof: action.payload.proof,
          publicSignals: action.payload.publicSignals,
        },
      };
    }
  );
  builder.addCase(
    BLOCKCHAIN_ACTIONS.SET_PROOF_zkMerkle,
    (state, action: ActionWithPayload<any>) => {
      // console.log(action.payload);
      console.log("PROOF Merkle RED: ", action.payload);

      return {
        ...state,
        zkMerkleTree: {
          proof: action.payload.proof,
          publicSignals: action.payload.publicSignals,
        },
      };
    }
  );
});

export default blockchainReducer;
