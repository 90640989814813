import {
  Container,
  Paper,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import variables from "../../styles/variables.module.scss";
import { useTranslation } from "react-i18next";

const CustomLoader = () => {
  const { t } = useTranslation();

  return (
    <Container sx={styles.formContainer} maxWidth='lg'>
      <Paper elevation={2} sx={styles.paper}>
        <Box sx={styles.box}>
          <Typography>{t("general.wait")}</Typography>
          <CircularProgress sx={{ color: variables.color_secondary }} />
        </Box>
      </Paper>
    </Container>
  );
};

export default CustomLoader;

const styles = {
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    top: variables.padding_main,
  },
  paper: {
    overflow: "hidden",
    padding: 6,
    borderRadius: 8,
    border: `5px solid ${variables.color_secondary}`,
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 3,
    padding: variables.padding_secondary,
    position: "relative",
    zIndex: 2,
    flexGrow: 1,
  },
};
