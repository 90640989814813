import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import variables from "../../../styles/variables.module.scss";

const AboutPage = () => {
  const { t } = useTranslation();

  const getTitle = (title: string) => {
    return (
      <>
        <Typography variant='h5' gutterBottom>
          {title}
        </Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
      </>
    );
  };

  console.log(useAppSelector((state) => state.blockchain));

  const getParagraph = (p: string) => {
    return (
      <Typography variant='body1' paragraph>
        {p}
      </Typography>
    );
  };

  const getListItem = (p: string, s: string) => {
    return (
      <ListItem sx={{ display: "list-item" }}>
        <ListItemText
          primaryTypographyProps={{ fontSize: "18px" }}
          secondaryTypographyProps={{ fontSize: "16px" }}
          primary={<strong>{p}</strong>}
          secondary={s}
        />
      </ListItem>
    );
  };

  return (
    <Container maxWidth='lg' sx={{ paddingTop: variables.padTop }}>
      <Paper elevation={2} style={{ padding: 50 }}>
        <Typography variant='h4' gutterBottom>
          {t("about.title")}
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        {getTitle(t("about.about"))}
        {getParagraph(t("about.aboutDesc"))}
        {getTitle(t("about.motivation"))}
        {getParagraph(t("about.motivationDesc"))}
        {getTitle(t("about.generic"))}
        {getParagraph(t("about.genericDesc"))}
        {getTitle(t("about.essentialFunctionalities.title"))}
        <List dense sx={{ marginLeft: 3, listStyleType: "disc" }}>
          {getListItem(
            t("about.essentialFunctionalities.bc"),
            t("about.essentialFunctionalities.bcDesc")
          )}
          {getListItem(
            t("about.essentialFunctionalities.zkp"),
            t("about.essentialFunctionalities.zkpDesc")
          )}
          {getListItem(
            t("about.essentialFunctionalities.ssi"),
            t("about.essentialFunctionalities.ssiDesc")
          )}
          {getListItem(
            t("about.essentialFunctionalities.mobileWebApp"),
            t("about.essentialFunctionalities.mobileWebAppDesc")
          )}
        </List>
        {getTitle(t("about.integration"))}
        {getParagraph(t("about.integrationDesc"))}
        {getTitle(t("about.gap"))}
        {getParagraph(t("about.gapDesc"))}
        {getTitle(t("about.expecetdBenefits.title"))}
        <List dense sx={{ marginLeft: 3, listStyleType: "disc" }}>
          {getListItem(
            t("about.expecetdBenefits.securityTransparency"),
            t("about.expecetdBenefits.securityTransparencyDesc")
          )}
          {getListItem(
            t("about.expecetdBenefits.voterParticipation"),
            t("about.expecetdBenefits.voterParticipationDesc")
          )}
          {getListItem(
            t("about.expecetdBenefits.electoralProcess"),
            t("about.expecetdBenefits.electoralProcessDesc")
          )}
        </List>
        {getTitle(t("about.demoScenario"))}
        {getParagraph(t("about.demoScenarioDesc"))}
      </Paper>
    </Container>
  );
};

export default AboutPage;
