import styles from "../../styles/PollCards.module.scss";
import {
  Box,
  Container,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import BarChartIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import { CreatePollApi, PollStatus } from "../../types/poll";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../styled/Button";
import { useAppThunkDispatch } from "../../store/hooks";
import { createPoll } from "../../services/api/polls.api";
import { getDrafts } from "../../store/effects/pollEffects";
import { getActiveType } from "../../store/actions/uiActions";
import AlertDialog, { TransitionAlert } from "../utils/CustomAlertDialog";
import * as Sentry from "@sentry/react";
import { theme } from "../../consts/theme";
import { BorderAll, Cancel } from "@mui/icons-material";

// type Counts = {
//   [type in PollStatus]: number;
// };

const PollCards = ({
  setPollType,
  pollType,
  ungroupedVoters = 0,
}: {
  setPollType: any;
  pollType: PollStatus | undefined;
  ungroupedVoters?: number;
}) => {
  const { t } = useTranslation();
  // const pathname = usePathname();
  // const dragControls = useDragControls();
  const dispatch = useAppThunkDispatch();
  const router = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClick = (poll: PollStatus) => {
    setPollType(poll);
    dispatch(getActiveType(poll));
  };

  // useEffect(() => {
  //   return () => {
  //     handleClick("ALL");
  //   };
  // }, []);

  const handleAddPoll = (e: any) => {
    const poll: CreatePollApi = {
      startDate: null,
      endDate: null,
      status: "DRAFT",
      createdAt:
        new Date().toLocaleDateString("en-GB") +
        " " +
        new Date().toLocaleTimeString("en-GB").slice(0.3),
      groups: [],
      title: "title",
      description: "description",
      elements: [
        {
          type: "radiogroup",
          name: "title",
          title: t("wizard.steps.ballot_design.question_msg"),
          choices: [
            // {
            //   value: "Item 1",
            //   text: `${t("wizard.steps.ballot_design.candidate")} 1`,
            // },
            // {
            //   value: "Item 2",
            //   text: `${t("wizard.steps.ballot_design.candidate")} 2`,
            // },
            // {
            //   value: "Item 2",
            //   text: `${t("wizard.steps.ballot_design.candidate")} 3`,
            // },
          ],
        },
      ],
      isDirty: false,
      is_draft: true,
    };

    console.log("ADD POLL: ", poll);
    // dispatch(addPoll(poll));
    // dispatch(getActivePoll(poll));

    createPoll(poll)
      .then((res) => {
        if (res.status === 200) {
          const id = res.data.data.replace("Created: ", "");
          dispatch(getDrafts());
          router(`/dashboard/polls/edit/${id}`);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Axios request error:", error);
        setError(error);
      });
  };

  return (
    <Container className={styles.surveyContainer} maxWidth={false}>
      <Box marginBottom={5}>
        {ungroupedVoters > 0 && (
          <TransitionAlert
            open={ungroupedVoters > 0}
            message={t("dashboard.messages.voters", {
              numOfVoters: ungroupedVoters,
            })}
          />
        )}
      </Box>
      <Grid
        className={styles.grid}
        gap={{ xs: 1, sm: 2, md: 4, lg: 6, xl: 8 }}
        mb={{ xs: 0, lg: 2, xl: 4 }}
      >
        <button
          className={pollType === "ALL" ? styles.activeCard : styles.card}
          onClick={(e) => handleClick("ALL")}
        >
          <Grid
            container
            flex={1}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <BorderAll className='cardContent' />
            {isSmallScreen && (
              <Typography variant='body2'>{t("general.all")}</Typography>
            )}
          </Grid>
        </button>
        <button
          className={pollType === "ACTIVE" ? styles.activeCard : styles.card}
          onClick={(e) => handleClick("ACTIVE")}
        >
          <Grid
            container
            flex={1}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <HowToVoteIcon className='cardContent' />
            {isSmallScreen && (
              <Typography variant='body2'>
                {t("dashboard.status.active")}
              </Typography>
            )}
          </Grid>
        </button>
        <button
          className={pollType === "UPCOMING" ? styles.activeCard : styles.card}
          onClick={(e) => handleClick("UPCOMING")}
        >
          <Grid
            container
            flex={1}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <CampaignIcon className='cardContent' />
            {isSmallScreen && (
              <Typography variant='body2'>
                {t("dashboard.status.upcoming")}
              </Typography>
            )}
          </Grid>
        </button>
        <button
          className={pollType === "PAST" ? styles.activeCard : styles.card}
          onClick={(e) => handleClick("PAST")}
        >
          <Grid
            container
            flex={1}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <BarChartIcon className='cardContent' />
            {isSmallScreen && (
              <Typography variant='body2'>
                {t("dashboard.status.past")}
              </Typography>
            )}
          </Grid>
        </button>
        <button
          className={pollType === "DRAFT" ? styles.activeCard : styles.card}
          onClick={(e) => handleClick("DRAFT")}
        >
          <Grid
            container
            flex={1}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <ModeEditIcon className='cardContent' />
            {isSmallScreen && (
              <Typography variant='body2'>
                {t("dashboard.status.draft")}
              </Typography>
            )}
          </Grid>
        </button>
        <button
          className={pollType === "CANCELLED" ? styles.activeCard : styles.card}
          onClick={(e) => handleClick("CANCELLED")}
        >
          <Grid
            container
            flex={1}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <Cancel className='cardContent' />
            {isSmallScreen && (
              <Typography variant='body2'>
                {t("dashboard.status.cancelled")}
              </Typography>
            )}
          </Grid>
        </button>
        <Tooltip title={t("dashboard.create_election")} placement={"top"}>
          <StyledButton
            className={styles.activeCard}
            onClick={(e) => handleAddPoll(e)}
          >
            <AddBoxIcon />
          </StyledButton>
        </Tooltip>
      </Grid>
      {error && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={error}
          buttons={[{ label: "Ok", onClick: () => setError(null) }]}
        />
      )}
    </Container>
  );
};

const iconStyles = { fontSize: 50, cursor: "pointer" };

export default PollCards;
