import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const SelectPollType = ({
  type,
  setType,
}: {
  type: string;
  setType: (type: string) => void;
}) => {
  const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
  };

  return (
    // <Container className={styles.surveyContainer} maxWidth={"xl"}>
    // <Box sx={{ bgcolor: theme.palette.primary.dark }}>
    <Box>
      <Tabs centered value={type} onChange={handleChange}>
        <Tab
          value='radiogroup'
          label={t("wizard.steps.ballot_design.election_ballot")}
        />
        {/* <Tab
          value="checkbox"
          label={t("wizard.steps.ballot_design.opinion_ballot")}
        /> */}
      </Tabs>
    </Box>
    // </Container>
  );
};

export default SelectPollType;
