import { createAction } from "@reduxjs/toolkit";
import { Group } from "../../types/group";

export const GROUPS_PREFIX = "groups";

export const GROUP_ACTIONS = {
  GET_GROUPS: `${GROUPS_PREFIX}/GET_GROUPS`,
  GET_ACTIVE_GROUP: `${GROUPS_PREFIX}/GET_ACTIVE_GROUP`,
  REMOVE_GROUP: `${GROUPS_PREFIX}/REMOVE_GROUP`,
  ADD_GROUP: `${GROUPS_PREFIX}/ADD_GROUP`,
  UPDATE_GROUP: `${GROUPS_PREFIX}/UPDATE_GROUP`,
} as const;

export const getActiveGroup = createAction(
  GROUP_ACTIONS.GET_ACTIVE_GROUP,
  (group: Group | null): { payload: Group | null } => {
    // console.log(group);
    return { payload: group };
  }
);

export const removeGroup = createAction(
  GROUP_ACTIONS.REMOVE_GROUP,
  (group: string): { payload: string } => {
    return { payload: group };
  }
);

export const addGroup = createAction(
  GROUP_ACTIONS.ADD_GROUP,
  (group: Group): { payload: Group } => {
    return { payload: group };
  }
);

export const updateGroup = createAction(
  GROUP_ACTIONS.UPDATE_GROUP,
  (group: Group): { payload: Group } => {
    return { payload: group };
  }
);

export const getMockGroups = createAction(GROUP_ACTIONS.GET_GROUPS);
