import { useEffect, useState } from "react";
import { StyledTextField } from "../styled/TextField";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { StyledButton, StyledTransparentButton } from "../styled/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getUsername } from "../../services/api/utils.api";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import { selectProfile } from "../../store/selectors/userSelector";
import { Field, Formik } from "formik";
import { yupValidationAccount } from "../../utils/yupValidation";
import CustomModal from "../utils/CustomModal";
import CustomLoader from "../utils/CustomLoader";
import AlertDialog from "../utils/CustomAlertDialog";
import { fetchEL, updateEL, uploadPhotoEL } from "../../services/api/el.api";
import { ElectionLeaderUpdate } from "../../types/el";
import * as Sentry from "@sentry/react";
import { getProfile } from "../../store/actions/userActions";
import InputFileUpload from "../utils/Upload";
import { MAX_FILE_SIZE_TXT } from "../../consts/upload";

const AccountDetails = () => {
  const { t } = useTranslation();
  const profile = useAppSelector(selectProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const photo = useAppSelector(selectProfile)?.photo;
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [file, setFile] = useState<string | undefined>(photo);
  const dispatch = useAppThunkDispatch();

  const syncData = (username: string) => {
    fetchEL(username)
      .then((res) => {
        dispatch(getProfile({ ...res.data.data, photo: file }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
        alert(error.message);
        // setError(error.message);
        setIsLoading(false);
      });
  };

  return (
    <Paper elevation={0} sx={{ p: { xs: 0, md: 8 }, borderRadius: 4 }}>
      <Formik
        // enableReinitialize
        initialValues={{
          username: getUsername(),
          firstName: profile?.firstName || "",
          lastName: profile?.lastName || "",
          description: profile?.description || "",
        }}
        onSubmit={(values, actions) => {
          console.log("Change Details: ", values);

          const el: ElectionLeaderUpdate = {
            username: values.username,
            fullname: values.firstName + " " + values.lastName,
            description: values.description,
          };

          setIsLoading(true);
          updateEL(el)
            .then((res: any) => {
              console.log(res);
              if (res.status === 200) {
                const formData = new FormData();
                formData.append("election_leader", values.username);
                fileToUpload && formData.append("file", fileToUpload);

                console.log(
                  "Values: ",
                  formData.get("election_leader"),
                  formData.get("file")
                );

                fileToUpload
                  ? uploadPhotoEL(formData)
                      .then((resp) => {
                        if (resp.status === 200) {
                          values.username && syncData(values.username);
                        }
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        console.error("Axios request error:", error);
                        Sentry.captureException(error);
                        setError(t("wizard.errors.systemError"));
                      })
                  : syncData(values.username);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Axios request error:", error);
              Sentry.captureException(error);
              setError(t("wizard.errors.systemError"));
            });
        }}
        validationSchema={yupValidationAccount}
      >
        {({
          values,
          errors,
          touched,
          // setValues,
          setFieldValue,
          handleChange,
          // handleBlur,
          handleSubmit,
          // isSubmitting,
          resetForm,
        }) => (
          <form
            // className={styles.form}
            onSubmit={handleSubmit}
            autoComplete='off'
          >
            <Grid
              bgcolor={"#fff"}
              p={8}
              borderRadius={4}
              flexDirection={"column"}
              container
              gap={4}
            >
              <Grid flexDirection={"row"} item container gap={2}>
                {file ? (
                  <img src={file} height={"120px"} width={"120px"} />
                ) : (
                  <AccountCircleIcon
                    style={{
                      height: "120px",
                      width: "120px",
                    }}
                  />
                )}
                <Box>
                  <Grid flexDirection={"column"} container>
                    <Grid
                      marginTop={{ xs: 0, md: 4 }}
                      gap={2}
                      flexDirection={"row"}
                      container
                    >
                      <InputFileUpload
                        setFile={setFile}
                        setFileToUpload={setFileToUpload}
                        setError={setError}
                      />
                      <StyledTransparentButton
                        type={"reset"}
                        onClick={() => setFile(undefined)}
                      >
                        {t("profile.account.reset")}
                      </StyledTransparentButton>
                    </Grid>
                    <Typography variant={"body2"} sx={{ marginTop: 2 }}>
                      {t("profile.account.photo_requirements", {
                        size: MAX_FILE_SIZE_TXT,
                      })}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid flexDirection={"column"} container gap={4}>
                <Grid
                  item
                  flexDirection={"row"}
                  gap={2}
                  container
                  justifyContent={"space-evenly"}
                >
                  <Grid item flexGrow={1}>
                    <Field
                      as={StyledTextField}
                      id={"username"}
                      name={"username"}
                      label={t("profile.account.username")}
                      autoComplete='off'
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Field
                      as={StyledTextField}
                      id={"description"}
                      name={"description"}
                      label={t("profile.account.occupation")}
                      autoComplete='off'
                      variant='outlined'
                      // helperText={<ErrorMessage name={"occupation"} />}
                      // error={errors["occupation"] && touched["occupation"]}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  flexDirection={"row"}
                  gap={2}
                  container
                  justifyContent={"space-evenly"}
                >
                  <Grid item flexGrow={1}>
                    <Field
                      as={StyledTextField}
                      id={"firstName"}
                      name={"firstName"}
                      label={t("profile.account.firstname")}
                      autoComplete='off'
                      variant='outlined'
                      // helperText={<ErrorMessage name={"firstName"} />}
                      // error={errors["firstName"] && touched["firstName"]}
                    />
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Field
                      as={StyledTextField}
                      id={"lastName"}
                      name={"lastName"}
                      label={t("profile.account.lastname")}
                      autoComplete='off'
                      variant='outlined'
                      // helperText={<ErrorMessage name={"lastName"} />}
                      // error={errors["lastName"] && touched["lastName"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid marginTop={4} gap={2} flexDirection={"row"} container>
                <StyledButton type='submit'>
                  {t("profile.account.save_changes")}
                </StyledButton>
                <StyledTransparentButton
                  type={"reset"}
                  onClick={() => resetForm()}
                >
                  {t("profile.account.reset")}
                </StyledTransparentButton>
              </Grid>
            </Grid>
            <Grid></Grid>
          </form>
        )}
      </Formik>
      {isLoading && (
        <CustomModal
          props={{
            open: true,
            handleClose: () => {
              console.log("closed");
            },
          }}
        >
          <CustomLoader />
        </CustomModal>
      )}
      {error && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={error}
          buttons={[{ label: "Ok", onClick: () => setError(null) }]}
        />
      )}
    </Paper>
  );
};

export default AccountDetails;
