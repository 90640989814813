import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/Login";
import HomePage from "./pages/HomePage";
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import PollPage from "./pages/dashboard/polls/PollPage";
import PollPreviewPage from "./pages/dashboard/polls/PollPreview";
import EditPollPage from "./pages/dashboard/polls/EditPollPage";
import ElectorateManagementPage from "./pages/dashboard/electorate/ElectorateManagementPage";
import VoterPage from "./pages/dashboard/electorate/voters/VoterPage";
import GroupsPage from "./pages/dashboard/electorate/groups/GroupPage";
import PreviewGroupPage from "./pages/dashboard/electorate/groups/PreviewGroupPage";
import PreviewVoterPage from "./pages/dashboard/electorate/voters/PreviewVoterPage";
import AboutPage from "./pages/dashboard/about/AboutPage";
import RequestRemovalPage from "./pages/removal/RequestRemovalPage";
import ConfirmRemovalPage from "./pages/removal/ConfirmRemovalPage";
import AccountPage from "./pages/dashboard/settings/AccountPage";

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        {/*Removal Request*/}
        <Route path='/remove-user-request' element={<RequestRemovalPage />} />
        <Route
          path='/remove-user-confirm/:code'
          element={<ConfirmRemovalPage />}
        />
        <Route path='/login' element={<LoginPage />} />
        <Route element={<DashboardLayout />}>
          {/*Profile*/}
          <Route path='/dashboard/:profile' element={<AccountPage />} />
          {/*About*/}
          <Route path='/dashboard/about' element={<AboutPage />} />
          {/* Evaluate Page */}
          {/* <Route path="/dashboard/evaluate" element={<EvaluatePage />} /> */}
          {/* Electorate Management Page */}
          <Route
            path='/dashboard/electorate'
            element={<ElectorateManagementPage />}
          />
          {/* Groups */}
          <Route path='/dashboard/electorate/groups' element={<GroupsPage />} />
          <Route
            path='/dashboard/electorate/groups/:id'
            element={<PreviewGroupPage />}
          />
          {/* <Route path="/dashboard/settings" element={<AccountSettings />} /> */}

          {/* Voters */}
          <Route path='/dashboard/electorate/voters' element={<VoterPage />} />
          <Route
            path='/dashboard/electorate/voters/:id'
            element={<PreviewVoterPage />}
          />
          {/* Polls */}
          <Route path='/dashboard/polls' element={<PollPage />} />
          <Route path='/dashboard/polls/:id' element={<PollPreviewPage />} />
          <Route path='/dashboard/polls/edit/:id' element={<EditPollPage />} />
        </Route>
      </Routes>
      {children}
    </BrowserRouter>
  );
}

export default AppRouter;
