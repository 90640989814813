import { createAction } from "@reduxjs/toolkit";

export const BLOCKCHAIN_PREFIX = "blockchain";

export const BLOCKCHAIN_ACTIONS = {
  SET_PROVIDER: `${BLOCKCHAIN_PREFIX}/SET_PROVIDER`,
  SET_PATH_ITEMS: `${BLOCKCHAIN_PREFIX}/SET_PATH_ITEMS`,
  SET_PROOF_zkAddress: `${BLOCKCHAIN_PREFIX}/SET_PROOF/zkAddress`,
  SET_PROOF_zkMerkle: `${BLOCKCHAIN_PREFIX}/SET_PROOF/zkMerkleTree`,
} as const;

export const setPathItems = createAction(
  BLOCKCHAIN_ACTIONS.SET_PATH_ITEMS,
  (pathItems: any) : { payload: any } => {
    return { payload: pathItems };
  }
);

export const setProofzKAddress = createAction(
  BLOCKCHAIN_ACTIONS.SET_PROOF_zkAddress,
  (proof: any) : { payload: any } => {
    console.log("action: ", proof);
    return { payload: proof};
  }
);

export const setProofzKMerkleTree = createAction(
  BLOCKCHAIN_ACTIONS.SET_PROOF_zkMerkle,
  (proof: any) : { payload: any } => {
    console.log("action: ", proof);
    return { payload: proof};
  }
);