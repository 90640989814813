import { useEffect } from "react";
import { usePathname } from "./hooks/usePathname";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./hooks/auth/useAuth";
import { useAppThunkDispatch } from "./store/hooks";
import { getActiveType } from "./store/actions/uiActions";

export default function AuthCheck({ children }: { children: React.ReactNode }) {
  const isAuthenticated = useAuth();
  const pathname = usePathname();
  const router = useNavigate();
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    console.log("Route changed", pathname);

    if (isAuthenticated && !pathname.startsWith("/dashboard/polls")) {
      dispatch(getActiveType("ALL"));
    }

    if (
      !isAuthenticated &&
      (pathname.startsWith("/dashboard") || pathname === "/")
    ) {
      router("/login");
      return;
    }
    if (isAuthenticated && pathname === "/login") {
      router("/dashboard/polls");
      return;
    }
    if (isAuthenticated && pathname === "/") {
      router("/dashboard/polls");
      return;
    }
    // if (isAuthenticated) {
    //   const role = getRole();
    //   role && router(redirectPaths[role]);
    // }
  }, [pathname, isAuthenticated]);

  return <>{children}</>;
}
