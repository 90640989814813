import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Dashboard } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const getDetails = (
  path: string
): { icon: JSX.Element; title: string } => {
  const { t } = useTranslation();

  switch (path) {
    case "About":
      return { icon: <InfoIcon />, title: t("pages.about.title") };
    case "Polls":
      return { icon: <Dashboard />, title: t("dashboard.title") };
    case "Electorate":
      return {
        icon: <ManageAccountsIcon />,
        title: t("electorate.title"),
      };
    // case "Evaluate":
    //   return { icon: <Star />, title: t("pages.evaluate.title") };
    case "Logout":
      return {
        icon: <ExitToAppRoundedIcon />,
        title: t("logout.title"),
      };
    default:
      return {
        icon: <ExitToAppRoundedIcon />,
        title: t("logout.title"),
      };
  }
};
