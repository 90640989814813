import { sha256 } from "ethers-test";
import { reverseTransformDate } from "./transformDates";

export const getHash = (startDate: string, title: string) => {
  const stringToHash = `${startDate}+${title}`;
  const encoder = new TextEncoder();
  const utf8Encoded = encoder.encode(stringToHash);
  const hash = sha256(utf8Encoded);

  // console.log(hash);

  return hash;
};

export const getBcElectionHash = (election: any): string => {
  const { title, startDate } = election[1];

  const hash = getHash(reverseTransformDate(startDate), title.split("|")[0]);

  return hash;
};

export const getBcId = (election: any): string => {
  return election[0].toString();
};
