import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import { styled } from "@mui/system";
import variables from "../../styles/variables.module.scss";

export const CustomTablePagination = styled(TablePagination)(`
    width: 100%;
    /* display: flex;
    flex-direction: row;
    align-items: center; */
    /* background-color: ${variables.primary}; */
    color: ${variables.text_color};
    font-size: ${variables.font_size_table};
    font-weight: ${variables.font_weight};
    padding: ${variables.padding_table} ;

    & .${classes.spacer} {
      display: flex;
    }

    & .${classes.toolbar}  {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
    & .${classes.selectLabel} {
      margin: 0;
    }

    & .${classes.select}{
      padding: 2px;
      border: 1px solid black;
      border-radius: 50px;
      /* background-color: ${variables.white}; */

      &:focus {
        outline: 1px solid black;
      }
    }

    & .${classes.menuItem}{
      /* background-color: ${variables.white}; */

      &:hover {
        background-color: ${variables.secondary};
      }
    }

    & .${classes.displayedRows} {
      margin: 0;

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    & .${classes.actions} {
      padding: 2px;
      /* border: 1px solid black; */
      /* border-radius: 50px; */
      text-align: center;
      /* background-color: ${variables.white}; */
    }

    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 8px;
      background: transparent; //${variables.white};


      &:hover {
        padding: 10px;
        background-color: ${variables.background_toolbar};
      }

      &:focus {
        background-color: 1px solid ${variables.background_toolbar};
      }
    }
`);
