import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import PreviewIcon from "@mui/icons-material/Preview";
import DrawIcon from "@mui/icons-material/Draw";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import {
  Alert,
  Box,
  Paper,
  Snackbar,
  StepButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StyledButton } from "../../styled/Button";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../utils/CustomAlertDialog";
import { Voter } from "../../../types/voter";
import variables from "../../../styles/variables.module.scss";
import { theme } from "../../../consts/theme";

// const gradient = ["rgb(255, 247, 179)", "rgb(193, 225, 193)", "rgb(151, 186, 189)"];
const gradient = [
  "rgb(82, 131, 177)",
  "rgb(54, 77, 119)",
  "rgb(23, 23, 46)",
  //"rgb(250, 205, 252)",
  //"rgb(195, 177, 225)",
  //"rgb(135, 118, 150)",
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    [theme.breakpoints.down("sm")]: {
      top: 12,
    },
    [theme.breakpoints.up("md")]: {
      top: 22,
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${gradient[0]} 0%,${gradient[1]} 50%,${gradient[2]} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${gradient[0]} 0%,${gradient[1]} 50%, ${gradient[2]} 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    width: "100%",
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  // width: 50,
  // height: 50,
  [theme.breakpoints.down("md")]: {
    width: "25px",
    height: "25px",
  },
  [theme.breakpoints.up("md")]: {
    width: "50px",
    height: "50px",
  },
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 136deg, ${gradient[0]} 0%, ${gradient[1]} 50%, ${gradient[2]} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 136deg, ${gradient[0]} 0%,  ${gradient[1]} 50%, ${gradient[2]} 100%)`,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const size = useMediaQuery(theme.breakpoints.down("md")) ? "small" : "medium";

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon fontSize={size} />,
    2: <DrawIcon fontSize={size} />,
    3: <GroupAddIcon fontSize={size} />,
    4: <VisibilityIcon fontSize={size} />,
    5: <CheckIcon fontSize={size} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

interface Props {
  children: React.ReactNode;
  props: {
    steps: string[];
    activeStep: number;
    setActiveStep: (number: number) => void;
    handleSave: () => void;
    handlePublish: () => void;
    disableNext: boolean;
    excludedVoters: Voter[];
    snackbarOpen: boolean;
  };
}

const CustomizedStepper: React.FC<Props> = ({ children, props }) => {
  const { t } = useTranslation();
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState<string | null>(null);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  const totalSteps = () => {
    return props.steps.length;
  };

  const isLastStep = () => {
    return props.activeStep === totalSteps() - 2;
  };

  const handleNext = () => {
    if (isLastStep()) {
      const message =
        props.excludedVoters.length > 0
          ? t("wizard.errors.excludedVoters") +
            " " +
            props.excludedVoters.map((voter) => voter._id) +
            ". " +
            t("wizard.errors.excludedVoters2") +
            " " +
            t("wizard.steps.preview.message")
          : t("wizard.steps.preview.message");
      setAlert(message);
    } else {
      props.handleSave();
      setNextStep();
    }
  };

  const setNextStep = () => {
    const newCompleted = completed;
    newCompleted[props.activeStep] = true;
    setCompleted(newCompleted);
    const newActiveStep = props.activeStep + 1;
    props.setActiveStep(newActiveStep);
  };

  const handleConfirmPublish = () => {
    setAlert(null);
    props.handlePublish();
    setNextStep();
  };

  const handleBack = () => {
    const newCompleted = completed;
    newCompleted[props.activeStep] = false;
    setCompleted(newCompleted);
    props.handleSave();
    props.setActiveStep(props.activeStep - 1);
  };

  const handleStep = (step: number) => () => {
    if (step > props.activeStep) {
      const newCompleted = completed;
      for (let i = props.activeStep; i <= step - 1; i++) {
        newCompleted[i] = true;
      }
      setCompleted(newCompleted);
      props.handleSave();
      props.setActiveStep(step);
    } else {
      const newCompleted = completed;
      for (let i = step; i <= props.activeStep - 1; i++) {
        newCompleted[i] = false;
      }
      setCompleted(newCompleted);
      props.handleSave();
      props.setActiveStep(step);
    }
  };

  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Paper
          sx={{ padding: { xs: "10px", sm: "20px", lg: "80px" } }}
          elevation={1}
        >
          <Stepper
            nonLinear
            alternativeLabel
            activeStep={props.activeStep}
            connector={<ColorlibConnector />}
          >
            {props.steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton
                  color='inherit'
                  disabled={index === props.steps.length - 1}
                  onClick={handleStep(index)}
                >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "18px",
                        },
                      }}
                    >
                      {t(`wizard.steps.${label}.name`)}
                    </Typography>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Paper>
        <div>
          <React.Fragment>
            {children}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <StyledButton
                // color="inherit"
                variant='contained'
                disabled={props.activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("wizard.nav.back")}
              </StyledButton>
              <Box sx={{ flex: "1 1 auto" }} />
              <StyledButton
                variant='contained'
                onClick={handleNext}
                sx={{ mr: 1 }}
                disabled={isLastStep() ? props.disableNext : false}
              >
                {isLastStep() ? t("wizard.nav.confirm") : t("wizard.nav.next")}
              </StyledButton>
              <StyledButton
                variant='contained'
                onClick={() => {
                  props.handleSave();
                  setOpen(true);
                }}
                sx={{ mr: 1 }}
              >
                {t("wizard.nav.save_draft")}
              </StyledButton>
            </Box>
          </React.Fragment>
        </div>
      </Stack>
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={[
            { label: "Cancel", onClick: () => setAlert(null) },
            {
              label: "Ok",
              onClick: () => {
                handleConfirmPublish();
              },
            },
          ]}
        />
      )}
      {props.snackbarOpen && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            sx={{ width: "100%", border: `1px solid ${variables.green}` }}
          >
            {t("wizard.save_msg")}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CustomizedStepper;
