import { getApiUrl } from "../../consts/api";
import { ApiResponse, AuthApi } from "../../types/api";
import { Axios } from "../api/axios";

export const authClient = () => Axios(`${getApiUrl()}/keycloak`);

// export async function getJwt(usr: string, psw: string) {
//   const params = { usr, psw };
//   const jwt =
//     "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIzYWhTbHozek5NY1YyTm92VDdXVE5TNU5pSFBKUnBSS1luWnlNWERyclVrIn0.eyJleHAiOjE2OTgzMjk2MDMsImlhdCI6MTY5ODI0MzIwMywianRpIjoiNGM5MGY1NmUtZWM5ZS00N2RhLTkwN2YtOTk0MmIwZWVmMmRmIiwiaXNzIjoiaHR0cHM6Ly9zeW5haXJnLWlkcy1kZXYuaW5sZWNvbS5ldS9yZWFsbXMvc3luYWlyZyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJlYWZjNjQxYS02YWNhLTRhM2YtOGI5Zi04NzdmZWJiMjI5MDMiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJzeW5haXJnX2NsaWVudF9mb3JfYmFja2VuZCIsInNlc3Npb25fc3RhdGUiOiJjNTVmNzA1YS05ZTVmLTQ3ZTUtOWVkMS1iYmI5ZDhhY2JlMzIiLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiZGVmYXVsdC1yb2xlcy1zeW5haXJnIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIGdyb3VwLW1hcHBlci1zY29wZSIsInNpZCI6ImM1NWY3MDVhLTllNWYtNDdlNS05ZWQxLWJiYjlkOGFjYmUzMiIsInN5bmFpcmdfZ3JvdXBzIjpbIi9hZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkFOQVNUQVNJT1MgS0FLT1VSSVMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhZG1pbiIsImdpdmVuX25hbWUiOiJBTkFTVEFTSU9TIiwiZmFtaWx5X25hbWUiOiJLQUtPVVJJUyIsImVtYWlsIjoidGFzb3NrYWtvdXJpc0BnbWFpbC5jb20ifQ.pm05dK_eInt1fFgALGhWr2gVb8zhJo14ZV1ApVF7pPdMmGzgKy2mbMZPmQSEzGHwjyo5UakrRf1y6PJNCjW0Kb5KV9IkhtbOai5y1vJFb_sm_fzsyCRCLxfQZFxm-tV5tiJNSTjKZ24yG9jHtyu7KodcF_ZuaZGZLJafULyiJraQqiRIpaJWfc2bLLgyN2tQMVBkTwjEJlKcRNqDOZ6534pCEAL7_Aa4hZXMxZvJJyYLYcMah5nbb57ZCMYjDpuNK-8W0Z4IU9Z6LjZqKI_6gsilx6Lz_RUrvBOCst2bvlFbygPUEgyirYAQbcfmpIXuDac1idh37xJs6fuvjAo9YA";
//   return { data: { data: { jwt: { access_token: jwt } } } };
// }

export async function getJwt(usr: string, psw: string) {
  const params = { usr, psw };
  return await authClient().get<ApiResponse<AuthApi>>("/jwt", { params });
}

export async function sendRefreshToken(refresh_token: string) {
  const params = { refresh_token: JSON.parse(refresh_token) };
  return await authClient().get<ApiResponse<AuthApi>>("/logout", { params });
}
