import { Poll } from "../../../types/poll";
import { useTranslation } from "react-i18next";
import {
  renderChoices,
  renderParticipation,
  renderResults,
  renderResultsChart,
} from "../../../utils/polls/pollPreviewHelper";
import DetailsCard from "../../DetailsCard";

const PreviewResults = ({
  poll,
  shrinked = false,
}: {
  poll: Poll;
  shrinked?: boolean;
}) => {
  const { t } = useTranslation();
  const participationPieData = poll.status !== "UPCOMING" && {
    [t("polls.details.p_voters")]: (poll.participation / poll.toVote) * 100,
    [t("polls.details.a_voters")]:
      100 - (poll.participation / poll.toVote) * 100,
  };
  const resultsPieData =
    poll.status === "PAST" &&
    Object.fromEntries(
      Object.entries(poll.votes).map(([rating, count]) => [
        rating,
        (count / poll.participation) * 100,
      ])
    );

  // console.log("VOTES: ", poll.votes);

  return (
    <DetailsCard
      title={poll.title}
      width={{ xs: "100%", lg: "45%", xl: "60%" }}
      shrinked={shrinked}
      pollType={poll.status}
    >
      {poll.status === "PAST" && (
        <>
          {renderResults(
            poll.votes,
            poll.participation,
            poll.elements[0].title,
            poll.status
          )}
          {renderResultsChart(
            resultsPieData,
            t("polls.details.results"),
            poll.status
          )}
        </>
      )}
      {poll.status === "ACTIVE" && (
        <>
          {renderChoices(
            poll.elements[0].title,
            poll.elements[0].choices,
            poll.status,
            shrinked
          )}
          {renderParticipation(
            participationPieData,
            t("polls.details.current_participation"),
            poll.status
          )}
        </>
      )}
      {(poll.status === "UPCOMING" || poll.status === "DRAFT") &&
        renderChoices(
          poll.elements[0].title,
          poll.elements[0].choices,
          poll.status,
          shrinked
        )}
    </DetailsCard>
  );
};

export default PreviewResults;
