import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseElectionReverse } from "../../utils/bc/parseElection";
import { POLL_ACTIONS } from "../actions/pollActions";
import { Poll } from "../../types/poll";
import {
  fetchDrafts,
  fetchPollById,
  fetchPolls,
  fetchPollsEL,
} from "../../services/api/polls.api";
import * as Sentry from "@sentry/react";
import { getUsername } from "../../services/api/utils.api";

type ContractParams = {
  contract: any;
  proof: any;
  signals: any;
};

type PollParams = ContractParams & {
  bc_id: string;
  draft_id: string;
};

export const getElectionById = createAsyncThunk<any, PollParams>(
  POLL_ACTIONS.GET_ACTIVE_POLL,
  async (params, thunkAPI) => {
    try {
      console.log("Effect: ", params);

      const election = await params?.contract.fetchElectionAsElectionLeader(
        params.proof,
        params.signals,
        params.bc_id
      );
      console.log("Election: ", election);

      const poll = parseElectionReverse(election);

      const enrichedPoll = await fetchPollById(params.draft_id).then((res) => {
        const matchingPoll = res.data.data;

        return {
          ...poll,
          committed: matchingPoll.committed,
          hidden: matchingPoll.hidden,
          draft_id: matchingPoll._id,
        };
      });

      // console.log("EP: ", enrichedPoll);
      return enrichedPoll;
    } catch (error) {
      // const message = (error.response.data.message) || error.toString();
      Sentry.captureException(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getElections = createAsyncThunk<any, ContractParams | undefined>(
  POLL_ACTIONS.GET_POLLS,
  async (params, thunkAPI) => {
    try {
      console.log("Effect: ", params);

      const elections = await params?.contract.fetchElectionsAsElectionLeader(
        params.proof,
        params.signals
      );
      console.log("elections: ", elections);

      const polls = elections.map((election: any) =>
        parseElectionReverse(election)
      );

      const enrichedPolls = await fetchPollsEL().then((res) => {
        const fetchedPolls = res.data.data;

        const committedToPolls: Poll[] = polls.map((poll: Poll) => {
          const matchingPoll = fetchedPolls.find(
            (p: Poll) => poll._id === p.bc_id
          );

          if (matchingPoll) {
            return {
              ...poll,
              committed: matchingPoll.committed,
              hidden: matchingPoll.hidden,
              draft_id: matchingPoll._id,
              author: matchingPoll.author,
            };
          } else {
            return { ...poll, committed: [], hidden: true };
          }
        });

        return committedToPolls;
      });

      console.log("H: ", enrichedPolls);
      return enrichedPolls;
    } catch (error) {
      // const message = (error.response.data.message) || error.toString();
      Sentry.captureException(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDrafts = createAsyncThunk<Poll[]>(
  POLL_ACTIONS.GET_DRAFTS,
  async (params, thunkAPI) => {
    try {
      const { data: responseData } = await fetchDrafts();

      const drafts = responseData.data.filter(
        (draft) => draft.author === getUsername()
      );
      return drafts;
    } catch (error) {
      Sentry.captureException(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
