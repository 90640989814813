import styles from "../../styles/Layout.module.scss";
import ConfirmRemoval from "../../components/ConfirmRemoval";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

const ConfirmRemovalPage = () => {
  const { code } = useParams();

  console.log(code);
  return (
    <Container
      maxWidth={false}
      className={styles.mainContainer}
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {code && <ConfirmRemoval code={code} />}
    </Container>
  );
};

export default ConfirmRemovalPage;
