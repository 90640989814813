import { Button } from "@mui/material";
import { styled } from "@mui/material";
import variables from "../../styles/variables.module.scss";

export const StyledButton = styled(Button)(() => {
  return {
    backgroundColor: variables.color_secondary,
    transition: "all .15s ease-in-out",
    color: "#fff",
    "&:hover": {
      backgroundColor: variables.color_secondary,
      fontWeight: 900,
      transform: "scale(1.05)",
      opacity: 1,
    },
  };
});

export const StyledTransparentButton = styled(Button)(() => {
  return {
    border: "1px solid rgba(138, 141, 147, 0.5)",
    backgroundColor: "#fff",
    color: variables.color_secondary,
  };
});
