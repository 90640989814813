import { USER_ACTIONS } from "./../actions/userActions";
import { User } from "../../types/user";
import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../../types/redux";
import { ElectionLeader, ElectionLeaderApi } from "../../types/el";

export interface UserState {
  activeUser: User | null;
  profile: ElectionLeader | null;
  loading: boolean;
}

export const initialUserState: UserState = {
  activeUser: null,
  profile: null,
  loading: false,
};

const userReducer = createReducer(initialUserState, (builder) => {
  builder.addCase(
    USER_ACTIONS.GET_ACTIVE_USER,
    (state, action: ActionWithPayload<User>) => {
      // console.log(action.payload);
      console.log(action.type);

      return { ...state, activeUser: action.payload };
    }
  );
  builder.addCase(
    USER_ACTIONS.GET_PROFILE,
    (state, action: ActionWithPayload<ElectionLeaderApi>) => {
      console.log(action.payload);

      const fullName = action.payload?.fullname;

      let firstName, lastName;

      if (fullName) {
        const lastSpaceIndex = fullName.lastIndexOf(" ");
        if (lastSpaceIndex !== -1) {
          firstName = fullName.slice(0, lastSpaceIndex);
          lastName = fullName.slice(lastSpaceIndex + 1);
        } else {
          firstName = fullName;
          lastName = "";
        }
      } else {
        firstName = "";
        lastName = "";
      }

      const profile = {
        _id: action.payload._id,
        firstName: firstName,
        lastName: lastName,
        description: action.payload.description,
        photo: action.payload.photo || undefined,
      };
      return { ...state, profile };
    }
  );
});

export default userReducer;
