import { IconButton, styled } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import variables from "../../styles/variables.module.scss";

export const AddOne = styled(AddCircleIcon)(() => ({
  fontSize: "40px",
  "&:hover": {
    fontWeight: 900,
    opacity: 1,
  },
}));

export const AddMultiple = styled(AddCircleOutlineIcon)(() => ({
  fontSize: "40px",
  "&:hover": {
    fontWeight: 900,
    opacity: 1,
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: variables.white,
  color: variables.color_secondary,
  transition: variables.trans_ease_in_out,
  borderRadius: "99999px",
  borderColor: variables.primary,
  padding: "15px",
  margin: "0px",
  width: "0px",
  height: "0px",
  boxShadow: theme.shadows[10],
  // boxShadow: "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
  "&:hover": {
    transform: "scale(1.10)",
  },
}));
