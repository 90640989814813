import { createAction } from "@reduxjs/toolkit";
import { Voter } from "../../types/voter";

export const VOTERS_PREFIX = "voters";

export const VOTER_ACTIONS = {
  GET_VOTERS: `${VOTERS_PREFIX}/GET_VOTERS`,
  GET_ACTIVE_VOTER: `${VOTERS_PREFIX}/GET_ACTIVE_VOTER`,
  REMOVE_VOTER: `${VOTERS_PREFIX}/REMOVE_VOTER`,
  ADD_VOTER: `${VOTERS_PREFIX}/ADD_VOTER`,
  UPDATE_VOTER: `${VOTERS_PREFIX}/UPDATE_VOTER`,
} as const;

export const getActiveVoter = createAction(
  VOTER_ACTIONS.GET_ACTIVE_VOTER,
  (voter: Voter | null): { payload: Voter | null } => {
    // console.log(voter);
    return { payload: voter };
  }
);

export const removeVoter = createAction(
  VOTER_ACTIONS.REMOVE_VOTER,
  (voter: string): { payload: string } => {
    return { payload: voter };
  }
);

// export const addVoter = createAction(
//   VOTER_ACTIONS.ADD_VOTER,
//   (voter: Voter): { payload: Voter } => {
//     return { payload: voter };
//   }
// );

// export const updateVoter = createAction(
//   VOTER_ACTIONS.UPDATE_VOTER,
//   (voter: Voter): { payload: Voter } => {
//     return { payload: voter };
//   }
// );

