import { createReducer } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../../types/redux";
import { Group } from "../../types/group";
import { GROUP_ACTIONS } from "../actions/groupAction";
import { getGroups } from "../effects/groupEffects";

export interface GroupState {
  groups: Group[];
  activeGroup: Group | null;
  loading: boolean;
}

export const initialGroupState: GroupState = {
  groups: [],
  activeGroup: null,
  loading: false,
};

const groupReducer = createReducer(initialGroupState, (builder) => {
  builder.addCase(getGroups.fulfilled, (state, { payload }) => {
    return { ...state, groups: payload, loading: false };
  });
  builder.addCase(getGroups.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(
    GROUP_ACTIONS.GET_ACTIVE_GROUP,
    (state, action: ActionWithPayload<Group>) => {
      // console.log(action.payload);
      console.log(action.type);

      return { ...state, activeGroup: action.payload };
    }
  );
  builder.addCase(
    GROUP_ACTIONS.REMOVE_GROUP,
    (state, action: ActionWithPayload<string>) => {
      return {
        ...state,
        groups: state.groups.filter((group) => group._id !== action.payload),
      };
    }
  );
  builder.addCase(
    GROUP_ACTIONS.ADD_GROUP,
    (state, action: ActionWithPayload<Group>) => {
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };
    }
  );
  builder.addCase(
    GROUP_ACTIONS.UPDATE_GROUP,
    (state, action: ActionWithPayload<Group>) => {
      console.log("RED: ", action.payload);
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group._id === action.payload._id) {
            return action.payload;
          }
          return group;
        }),
      };
    }
  );
});

export default groupReducer;
