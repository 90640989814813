import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import type {
  AppDispatch,
  RootState,
} from './store';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

// use throughout your app instead of useDispatch and useSelector

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useAppThunkDispatch = () => useDispatch<AppThunkDispatch>();

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;