export const transformDate = (dateString: string) => {
  console.log("DS: ", dateString);
  const dateObject = dateString.split(" ");
  const date = dateObject[0].split("/");

  // const formattedDate =
  //   date[2] + "-" + date[1] + "-" + date[0] + " " + dateObject[1];

  const time = dateObject[1].split(":");
  // console.log("Time: ", time);

  const timestamp = Date.UTC(
    parseInt(date[2]),
    parseInt(date[1]) - 1,
    parseInt(date[0]),
    parseInt(time[0]),
    parseInt(time[1]),
    0
  );

  // console.log("Timestamp: ", timestamp);
  // console.log("BigInt: ", BigInt(timestamp * 1000000));

  return BigInt(timestamp * 1000000);
};

export const reverseTransformDate = (timestampBigInt: bigint): string => {
  const timestampMillis = Number(timestampBigInt) / 1000000;

  const dateObject = new Date(timestampMillis);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  // const seconds = dateObject.getSeconds().toString().padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  // console.log("REVERSE FD: ", formattedDate);

  return formattedDate;
};
