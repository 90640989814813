import { createAsyncThunk } from "@reduxjs/toolkit";
import { Group } from "../../types/group";
import { fetchGroups } from "../../services/api/group.api";
import { GROUP_ACTIONS } from "../actions/groupAction";
import * as Sentry from "@sentry/react";

export const getGroups = createAsyncThunk<Group[]>(
  GROUP_ACTIONS.GET_GROUPS,
  async (params, thunkAPI) => {
    try {
      const { data: responseData } = await fetchGroups();

      return responseData.data;
    } catch (error) {
      Sentry.captureException(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
