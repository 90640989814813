import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Typography, Container } from "@mui/material";
import AlertDialog from "../utils/CustomAlertDialog";
import { deleteRequest } from "../../services/api/voter.api";
import * as Sentry from "@sentry/react";
import { StyledTextField } from "../styled/TextField";
import { ErrorMessage, Field, Formik } from "formik";
import { yupValidationVoter } from "../../utils/yupValidation";
import { StyledButton } from "../styled/Button";
import CustomModal from "../utils/CustomModal";
import CustomLoader from "../utils/CustomLoader";

const Form = () => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState(false);
  // const [check, setCheck] = useState(false);

  return (
    <Container maxWidth={"lg"} sx={{ paddingTop: 10 }}>
      <Paper elevation={2} sx={{ p: 4 }}>
        <Typography variant='h6' marginBottom={2} gutterBottom>
          {t("request.title")}
        </Typography>
        <Typography
          variant='body1'
          textAlign={"justify"}
          marginBottom={2}
          gutterBottom
        >
          {t(`request.description`)}
        </Typography>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, actions) => {
            console.log("Delete Request: ", values);

            setIsLoading(true);
            deleteRequest(values.email)
              .then((res: any) => {
                console.log(res);
                if (res.status === 200) {
                  setIsLoading(false);
                  setAlert(t("request.message"));
                }
              })
              .catch((error) => {
                setIsLoading(false);
                console.error("Axios request error:", error);
                Sentry.captureException(error);
                setAlert(t("wizard.errors.systemError"));
              });
          }}
          validationSchema={yupValidationVoter}
        >
          {({
            values,
            errors,
            touched,
            // setValues,
            // setFieldValue,
            handleChange,
            // handleBlur,
            handleSubmit,
            // isSubmitting,
          }) => (
            <form
              // className={styles.form}
              onSubmit={handleSubmit}
              autoComplete='off'
            >
              <Field
                as={StyledTextField}
                required
                autoComplete='off'
                id={"email"}
                name={"email"}
                label={t(`electorate.form.fields.email`)}
                fullWidth
                margin='dense'
                variant='filled'
                helperText={<ErrorMessage name={"email"} />}
                error={errors["email"] && touched["email"]}
                type={"email"}
              />
              {/* <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                paddingTop={2}
                paddingBottom={2}
              >
                <StyledCheckbox
                  checked={check}
                  onChange={() => setCheck(!check)}
                />
                <Typography>{t("request.check")}</Typography>
              </Box> */}
              <StyledButton
                // className={styles.button}
                fullWidth
                sx={{ marginTop: 2 }}
                variant='contained'
                type='submit'
              >
                {t("request.button")}
              </StyledButton>
            </form>
          )}
        </Formik>
      </Paper>
      {isLoading && (
        <CustomModal
          props={{
            open: true,
            handleClose: () => {
              console.log("closed");
            },
          }}
        >
          <CustomLoader />
        </CustomModal>
      )}
      {alert && (
        <AlertDialog
          open={true}
          title={t("general.alert")}
          message={alert}
          buttons={[{ label: "Ok", onClick: () => setAlert(null) }]}
        />
      )}
    </Container>
  );
};

export default Form;
